import { Injectable } from '@angular/core';
import { Share, ShareResult } from '@capacitor/share';

// Services
import { TranslationService } from '../translation/translation.service';

@Injectable({ providedIn: 'root' })
export class ShareService {
  constructor(private translate: TranslationService) {}

  shareNews(link: string): Promise<ShareResult> {
    const text = `${this.translate.instant('SHARE.NEWS')}\n`;
    return Share.share({
      title: 'Federazione Rinascimento Italia',
      text,
      url: link,
      dialogTitle: text,
    });
  }

  sharePublicKey(publicKey: string): Promise<ShareResult> {
    const text = `${this.translate.instant('SHARE.KEY')}\n`;
    return Share.share({
      title: 'Federazione Rinascimento Italia',
      text: publicKey,
      dialogTitle: text,
    });
  }

  shareInvitationLink(link: string): Promise<ShareResult> {
    const text = `${this.translate.instant('SHARE.LINK')}\n`;
    return Share.share({
      title: 'Federazione Rinascimento Italia',
      url: link,
      dialogTitle: text,
    });
  }
}
