<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="3" class="ion-justify-content-flex-start ion-align-items-center">
        <ion-label color="light" (click)="dismissModal()">{{ 'TORGRAM.CANCEL' | translate }}</ion-label>
      </ion-col>

      <ion-col size="7" class="ion-justify-content-center ion-align-items-center"> </ion-col>

      <ion-col size="2" class="ion-justify-content-flex-end ion-align-items-center"> </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-label class="ion-margin-top ion-padding-horizontal ion-text-uppercase">
    {{ 'TORGRAM.LINKNAME' | translate }} *
  </ion-label>
  <ion-input
    type="text"
    [(ngModel)]="linkName"
    placeholder="{{ 'TORGRAM.LINKNAME' | translate }}"
    class="linkName ion-padding-horizontal ion-margin-top"
    maxlength="30"
  >
  </ion-input>

  <br />
  <br />

  <ion-label class="ion-padding-horizontal ion-text-uppercase">
    {{ 'TORGRAM.DURATIONLIMIT' | translate }}
  </ion-label>

  <ion-input
    value="{{ expirationDate | date: 'dd/MM/yyyy' }}"
    id="date"
    class="durationLimit ion-padding-horizontal ion-margin-top"
    placeholder="{{ 'TORGRAM.DATE' | translate }}"
  ></ion-input>

  <ion-popover trigger="date">
    <ng-template>
      <ion-datetime presentation="date" [(ngModel)]="expirationDate" locale="it-IT" [min]="today"></ion-datetime>
    </ng-template>
  </ion-popover>

  <ion-label class="ExpLabel ion-padding-horizontal">
    {{ 'TORGRAM.DURATIONLIMITEXPLANATION' | translate }}
  </ion-label>

  <br />
  <br />
  <br />

  <ion-label class="ion-padding-horizontal ion-text-uppercase"> {{ 'TORGRAM.USERLIMIT' | translate }} </ion-label>

  <ion-input
    [(ngModel)]="userLimit"
    type="number"
    class="durationLimit ion-padding-horizontal ion-margin-top"
    placeholder="{{ 'TORGRAM.NUMBERUSER' | translate }}"
  ></ion-input>

  <br />
  <br />
  <ion-label class="ExpLabel ion-padding-horizontal">
    {{ 'TORGRAM.MANDATORYFIELDS' | translate }}
  </ion-label>
  <br />
  <div class="ion-padding-horizontal">
    <ion-label class="ExpLabel">
      {{ 'TORGRAM.NOLIMITEXP' | translate }}
    </ion-label>
  </div>

  <ion-button expand="block" (click)="createNewLink()" [disabled]="createNotDisabled || !internetAvailable">
    {{ 'TORGRAM.CREATELINK' | translate }}
  </ion-button>
</ion-content>
