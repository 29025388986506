import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpHandlerFn } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Environment
import { environment } from '../../../environments/environment';

// PackageJson
import packageJSon from '../../../../package.json';

// Services
import { LoggerService } from '../services/logger/logger.service';
import { CommonService } from '../services/common/common.service';
import { KeysService } from '../services/keys/keys.service';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function canLog(url: string): boolean {
  return !url.includes('index.html') && !url.includes('//dev-app');
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/explicit-module-boundary-types
export default function intercept(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  const logger = inject(LoggerService);
  const common = inject(CommonService);
  const keyService = inject(KeysService);

  const WEBSERVICES_MODE = environment.WEBSERVICES_MODE as 'ONLINE' | 'LOCAL';
  const WEB_SERVICE_URL = environment.WEBSERVICES[WEBSERVICES_MODE].WEB_SERVICE_URL;

  const { url, method } = request;

  if (canLog(url)) {
    logger.consoleLog('HttpConfigInterceptor', 'intercept', {
      url,
      VERSION: packageJSon.version,
      lang: common.defaultLanguage ?? '',
      method,
      keys: keyService.keys,
    });
  }

  if (!url.includes('wp-json')) {
    request = request.clone({
      headers: request.headers
        .append('VERSION', packageJSon.version)
        .append('lang', common.defaultLanguage ?? '')
        .append('publicKey', keyService.publicKey),
    });
  }

  return next(request).pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    switchMap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (
          environment.STATUS_FORCE_SERVICE &&
          environment.STATUS_FORCED === 'offline' &&
          event.url.includes(WEB_SERVICE_URL)
        ) {
          return throwError(() => 'STATUS_FORCE_SERVICE: offline');
        }

        if (canLog(url)) {
          if (event.status === 200) {
            logger.consoleLog('HttpConfigInterceptor', 'handle', { url, event });
          } else {
            logger.consoleError('HttpConfigInterceptor', 'handle', { url });
          }
        }
      }

      return of(event);
    }),
  );
}
