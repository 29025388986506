import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// Types
import { IFeed } from '../../types';
import { PageNgOnInitCallBack } from '../../types/common';
import { StorageKey } from '../../types/storage';

// Services
import { StorageService } from '../../../core/services/storage/storage.service';
import { ApiService } from '../../../core/http/api.service';
import { NetworkService } from '../../../core/services/network/network.service';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
})
export class NewsFeedComponent implements AfterViewInit, OnDestroy {
  public newsFeed: string = '';

  constructor(
    private storage: StorageService,
    private api: ApiService,
    private networkService: NetworkService,
  ) {}

  ngAfterViewInit(): void {
    this.networkService.pageNgOnInit(
      'NewsFeedComponent',
      this.getSimplePostTicker.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );
  }

  ngOnDestroy(): void {
    this.networkService.removeListeners('NewsFeedComponent');
  }

  private getSimplePostTicker(): void {
    this.storage
      .getFromStorage(StorageKey.NewsFeed)
      .pipe(
        // Check if is last version
        map((feed: string | IFeed) => {
          if (typeof feed === 'string') {
            feed = { dateFeed: 0, textFeed: feed } as IFeed;
            this.storage.setOnStorage(StorageKey.NewsFeed, feed).subscribe();
          }
          return feed;
        }),
        // Chech if feed was registered yesterday
        switchMap(
          (feed: IFeed) =>
            new Observable((observer) => {
              if (Date.now() - feed.dateFeed > 86400000) {
                this.api.wordpress.getSimplePostTicker().subscribe({
                  next: (newFeed: string) => {
                    const newFeedObj: IFeed = { dateFeed: Date.now(), textFeed: newFeed };
                    this.storage.setOnStorage(StorageKey.NewsFeed, newFeedObj).subscribe();
                    observer.next(newFeedObj);
                  },
                  error: () => observer.next(feed),
                });
              } else {
                observer.next(feed);
              }
            }),
        ),
      )
      .subscribe({
        next: (feed: IFeed) => (this.newsFeed = feed.textFeed),
        error: (error) => {
          if (error === null) {
            this.storage.setOnStorage(StorageKey.NewsFeed, '').subscribe({ next: () => this.getSimplePostTicker() });
          } else {
            this.newsFeed = '';
          }
        },
      });
  }

  private notConnectedCallback(): void {
    this.newsFeed = '';
  }
}
