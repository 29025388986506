<ion-list lines="full">
  <ion-item (click)="copyLink()" [disabled]="!internetAvailable">
    <ion-icon slot="start" ios="copy-outline" md="copy-sharp"></ion-icon>
    <ion-label color="fri-dark" class="ion-text-wrap">{{ 'TORGRAM.COPY' | translate }}</ion-label>
    <not-available-offline> </not-available-offline>
  </ion-item>

  <ion-item (click)="revokeLink()" [disabled]="!internetAvailable">
    <ion-icon slot="start" ios="trash-outline" md="trash-outline" color="danger"></ion-icon>
    <ion-label color="danger" class="ion-text-wrap ion-text-bold">{{ 'TORGRAM.REVOKE' | translate }}</ion-label>
    <not-available-offline> </not-available-offline>
  </ion-item>
</ion-list>
