/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-shadow
export enum StorageKey {
  FRITokenAvailability = 'FRITokenAvailability',
  FRITransactionsPending = 'FRITransactionsPending',
  FRITransactionsHistory = 'FRITransactionsHistory',
  LastCategorySelected = 'lastCategorySelected',
  Keys = 'keys',
  Pin = 'pin',
  CameraLog = 'cameraLog',
  CurrentEnvironment = 'currentEnvironment',
  LoggedUser = 'loggedUser',
  ResearchResults = 'RESEARCH_RESULTS',
  NewsFeed = 'newsFeed',
  ZoomMode = 'zoomMode',
  Lang = 'lang',
  AAACategories = 'AAACategories',
  RememberMe = 'rememberMe',
  WalletFiltersActive = 'walletFiltersActive',
}
