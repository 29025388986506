<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="2" class="ion-justify-content-flex-start ion-align-items-center">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="8" class="ion-justify-content-center ion-align-items-center"> </ion-col>

      <ion-col size="2" class="ion-justify-content-flex-end ion-align-items-center"> </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-header class="searchBar">
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-no-padding">
      <ion-searchbar
        [(ngModel)]="searchBoxTxt"
        [placeholder]="'TORGRAM.SEARCH_PLACEHOLDER' | translate"
        [disabled]="(!onlyMessages && isLoading) || !internetAvailable || isJoiningStarted"
        [debounce]="2000"
        (ionInput)="handleInput()"
        class="ion-no-padding"
      ></ion-searchbar>
    </ion-col>
  </ion-row>

  @if (!onlyMessages) {
    <ion-row>
      <ion-col>
        <ion-segment
          mode="md"
          class="ion-full-width"
          color="dark"
          [scrollable]="false"
          [(ngModel)]="descriptionSelected"
          (ngModelChange)="handleInput()"
          [disabled]="!internetAvailable || isJoiningStarted"
        >
          @for (type of searchCategories; track $index) {
            <ion-segment-button [value]="type.description" [disabled]="type.disabled">
              {{ type.label | translate }}
            </ion-segment-button>
          }
        </ion-segment>
      </ion-col>
    </ion-row>
  }
</ion-header>

<ion-content class="ion-padding-horizontal">
  @if (internetAvailable && !isLoadingRooms) {
    @if (filteredList?.length > 0) {
      @if (descriptionSelected !== 'messages' || (descriptionSelected === 'messages' && searchBoxTxt.length > 0)) {
        <ion-list>
          @for (group of filteredList; track $index) {
            <app-torgram-list-item
              [item]="group"
              [detail]="false"
              (modalDismiss)="dismissModal($event)"
              [toId]="descriptionSelected === 'messages' ? group.id : undefined"
              [searchText]="searchBoxTxt"
            ></app-torgram-list-item>
          }
        </ion-list>
      } @else {
        <ion-row class="ion-justify-content-center ion-align-items-center placeholder">
          <ion-col size="12" class="ion-justify-content-center ion-align-items-center">
            <ion-icon name="search-outline"></ion-icon>
          </ion-col>
          <ion-col size="12" class="ion-justify-content-center ion-align-items-center">
            <ion-label class="ion-text-center">{{ 'TORGRAM.SEARCH_ANOTHER_THINGS' | translate }}</ion-label>
          </ion-col>
        </ion-row>
      }
    } @else {
      <ion-row class="ion-full-height ion-justify-content-center ion-align-items-center placeholder">
        <ion-col size="12" class="ion-justify-content-center ion-align-items-center">
          <ion-label class="ion-text-center" [innerHTML]="'TORGRAM.NO_RESULTS' | translate"></ion-label>
        </ion-col>
      </ion-row>
    }
  } @else {
    <div class="ion-full-height ion-align-items-center ion-justify-content-center">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  }
</ion-content>
