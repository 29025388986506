import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '../directives/directives.module';

// Components
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { FooterComponent } from './footer/footer.component';
import { NotAvailableOfflineComponent } from './not-available-offline/not-available-offline.component';
import { MenuComponent } from './menu/menu.component';
import { QRCodeScannerComponent } from './qr-code-scanner/qr-code-scanner.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { NewsFeedComponent } from './news-feed/news-feed.component';
import { InputDateComponent } from './input-date/input-date.component';
import { QRCodeModalComponent } from './qrcode-modal/qrcode-modal.component';
import { PwaNotAvailableComponent } from './pwa-not-available/pwa-not-available.component';
import { PwaNotInstalledComponent } from './pwa-not-installed/pwa-not-installed.component';

const COMPONENTS = [
  FooterComponent,
  NotAvailableOfflineComponent,
  MenuComponent,
  TransactionDetailComponent,
  QRCodeScannerComponent,
  MenuButtonComponent,
  NewsFeedComponent,
  InputDateComponent,
  QRCodeModalComponent,
  PwaNotAvailableComponent,
  PwaNotInstalledComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    QRCodeModule,
    ReactiveFormsModule,
    DirectivesModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class ComponentsModule {}
