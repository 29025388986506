import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Directive({
  selector: '[rightToLeftAnimation]',
})
export class RightToLeftAnimationDirective implements OnInit {
  @Input() duration!: number;

  constructor(
    private animationCtrl: AnimationController,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    const element = this.el.nativeElement as HTMLElement;
    element.style.transform = 'translateX(100vw)';
    void this.animationCtrl
      .create()
      .addElement(element)
      .iterations(Infinity)
      .duration(this.duration)
      .fromTo('transform', 'translateX(110vw)', 'translateX(-100%)')
      .play();
  }
}
