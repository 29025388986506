import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { ApiService } from './http/api.service';
import { WordpressService } from './http/wordpress/wordpress.service';
import { WalletService } from './http/wallet/wallet.service';
import { WebService } from './http/web/web.service';

const SERVICES = [WordpressService, WalletService, WebService];

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ApiService, ...SERVICES],
})
export class CoreModule {}
