export const environment = {
  production: true,
  STATUS_FORCE_SERVICE: false,
  STATUS_FORCED: 'offline',
  WEBSERVICES: {
    ONLINE: {
      WEB_SERVICE_URL: 'https://dev-webservices.rinascimentoitalia.it',
      TOKEN_GENERATOR: 'https://dev.rinascimentoitalia.it/wp-json/jwt-auth/v1/token',
      VALIDATE_TOKEN: 'https://dev.rinascimentoitalia.it/wp-json/jwt-auth/v1/token/validate',
      INVITE_LINK: 'https://dev-app.rinascimentoitalia.it/#/torgram/invite-link/',
      WEB_WEB_SERVICE_URL: 'https://dev-tor-webservices.rinascimentoitalia.it',
      TOR_WEB_SERVICE_URL: 'https://dev-tor-webservices.rinascimentoitalia.it:444',
    },
  },
  LOGS: {
    CONSOLE: false,
    ERROR: true,
    STORAGE: false,
    SOCKET: true,
    NETWORK: true,
    PWA: false,
  },
  STORAGE_NAME: '__fri_app_dev',
  TIMEOUT: 1500,
  URLS: {
    BLOG_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/bblog?_embed',
    AAA_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/aaa?#placeholder#_embed',
    COMUNICATIONS_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/comunicazioni?_embed',
    RADIO_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/radio?_embed',
    IRONY_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/irony?_embed',
    NEWSLETTER_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/newsletter?_embed',
    TUTORIAL_URL: 'https://dev.rinascimentoitalia.it/wp-content/uploads/2020/07/FRI_tutorial.mp4',
    MANUAL_URL: 'https://dev.rinascimentoitalia.it/wp-content/uploads/2021/07/TUTORIAL-UTILIZZO-WEB-APP-FRI.pdf',
    POST_TICKER_URL: 'https://dev.rinascimentoitalia.it/wp-json/wp/v2/app-ticker?_embed',
  },
  VERSION_CODE: '5',
  WEBSERVICES_MODE: 'ONLINE',
  PWA_LINK: 'https://dev-app.rinascimentoitalia.it',
};
