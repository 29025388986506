<ion-content>
  <ion-row class="full-height">
    <ion-col size="12" class="full-height divOverflow">
      <ion-label class="ion-text-wrap"> {{ 'COMMON.OLD_VERSION.PART_1' | translate }}</ion-label>
      <ion-label class="ion-text-wrap"> {{ 'COMMON.OLD_VERSION.PART_2' | translate }}</ion-label>
      <ion-icon name="refresh"></ion-icon>.
      <ion-label class="ion-text-wrap"> {{ 'COMMON.OLD_VERSION.PART_3' | translate }}</ion-label>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-padding">
  <ion-row>
    <ion-col size="6" class="flexCenterCenter">
      <ion-button fill="clear" color="dark" (click)="forceRefresh()">
        <ion-icon name="refresh" icon-only></ion-icon>
      </ion-button>
    </ion-col>

    <ion-col size="6" class="flexCenterCenter">
      <ion-button fill="clear" color="dark" (click)="closeMessage()">
        <ion-icon name="close-outline" size="large" icon-only></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
