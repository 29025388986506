import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// Types
import { RoomUpdate } from '../../../../shared/types/socket';
import { Room, RoomMembers, RoomUsers } from '../../../../shared/types/tables';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

// Services
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { CommonService } from '../../../../core/services/common/common.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { ToastService } from '../../../../core/services/toast/toast.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../core/services/logger/logger.service';

@Component({
  selector: 'app-torgram-room-info',
  templateUrl: './torgram-room-info.component.html',
  styleUrls: ['./torgram-room-info.component.scss'],
})
export class TorgramRoomInfoComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() room!: Room;
  @Input() roomMessagesLength!: number;

  public roomMembers: RoomMembers[];
  public availableUsers: RoomUsers[];

  private roomGotSubscription: Subscription;
  private roomEditedSubscription: Subscription;
  private roomUnsubscribedSubscription: Subscription;
  private roomBannedMemberSubscription: Subscription;
  private roomMembersSubscription: Subscription;
  private roomDeleteAllMessagesSubscription: Subscription;
  private roomAvailableUsersSubscription: Subscription;
  private roomUnbannedMemberSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslationService,
    private socketService: SocketService,
    private modalService: ModalService,
    private common: CommonService,
    private networkService: NetworkService,
    private sanitizer: DomSanitizer,
    private presentToast: ToastService,
    private logger: LoggerService,
  ) {}

  get roomImage(): string | SafeResourceUrl | null {
    if (this.room?.image === null) {
      return './assets/menuIcon/newicon.png';
    } else {
      return this.room?.image
        ? this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.socketService.baseUrlAttachment}/${this.room.image?.id}?date=${new Date().toISOString()}`,
          )
        : './assets/menuIcon/newicon.png';
    }
  }

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnInit(): void {
    this.networkService.pageNgOnInit(
      'TorgramRoomInfoComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramRoomInfoComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.roomGotSubscription?.unsubscribe();
    this.roomEditedSubscription?.unsubscribe();
    this.roomUnsubscribedSubscription?.unsubscribe();
    this.roomBannedMemberSubscription?.unsubscribe();
    this.roomMembersSubscription?.unsubscribe();
    this.roomDeleteAllMessagesSubscription?.unsubscribe();
    this.roomAvailableUsersSubscription?.unsubscribe();
    this.roomUnbannedMemberSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  roleDescription(isOwner: boolean, isModerator: boolean, isContributor: boolean): string {
    const role = isOwner
      ? this.translate.instant('TORGRAM.OWNER')
      : isModerator
        ? this.translate.instant('TORGRAM.MODERATOR')
        : isContributor
          ? this.translate.instant('TORGRAM.CONTRIBUTOR')
          : '';

    return role;
  }

  async deleteMessages(): Promise<void> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('TORGRAM.DELETE_MESSAGES'),
      buttons: [
        {
          text: this.translate.instant('TORGRAM.YES'),
          role: 'confirm',
          handler: () => {
            if (this.room.members[0].owner) {
              this.deleteAllMessages(this.room?.id);
            }
          },
        },
        {
          text: this.translate.instant('TORGRAM.NO'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  async dismissRoom(): Promise<void> {
    let header: string;

    if (this.room?.roomType.id === 1) {
      header = this.room.members[0]?.owner ? 'CHANNELDELETE' : 'CHANNELLEAVE';
    } else {
      header = this.room.members[0]?.owner ? 'CHATDELETE' : 'CHATLEAVE';
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('TORGRAM.' + header),
      buttons: [
        {
          text: this.translate.instant('TORGRAM.YES'),
          role: 'confirm',
          handler: () => {
            if (this.room.members[0]?.owner) {
              const updateRoom = {
                roomId: this.room.id,
                status: 'disabled',
              } as RoomUpdate;

              this.updateRoom(updateRoom);
            } else {
              this.unsubscribeRoom(this.room?.id);
            }
          },
        },
        {
          text: this.translate.instant('TORGRAM.NO'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  async openInfoModal(): Promise<void> {
    const modal = await this.modalService.openTorgramRoomEditComponent(this.room);
    const results = await modal.onDidDismiss();

    await this.modalService.dismissLast();

    if (results?.data === 'confirm') {
      this.getRoomInformation(this.room.id);
    }
  }

  async openLinkModal(): Promise<void> {
    await this.modalService.openTorgramLinkComponent(this.room);
  }

  async openModal(role: string): Promise<void> {
    await this.modalService.openTorgramUserListComponent(role, this.room);
  }

  async openSearchModal(): Promise<void> {
    const modal = await this.modalService.openTorgramSearchGroupComponent(this.room, true);
    const results = await modal.onDidDismiss();

    if (results?.data === 'navigate-to-messages') {
      await this.modalService.dismissLast();
    }
  }

  async openQrCodeModal(): Promise<void> {
    const modal = await this.modalService.openTorgramRoomQrCodeJoin(this.room);
    await modal.onDidDismiss();

    await this.modalService.dismissLast();
  }

  async dismissModal(role?: string): Promise<void> {
    await this.modal.dismiss(role);
    await this.modalService.dismissLast();
  }

  async addNewUsers(): Promise<void> {
    const modal = await this.modalService.openTorgramAddUsersToRoom(this.room, this.availableUsers);
    await modal.onDidDismiss();

    await this.modalService.dismissLast();
  }

  private getRoomInformation(roomId: number): void {
    this.socket.emit(SocketEvents.ROOM_GET, { roomId });
  }

  private updateRoom(updateRoom: RoomUpdate): void {
    this.socket.emit(SocketEvents.ROOM_EDIT, { ...updateRoom });
  }

  private unsubscribeRoom(roomId: number): void {
    this.socket.emit(SocketEvents.ROOM_UNSUBSCRIBE, { roomId });
  }

  private deleteAllMessages(roomId: number): void {
    if (this.socketService.env === 'tor') {
      void this.presentToast.showOperationToast('', this.translate.instant('TORGRAM.DELETING_MESSAGES_TOR'));
    }

    this.socket.emit(SocketEvents.ROOM_DELETE_ALL_MESSAGES, { roomId });
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramRoomInfoComponent');
    }

    this.roomGotSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET).subscribe({
      next: (room: Room) => {
        this.room = room;
      },
    });

    this.roomEditedSubscription = this.socket.fromEvent(SocketEvents.ROOM_EDIT).subscribe({
      next: async (roomEdited: Room) => {
        if (roomEdited) {
          if (roomEdited.status === 'disabled') {
            this.socket.emit(SocketEvents.JOIN_ROOMS);
            await this.common.torgramNavigationWithBaseHref();

            await this.modalService.dismissAll();
            await this.dismissModal('confirm');
          }
        }
      },
    });

    this.roomUnsubscribedSubscription = this.socket.fromEvent(SocketEvents.ROOM_UNSUBSCRIBE).subscribe({
      next: async () => {
        await this.common.torgramNavigationWithBaseHref();

        await this.modalService.dismissAll();
        await this.dismissModal('confirm');
      },
    });

    this.roomMembersSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET_MEMBERS).subscribe({
      next: (results: RoomMembers[]) => {
        this.roomMembers = results;
      },
    });

    this.roomDeleteAllMessagesSubscription = this.socket.fromEvent(SocketEvents.ROOM_DELETE_ALL_MESSAGES).subscribe({
      next: () => {
        this.roomMessagesLength = 0;
      },
    });

    this.roomAvailableUsersSubscription = this.socket.fromEvent(SocketEvents.ROOM_AVAILABLE_USERS).subscribe({
      next: ({ availableUsers }: { availableUsers: RoomUsers[] }) => {
        this.availableUsers = availableUsers;
      },
    });

    this.roomUnbannedMemberSubscription = this.socket.fromEvent(SocketEvents.ROOM_UNBAN_USER).subscribe({
      next: () => {
        this.getAvailableUsers();
      },
    });

    this.socket.emit(SocketEvents.ROOM_GET_MEMBERS, {
      roomId: this.room.id,
      status: 'pending',
    });

    this.getAvailableUsers();
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramRoomInfoComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }

  private getAvailableUsers() {
    this.socket.emit(SocketEvents.ROOM_AVAILABLE_USERS, { roomId: this.room?.id ?? undefined });
  }
}
