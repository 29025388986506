import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import jsQR, { QRCode } from 'jsqr';

// Services
import { LoggerService } from '../../../core/services/logger/logger.service';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.scss'],
})
export class QRCodeScannerComponent implements AfterViewInit, OnDestroy {
  @Output() camerasFound = new EventEmitter<MediaDeviceInfo[]>();
  @Output() cameraError = new EventEmitter<void>();
  @Output() scanSuccess = new EventEmitter<string>();
  @Output() notHTTPSEnvironment = new EventEmitter<void>();

  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  mediaStream: MediaStream;
  animationFrameId: number;

  constructor(private logger: LoggerService) {}

  ngAfterViewInit(): void {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((media) => {
          media.getTracks().forEach((track) => track.stop());

          const video = this.video.nativeElement;

          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');

          void navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => this.camerasFound.emit(devices.filter((d) => d.kind === 'videoinput')));
        })
        .catch(() => this.cameraError.emit());
    } else {
      this.notHTTPSEnvironment.emit();
    }
  }

  startScan({ deviceId }: MediaDeviceInfo): void {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId,
          width: { min: 640, max: 1024 },
          height: { min: 480, max: 768 },
        },
      })
      .then(async (mediaStream) => {
        this.mediaStream = mediaStream;
        const video = this.video.nativeElement;
        video.srcObject = mediaStream;
        await video.play();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.animationFrameId = requestAnimationFrame(this.tick.bind(this));
      })
      .catch((error) => {
        this.logger.consoleError('QRCodeScannerComponent', 'startScan', error);
        this.cameraError.emit();
      });
  }

  ngOnDestroy(): void {
    this.stopScan();
  }

  stopScan(): void {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => {
        this.mediaStream.removeTrack(track);
        track.stop();
      });
    }

    this.video.nativeElement.srcObject = null;
  }

  private tick(): void {
    const video = this.video.nativeElement;
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const canvas = document.createElement('canvas');
      canvas.hidden = false;
      canvas.width = 640;
      canvas.height = 480;

      const canvasContext = canvas.getContext('2d');
      canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData: ImageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
      const code: QRCode = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        this.stopScan();
        this.scanSuccess.emit(code.data);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.animationFrameId = requestAnimationFrame(this.tick.bind(this));
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.animationFrameId = requestAnimationFrame(this.tick.bind(this));
    }
  }
}
