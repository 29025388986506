import { Injectable } from '@angular/core';
import { Clipboard, WriteOptions } from '@capacitor/clipboard';

// Services
import { ToastService } from '../toast/toast.service';
import { TranslationService } from '../translation/translation.service';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
  constructor(
    private presentToast: ToastService,
    private translate: TranslationService,
  ) {}

  write(text: string, successString?: string, errorString?: string): void {
    let TO_WRITE: WriteOptions = {};
    if (text.includes('http')) {
      TO_WRITE = { url: text };
    } else {
      // eslint-disable-next-line id-blacklist
      TO_WRITE = { string: text };
    }

    Clipboard.write(TO_WRITE)
      .then(
        () =>
          void this.presentToast.showToast(
            '',
            successString ?? this.translate.instant('COMMON.COPY_SUCCESSFULLY'),
            'bottom',
            'fri-light-blue',
            1000,
          ),
      )
      .catch(
        () =>
          void this.presentToast.showErrorToast(
            '',
            errorString ?? this.translate.instant('PWA.NOT_SUPPORTED.ERROR'),
            'bottom',
          ),
      );
  }
}
