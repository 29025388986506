import { Component } from '@angular/core';

// Service
import { NetworkService } from '../../../core/services/network/network.service';

@Component({
  selector: 'not-available-offline',
  templateUrl: './not-available-offline.component.html',
})
export class NotAvailableOfflineComponent {
  constructor(private networkService: NetworkService) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }
}
