<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="1" class="ion-justify-content-center ion-align-items-center ion-no-padding">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()" [disabled]="isLoadingRoom"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="9" class="ion-justify-content-center ion-align-items-center ion-no-padding"> </ion-col>

      <ion-col size="2" class="ion-justify-content-end ion-align-items-center ion-no-padding">
        @if (internetAvailable) {
          @if (isLoadingRoom) {
            <ion-spinner name="lines-small"></ion-spinner>
          } @else {
            <ion-button
              size="small"
              fill="clear"
              color="light"
              (click)="saveChanges(true)"
              [disabled]="!internetAvailable"
            >
              {{ 'TORGRAM.DONE' | translate }}
            </ion-button>
          }
        }
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content>
  <hr />

  @if (roomToEdit) {
    <ion-row>
      <ion-col class="ion-padding">
        <ion-list class="createRoomItems">
          <ion-row class="ion-full-width ion-justify-content-center ion-align-items-center" (click)="addAttachment()">
            <ion-col size="12" class="ion-text-center ion-justify-content-center ion-align-items-center">
              @if (!imagePreview || imagePreview === null) {
                <ion-avatar
                  [ngClass]="{
                    disabledImage: uploadParams === undefined || uploadParams['gallery'] === undefined || isLoadingRoom
                  }"
                  class="missingNewPhoto ion-justify-content-center ion-align-items-center"
                >
                  <ion-icon name="camera-outline" size="large"></ion-icon>
                </ion-avatar>
              } @else {
                <ion-avatar
                  [ngClass]="{
                    disabledImage: uploadParams === undefined || uploadParams['gallery'] === undefined || isLoadingRoom
                  }"
                  class="ion-justify-content-center ion-align-items-center"
                >
                  <ion-img [src]="imagePreview"></ion-img>
                </ion-avatar>
              }
            </ion-col>

            @if (uploadParams !== undefined && uploadParams['gallery'] !== undefined) {
              <ion-col size="12" class="ion-text-center ion-no-padding">
                <ion-label class="maxFileSize" color="fri-dark">
                  {{
                    'TORGRAM.MAX_SIZE'
                      | translate: { size: uploadParams['gallery'].dimension, uom: uploadParams['gallery'].uom }
                  }}
                </ion-label>
              </ion-col>
            }

            <ion-col size="12" class="ion-text-center ion-no-padding">
              <ion-label
                [ngClass]="{
                  disabledImage: uploadParams === undefined || uploadParams['gallery'] === undefined || isLoadingRoom
                }"
                class="ion-text-wrap ion-justify-content-center"
                translate
                color="fri-dark"
              >
                {{ 'TORGRAM.SET_NEW_PHOTO' | translate }}
              </ion-label>
            </ion-col>
          </ion-row>

          <hr />

          <ion-item lines="none">
            <ion-input
              label="{{ 'TORGRAM.NAME' | translate }}"
              placeholder="{{ 'TORGRAM.NAMEPLACEHOLDER' | translate }}"
              label-placement="stacked"
              [disabled]="isLoadingRoom"
              [(ngModel)]="roomToEdit.name"
            >
            </ion-input>
          </ion-item>

          <hr />

          <ion-item lines="none">
            <ion-input
              label="{{ 'TORGRAM.DESCRIPTION' | translate }}"
              placeholder="{{ 'TORGRAM.DESCPLACEHOLDER' | translate }}"
              label-placement="stacked"
              [disabled]="isLoadingRoom"
              [(ngModel)]="roomToEdit.description"
            >
            </ion-input>
          </ion-item>

          <hr />

          <ion-item lines="none">
            <ion-toggle [enableOnOffLabels]="true" [(ngModel)]="roomToEdit.reserved" [disabled]="isLoadingRoom">
              {{ 'TORGRAM.RESERVEDACTIVATION' | translate }}
            </ion-toggle>
          </ion-item>

          @if (isNew) {
            <hr />

            <ion-item lines="none">
              <ion-toggle [enableOnOffLabels]="true" [(ngModel)]="roomToEdit.byInvitation" [disabled]="isLoadingRoom">
                {{ 'TORGRAM.BYINVITATION' | translate }}
              </ion-toggle>
            </ion-item>
          }

          <hr />

          <ion-item lines="none">
            <ion-toggle
              [enableOnOffLabels]="true"
              [(ngModel)]="roomToEdit.historyAvailable"
              [disabled]="!isNew || isLoadingRoom"
            >
              {{ 'TORGRAM.HISTORY' | translate }}
            </ion-toggle>
          </ion-item>

          @if (availableUsers) {
            <hr />

            <ion-item lines="none">
              <ion-select
                [label]="'TORGRAM.USERS' | translate"
                [multiple]="true"
                [(ngModel)]="roomToEdit.newUsers"
                [disabled]="isLoadingRoom"
              >
                @for (user of availableUsers; track $index) {
                  <ion-select-option [value]="user">
                    {{ user.pk.substring(0, 4) }} ... {{ user.pk.substring(user.pk.length - 4) }}
                  </ion-select-option>
                }
              </ion-select>
            </ion-item>
          }
        </ion-list>
      </ion-col>
    </ion-row>
  }
</ion-content>
