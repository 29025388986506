import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { OrientationService } from '../../../../core/services/orientation/orientation.service';

@Component({
  selector: 'app-orientation',
  templateUrl: './orientation.page.html',
  styleUrls: ['./orientation.page.scss'],
})
export class OrientationPage implements OnInit {
  public dismiss$ = new Subject<void>();
  public correctOrientation = false;

  constructor(
    public orientation: OrientationService,
    private modalController: ModalController,
  ) {}

  ngOnInit(): void {
    this.orientation.currentOrientation$.pipe(takeUntil(this.dismiss$)).subscribe({
      next: (value) => (this.correctOrientation = this.isCorrect(value)),
      complete: () => this.modalController.dismiss(),
    });
  }

  orientationString(): string {
    return this.orientation.lockedOrientation$.value.split('-')[0];
  }

  /**
   * Checks if the current orientation matches the locked orientation
   */
  isCorrect(value: OrientationType): boolean {
    return value === this.orientation.lockedOrientation$.value;
  }

  /**
   * Dismisses the modal if orientation has been set
   */
  continueClicked(): void {
    const lock = this.orientation.lockedOrientation$.value;
    void this.orientation.setOrientation(lock).then(() => this.dismiss$.next());
  }
}
