<ion-content class="ion-padding">
  <div class="ion-justify-content-center ion-align-items-center ion-full-height ion-full-width">
    <ion-grid>
      <ng-container *ngIf="!correctOrientation">
        <ion-row class="ion-margin-top">
          <ion-col size="12" class="ion-text-center">
            <ion-icon [class]="orientationString()" color="secondary" name="phone-portrait-outline"></ion-icon><br />
          </ion-col>

          <ion-col size="12" class="ion-text-center ion-margin-top">
            <ion-label translate>MODALS.PORTRAIT_PWA.DESCRIPTION</ion-label>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-container *ngIf="correctOrientation">
        <ion-row class="ion-margin-top">
          <ion-col size="12" class="ion-text-center">
            <ion-icon color="success" name="checkmark-circle-outline"></ion-icon>
          </ion-col>

          <ion-col size="12" class="ion-text-center ion-margin-top">
            <ion-label translate>MODALS.PORTRAIT_PWA.CORRECT</ion-label>
          </ion-col>
        </ion-row>
      </ng-container>

      <ion-row class="ion-margin-top">
        <ion-col size="12" class="ion-text-center">
          <ion-button (click)="continueClicked()" [disabled]="!correctOrientation" color="secondary" size="small">
            <ion-label translate>MODALS.PORTRAIT_PWA.CONTINUE</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
