<ion-toolbar
  color="fri-violet"
  [class.ion-justify-content-center]="newsFeed === ''"
  [class.ion-align-items-center]="newsFeed === ''"
>
  @if (newsFeed !== '') {
    <ion-label rightToLeftAnimation [duration]="newsFeed.length * 130" [innerHTML]="newsFeed"> </ion-label>
  } @else {
    <ion-spinner name="dots" class="ion-full-width"></ion-spinner>
  }
</ion-toolbar>
