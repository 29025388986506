import { Component, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { IonModal } from '@ionic/angular';

import SwiperCore, { Pagination, Zoom } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ZoomOptions } from 'swiper/types';

// Services
import { ModalService } from '../../../core/services/modal/modal.service';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
SwiperCore.use([Pagination, Zoom]);

@Component({
  selector: 'app-modal-attachment',
  templateUrl: './modal-attachments.page.html',
  styleUrls: ['./modal-attachments.page.scss'],
})
export class ModalAttachmentsPage {
  @ViewChild('swiper') swiper: SwiperComponent;

  @Input() isOpen: boolean = false;
  @Input() images: string[];
  @Input() initialSlide?: number = 0;

  @Output() didDismiss: EventEmitter<void> = new EventEmitter<void>();

  protected zoomOptions: ZoomOptions = {
    minRatio: 1,
    maxRatio: 3,
  };

  constructor(private modalService: ModalService) {}

  zoom(direction: 'in' | 'out'): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const zoom = this.swiper.swiperRef.zoom;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    zoom[direction]();
  }

  async dismissModal(modal: IonModal): Promise<void> {
    await modal.dismiss();
    await this.modalService.dismissLast();
  }
}
