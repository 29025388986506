/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Room } from '../../types/tables';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(itemsList: Room[], filter: string): Room[] {
    if (itemsList && filter) {
      filter = filter.toLocaleLowerCase();

      return itemsList.filter(
        (item) =>
          item.name?.toLocaleLowerCase().includes(filter) || item.description?.toLocaleLowerCase().includes(filter),
      );
    }
    return itemsList;
  }
}
