import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Subject, Subscription } from 'rxjs';

// Types
import { PopoverSettingOptions } from '../../../shared/types/socket';
import { SocketEnv } from '../../../shared/types/tor';
import { Room, RoomJoinedParams, RoomMembers } from '../../../shared/types/tables';
import { StorageKey } from '../../../shared/types/storage';

// Services
import { LoggerService } from '../logger/logger.service';
import { KeysService } from '../keys/keys.service';
import { ToastService } from '../toast/toast.service';
import { TranslationService } from '../translation/translation.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { StorageService } from '../storage/storage.service';
import { NetworkService } from '../network/network.service';

// Environment
import { environment } from '../../../../environments/environment';

// Utils
import { SocketEvents } from './socket-events';

@Injectable({ providedIn: 'root' })
export class SocketService {
  public manageSettingOptions: PopoverSettingOptions = {
    message: '',
  };
  public socket: Socket;
  public alreadyCreatedSocket?: boolean = false;
  public env: SocketEnv;
  public socketChanged: Subject<void> = new Subject<void>();
  public isJoiningStarted: boolean = false;
  public ignoreSocketError: boolean = false;

  public socketErrorMessage: string | undefined = undefined;

  private pageCaller: string | undefined = undefined;
  private commonSocketEvents: string[] = [
    SocketEvents.CONNECT_ERROR,
    SocketEvents.CONNECT_FAILED,
    SocketEvents.DISCONNECT,
    SocketEvents.ERROR,
    SocketEvents.RECONNECT,
  ];
  private connectionSubscription: Subscription;
  private needSocketChanged: boolean = false;

  private WEBSERVICES_MODE = environment.WEBSERVICES_MODE as 'ONLINE' | 'LOCAL';

  private TOR_WEB_SERVICE_URL = environment.WEBSERVICES[this.WEBSERVICES_MODE].TOR_WEB_SERVICE_URL;
  private WEB_WEB_SERVICE_URL = environment.WEBSERVICES[this.WEBSERVICES_MODE].WEB_WEB_SERVICE_URL;
  private INVITE_LINK = environment.WEBSERVICES[this.WEBSERVICES_MODE].INVITE_LINK;

  private roomJoinRequestSubscription: Subscription;
  private roomAddedUsersSubscription: Subscription;
  private roomBanMemberSubscription: Subscription;

  private maxAttempts: number = 0;

  constructor(
    private logger: LoggerService,
    private keysService: KeysService,
    private toast: ToastService,
    private translate: TranslationService,
    private auth: AuthenticationService,
    private storage: StorageService,
    private network: NetworkService,
  ) {}

  get baseUrl(): string {
    const url = this.env === 'web' ? this.WEB_WEB_SERVICE_URL : this.TOR_WEB_SERVICE_URL;
    return url;
  }

  get baseUrlAttachment(): string {
    return `${this.baseUrl}/assets/${this.env}`;
  }

  get inviteLinkBaseUrl(): string {
    return this.INVITE_LINK;
  }

  get isSettingTextBoxVisible(): boolean {
    return (
      this.manageSettingOptions?.isReply || this.manageSettingOptions?.isForward || this.manageSettingOptions?.isEdit
    );
  }

  get imgReply(): string {
    if (this.manageSettingOptions?.messageDetails?.attachments) {
      if (this.manageSettingOptions?.messageDetails.attachments[0].type.includes('pdf')) {
        return 'assets/custom-icons/pdf.svg';
      } else {
        return (
          this.baseUrl +
          '/assets/room-' +
          this.manageSettingOptions?.messageDetails.roomId +
          '/' +
          this.manageSettingOptions?.messageDetails.attachments[0].name
        );
      }
    }
  }

  get icon(): string {
    if (this.manageSettingOptions?.isReply) {
      return 'arrow-undo-outline';
    } else if (this.manageSettingOptions?.isForward) {
      return 'arrow-redo-outline';
    } else if (this.manageSettingOptions?.isEdit) {
      return 'pencil-outline';
    }
  }

  public initSocket(from?: string, env?: SocketEnv): Promise<void> {
    this.socketErrorMessage = undefined;
    this.pageCaller = from;

    return new Promise((resolve) => {
      this.storage.getFromStorage(StorageKey.CurrentEnvironment).subscribe({
        next: async (currentEnvironment: SocketEnv) => {
          if (!env || env === null) {
            env = currentEnvironment;
          }

          this.logger.consoleLog(
            'SocketService',
            'initSocket' + (from !== undefined ? ' ' + from : ''),
            'this.socket === undefined: ' +
              (this.socket === undefined).toString() +
              ' | env: ' +
              env +
              ' | this.env: ' +
              this.env,
          );

          const needToCreateSocket = this.socket === undefined || this.env !== env;
          if (needToCreateSocket) {
            await this.createSocket(env);
            this.alreadyCreatedSocket = false;
          }

          if (!this.alreadyCreatedSocket && this.socket !== undefined) {
            this.logger.consoleLog(
              'SocketService',
              'initSocket' + (from !== undefined ? ' ' + from : ''),
              'setup common events',
            );

            this.alreadyCreatedSocket = true;

            this.socket.onAny((event, ...args) => {
              this.logger.consoleLog('SocketService', 'ON ANY: ' + event, args);
            });

            this.socket.onAnyOutgoing((event, ...args) => {
              this.logger.consoleLog('SocketService', 'ON ANY OUTGOING: ' + event, args);
            });

            this.socket.on(SocketEvents.CONNECT_ERROR, (error: { message: string }) => {
              this.logger.consoleError('SocketService', 'SocketEvents.CONNECT_ERROR', error);
              this.socket?.disconnect();
              this.socket = undefined;

              this.handleErrors(error);
            });
            this.socket.on(SocketEvents.CONNECT_FAILED, (error: { message: string }) => {
              this.logger.consoleError('SocketService', 'SocketEvents.CONNECT_FAILED');
              this.socket?.disconnect();
              this.socket = undefined;

              this.handleErrors(error);
            });
            this.socket.on(SocketEvents.DISCONNECT, (error: { message: string }) => {
              this.logger.consoleError('SocketService', 'SocketEvents.DISCONNECT', error);
              this.socket?.disconnect();
              this.socket = undefined;

              this.handleErrors(error);
            });
            this.socket.on(SocketEvents.ERROR, (error: { message: string }) => {
              this.logger.consoleError('SocketService', 'SocketEvents.ERROR', error);
              this.handleErrors(error);
            });
            this.socket.on(SocketEvents.RECONNECT, () => {
              this.logger.consoleError('SocketService', 'SocketEvents.RECONNECT');
            });

            this.roomJoinRequestSubscription = this.socket.fromEvent(SocketEvents.ROOM_JOIN_REQUEST).subscribe({
              next: (joinedParams: RoomJoinedParams) => {
                void this.toast.showSuccessToast(
                  this.translate.instant('COMMON.SUCCESS'),
                  this.translate.instant('TORGRAM.YOU_HAVE_BEEN_ADDED_TO', {
                    room: joinedParams.room.name,
                  }),
                  'bottom',
                );
              },
            });
            this.roomAddedUsersSubscription = this.socket.fromEvent(SocketEvents.ROOM_ADDED_USER).subscribe({
              next: (room: Room) => {
                void this.toast.showSuccessToast(
                  this.translate.instant('COMMON.SUCCESS'),
                  this.translate.instant('TORGRAM.YOU_HAVE_BEEN_ADDED_TO', {
                    room: room.name,
                  }),
                  'bottom',
                );
              },
            });
            this.roomBanMemberSubscription = this.socket
              .fromEvent<{ members: RoomMembers[]; room: Room }>(SocketEvents.ROOM_BAN_MEMBER)
              .subscribe({
                next: ({ members, room }) => {
                  const index = members?.findIndex(({ userId }) => userId === this.auth.userId);

                  if (index === -1) {
                    void this.toast.showWarningToast(
                      this.translate.instant('COMMON.CAUTION'),
                      this.translate.instant('TORGRAM.YOUHASBEENDELETED', { room: room.name }),
                      'bottom',
                      5000,
                    );
                  }
                },
              });

            if (this.needSocketChanged) {
              this.logger.consoleError('SocketService', 'initSocket', 'socketChanged');
              this.socketChanged.next();
              this.needSocketChanged = false;
            }
          }

          resolve();
        },
      });
    });
  }

  private socketError(error: string): void {
    void this.toast.dismissToast();

    setTimeout(async () => {
      await this.toast.showErrorToast(
        this.translate.instant('COMMON.CAUTION'),
        this.translate.instant('TORGRAM.ERRORS.' + error),
        'bottom',
      );
    }, 500);
  }

  private handleErrors(error: { message: string } | string): void {
    this.socketErrorMessage =
      typeof error === 'string'
        ? error.replace(/ /g, '_').toUpperCase()
        : error.message.replace(/ /g, '_').toUpperCase();

    this.logger.consoleError('SocketService', 'handleErrors', {
      error: this.socketErrorMessage,
      pageCaller: this.pageCaller,
      ignoreSocketError: this.ignoreSocketError,
    });

    this.checkSocketError();

    const shouldShowToast =
      !['CANNOT_JOIN_ROOM', 'NOT_PUBLIC_KEY_REGISTERED'].includes(this.socketErrorMessage) &&
      !(this.socketErrorMessage === 'IO_CLIENT_DISCONNECT' && this.pageCaller === 'TorgramListPage') &&
      !(this.socketErrorMessage === 'TRANSPORT_ERROR' && this.pageCaller === 'TorgramRoomFooterComponent') &&
      !this.ignoreSocketError;

    if (shouldShowToast) {
      this.logger.consoleError('SocketService', 'handleErrors > show toast');
      this.socketError(this.socketErrorMessage);
    }
  }

  private createSocket(env?: SocketEnv): Promise<void> {
    this.logger.consoleLog('SocketService', 'createSocket', env);

    return new Promise((resolve, reject) => {
      if (this.keysService.publicKey !== undefined && this.auth.loggedUser && this.auth.loggedUser?.ID !== undefined) {
        if (this.socket) {
          this.resetConnections();
          this.socket?.disconnect();
        }

        if (this.env !== env) {
          this.storage.setOnStorage(StorageKey.CurrentEnvironment, env).subscribe();
        }

        const config: SocketIoConfig = {
          url: env === 'tor' ? this.TOR_WEB_SERVICE_URL : this.WEB_WEB_SERVICE_URL,
          options: {
            extraHeaders: {
              publicKey: this.keysService.publicKey,
              userId: this.auth.loggedUser?.ID.toString(),
              env,
            },
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 30000,
            reconnectionAttempts: 10,
          },
        } as SocketIoConfig;

        this.logger.consoleLog('SocketService', 'createSocket', config);

        if (this.env !== env) {
          this.env = env;
        }

        this.socket = new Socket(config);
        this.socket.connect();
        this.maxAttempts = 0;
        this.connectionSubscription?.unsubscribe();

        resolve();
      } else {
        this.logger.consoleError('SocketService', 'createSocket', 'MISSING DATA');
        reject();
      }
    });
  }

  private resetConnections(): void {
    this.logger.consoleLog('SocketService', 'resetConnections');

    this.commonSocketEvents.map((event) => {
      this.socket.off(event);
    });

    this.roomAddedUsersSubscription?.unsubscribe();
    this.roomBanMemberSubscription?.unsubscribe();
    this.roomJoinRequestSubscription?.unsubscribe();
    this.connectionSubscription?.unsubscribe();
  }

  private checkSocketError(): void {
    this.logger.consoleLog('SocketService', 'checkSocketError', {
      socketErrorMessage: this.socketErrorMessage,
      maxAttempts: this.maxAttempts,
    });

    if (
      ['TRANSPORT_ERROR', 'TRANSPORT_CLOSE', 'XHR_POLL_ERROR'].includes(this.socketErrorMessage) &&
      this.maxAttempts < 3
    ) {
      this.maxAttempts += 1;

      this.connectionSubscription = this.network.connectedSubject?.subscribe({
        next: async () => {
          this.logger.consoleLog('SocketService', 'checkSocketError', 'network.connectedSubject');

          this.connectionSubscription?.unsubscribe();
          this.connectionSubscription = undefined;
          this.needSocketChanged = false;
          await this.initSocket(this.pageCaller, this.env);
        },
      });

      setTimeout(async () => {
        if (this.connectionSubscription !== undefined) {
          if (this.network.connected) {
            this.logger.consoleLog('SocketService', 'checkSocketError', 'network.connected');

            this.connectionSubscription?.unsubscribe();
            this.needSocketChanged = true;
            await this.initSocket(this.pageCaller, this.env);
          } else {
            this.logger.consoleError(
              'SocketService',
              'checkSocketError',
              'Can`t reconnect correctly, network.connected = false',
            );
          }
        }
      }, 5000);
    }
  }
}
