import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { format, parseISO } from 'date-fns';

interface IonDateTimeEvent extends CustomEvent {
  detail: { value: string };
}

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent {
  @Input() position?: 'static' | 'stacked' | 'fixed';
  @Input() labelText?: string;
  @Input() placeholder?: string;
  @Input() min?: string;
  @Input() max?: string;

  @Input() formGroup?: UntypedFormGroup;
  @Input() controlName?: string;

  get showDate(): string {
    const dateISO: string = this.formGroup.controls[this.controlName].value as string;
    return dateISO ? format(parseISO(dateISO), 'dd/MM/yyyy') : null;
  }

  changeDate(event: Event | IonDateTimeEvent): void {
    const value = (event as IonDateTimeEvent).detail.value;
    this.formGroup.controls[this.controlName].setValue(value);
  }
}
