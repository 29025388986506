import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { OrientationPageRoutingModule } from './orientation-routing.module';
import { OrientationPage } from './pages/orientation.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, OrientationPageRoutingModule, TranslateModule.forChild()],
  declarations: [OrientationPage],
})
export class OrientationPageModule {}
