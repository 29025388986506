import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.page.html',
  styleUrls: ['./custom-toast.page.scss'],
})
export class CustomToastPage {
  constructor(private modalController: ModalController) {}

  forceRefresh(): void {
    void this.modalController.dismiss({ refresh: true });
  }

  closeMessage(): void {
    void this.modalController.dismiss({ refresh: false });
  }
}
