import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Types
import { Room, RoomMembers } from '../../../../shared/types/tables';
import { ChangeClaimRoomParams } from '../../../../shared/types/socket';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

// Services
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../core/services/logger/logger.service';

@Component({
  selector: 'app-torgram-promote-list',
  templateUrl: './torgram-promote-list.component.html',
  styleUrls: ['./torgram-promote-list.component.scss'],
})
export class TorgramPromoteListComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() role?: string;
  @Input() room: Room;

  public searchBoxTxt: string = '';
  public roomMembers: RoomMembers[];
  public isLoadingMemebers: boolean = false;
  public isLoadingDemote: boolean = false;

  private getRoomMemberClaimsSubscription: Subscription;
  private getRoomMemberSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslationService,
    private socketService: SocketService,
    private modalService: ModalService,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.getRoomMemberClaimsSubscription?.unsubscribe();
    this.getRoomMemberSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.networkService.pageNgOnInit(
      'TorgramPromoteListComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramPromoteListComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  disabled(member: RoomMembers): boolean {
    return member[this.role] === true || member[this.role] === undefined;
  }

  async dismissModal(): Promise<void> {
    await this.socket.emit(SocketEvents.ROOM_GET_MEMBERS, { roomId: this.room.id, role: this.role });

    setTimeout(async () => {
      await this.modal.dismiss();
      await this.modalService.dismissLast();
    }, 200);
  }

  async promoteUser(member: RoomMembers): Promise<void> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('TORGRAM.PROMOTE'),
      buttons: [
        {
          text: this.translate.instant('TORGRAM.YES'),
          role: 'confirm',
          handler: () => {
            const updateMember = {
              roomId: this.room.id,
              memberId: member.userId,
              claims: {
                [this.role]: true,
              },
            } as ChangeClaimRoomParams;

            this.promoteMember(updateMember);
          },
        },
        {
          text: this.translate.instant('TORGRAM.NO'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  private getMembers(roomId: Room['id']): void {
    this.isLoadingMemebers = true;
    this.socket.emit(SocketEvents.ROOM_GET_MEMBERS, {
      roomId,
    });
  }

  private promoteMember(updateMember: ChangeClaimRoomParams): void {
    this.isLoadingDemote = true;
    this.socket.emit(SocketEvents.ROOM_UPDATE_MEMBER_CLAIMS, { ...updateMember });

    setTimeout(async () => {
      await this.modal.dismiss();
      await this.modalService.dismissLast();
    }, 200);
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramPromoteListComponent');
    }

    setTimeout(() => {
      this.getRoomMemberClaimsSubscription = this.socket.fromEvent(SocketEvents.ROOM_MEMBER_CLAIMS).subscribe({
        next: (results: RoomMembers[]) => {
          this.roomMembers = results;
          this.isLoadingDemote = false;
        },
      });

      this.getRoomMemberSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET_MEMBERS).subscribe({
        next: (results: RoomMembers[]) => {
          this.roomMembers = results;
          this.isLoadingMemebers = false;
        },
      });

      this.getMembers(this.room.id);
    }, 200);
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramPromoteListComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }
}
