export const SocketEvents = {
  CONNECT_ERROR: 'connect_error',
  CONNECT_FAILED: 'connect_failed',
  DISCONNECT: 'disconnect',
  ERROR: 'error',
  GENERAL_SEARCH: 'general-search',
  GET_UPLOAD_PARAMS: 'room-get-upload-params',
  GET_USER_ID: 'get-user-id',
  JOIN_ROOMS: 'join-rooms',
  RECONNECT: 'reconnect',
  ROOM_ADDED_USER: 'room-added-user',
  ROOM_ADD_USERS: 'room-add-users',
  ROOM_AVAILABLE_USERS: 'room-available-users',
  ROOM_BANNED_USERS: 'room-banned-users',
  ROOM_BAN_MEMBER: 'room-ban-member',
  ROOM_CREATE: 'room-create',
  ROOM_DELETE_ALL_MESSAGES: 'room-delete-all-messages',
  ROOM_DELETE_MESSAGE: 'room-delete-message',
  ROOM_EDIT: 'room-edit',
  ROOM_EDIT_MESSAGE: 'room-edit-message',
  ROOM_GENERATE_INVITE_LINK: 'room-generate-invite-link',
  ROOM_GET: 'room-get',
  ROOM_GET_CUSTOM_LINK: 'room-get-custom-links',
  ROOM_GET_INVITE_LINKS: 'room-get-invite-links',
  ROOM_GET_MEMBERS: 'room-get-members',
  ROOM_GET_MESSAGES: 'room-get-messages',
  ROOM_JOINED: 'room-joined',
  ROOM_JOIN_FROM_INVITE_LINK: 'room-join-from-invite-link',
  ROOM_JOIN_FROM_QR_CODE: 'room-join-from-qr-code',
  ROOM_JOIN_REQUEST: 'room-join-request',
  ROOM_MEMBER_CLAIMS: 'room-member-claims',
  ROOM_MEMBER_NEW_CLAIM: 'room-member-new-claim',
  ROOM_NEW_JOIN_REQUEST: 'room-new-join-request',
  ROOM_NEW_MESSAGE: 'room-new-message',
  ROOM_READ_MESSAGES: 'room-read-messages',
  ROOM_REVOKE_DEFAULT_INVITE_LINK: 'room-revoke-default-invite-link',
  ROOM_REVOKE_INVITE_LINK: 'room-revoke-invite-link',
  ROOM_SEND_MESSAGE: 'room-send-message',
  ROOM_SUBSCRIBE: 'room-subscribe',
  ROOM_TYPES: 'room-types',
  ROOM_UNBAN_USER: 'room-unban-user',
  ROOM_UNSUBSCRIBE: 'room-unsubscribe',
  ROOM_UPDATE_MEMBER_CLAIMS: 'room-update-member-claims',
  SEARCH_MESSAGE: 'search-message',
};
