/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { from, Observable } from 'rxjs';

// Services
import { AESEncryptDecryptService } from '../AESEncryptDecrypt/aesencrypt-decrypt.service';
import { LoggerService } from '../logger/logger.service';

// Types
import { StorageKey } from '../../../shared/types/storage';

@Injectable({ providedIn: 'root' })
export class StorageService {
  public _storage: Storage = null;

  constructor(
    private storage: Storage,
    private secureData: AESEncryptDecryptService,
    private logger: LoggerService,
  ) {
    void this.init();
  }

  /**
   * @param key string
   * @returns
   */
  setOnStorage<T = void>(key: StorageKey | string, value: unknown): Observable<T> {
    const type: string = typeof value;
    const typeofdata: string = this.secureData.encrypt(type);
    const encrypteddata: string = this.secureData.encrypt(
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      (type === 'object' ? JSON.stringify(value) : value !== null ? value?.toString() : value) as string,
    );

    this.logger.consoleLog('StorageService', 'setOnStorage', { [key]: value });

    return from(
      Promise.all([this.storage.set(key + '_typeof', typeofdata), this.storage.set(key, encrypteddata)]),
    ) as Observable<T>;
  }

  getFromStorage<T = unknown>(key: StorageKey | string): Observable<T> {
    return new Observable((observer) => {
      this.getDataToDecrypt(key).subscribe({
        next: ({ datatodecrypt, typetodecrypt }) => {
          const decryptedData: string = datatodecrypt !== null ? this.secureData.decrypt(datatodecrypt) : datatodecrypt;
          const type: string = typetodecrypt !== null ? this.secureData.decrypt(typetodecrypt) : typetodecrypt;

          // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
          let dataToReturn: any | boolean | string;
          if (type === 'object' || type === 'boolean') {
            dataToReturn = JSON.parse(decryptedData);
          } else {
            // Return true or false as booleans
            dataToReturn = decryptedData;
          }

          if (dataToReturn === null) {
            this.logger.consoleError('StorageService', 'getFromStorage', { key });
            observer.error(dataToReturn);
          } else {
            this.logger.consoleLog('StorageService', 'getFromStorage', { key, dataToReturn });
            observer.next(dataToReturn as T);
          }
        },
      });
    });
  }

  removeFromStorage(key: StorageKey | string): Observable<[boolean, boolean]> {
    this.logger.consoleLog('StorageService', 'removeFromStorage', { key });
    return from(Promise.all([this.storage.remove(key + '_typeof'), this.storage.remove(key)])) as Observable<
      [boolean, boolean]
    >;
  }

  private getDataToDecrypt(key: string): Observable<{ datatodecrypt: string; typetodecrypt: string }> {
    return from(
      new Promise<{ datatodecrypt: string; typetodecrypt: string }>(async (resolve) => {
        const datatodecrypt = (await this.storage.get(key)) as string;
        const typetodecrypt = (await this.storage.get(key + '_typeof')) as string;
        resolve({ datatodecrypt, typetodecrypt });
      }),
    );
  }

  private async init(): Promise<void> {
    await this.storage
      .create()
      .then((storage) => {
        this.logger.consoleLog('StorageService', 'init', 'storage create successfully');
        this._storage = storage;
      })
      .catch((error) => {
        this.logger.consoleError('StorageService', 'init', error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(error);
      });
  }
}
