/* eslint-disable no-console */
import { Injectable } from '@angular/core';

// Environment
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private readonly LOG_IN_CONSOLE = environment.LOGS.CONSOLE;
  private readonly LOG_ERROR_IN_CONSOLE = environment.LOGS.ERROR;
  private readonly LOG_SOCKET_IN_CONSOLE = environment.LOGS.SOCKET;
  private readonly LOG_STORAGE_IN_CONSOLE = environment.LOGS.STORAGE;
  private readonly LOG_NETWORK_IN_CONSOLE = environment.LOGS.NETWORK;
  private readonly LOG_PWA_IN_CONSOLE = environment.LOGS.PWA;

  constructor() {}

  public consoleLog(from: string, func: string, message: unknown = ''): void {
    if (
      this.LOG_IN_CONSOLE ||
      (this.LOG_SOCKET_IN_CONSOLE &&
        (from.toLowerCase().includes('socket') || func.toLowerCase().includes('socket'))) ||
      (this.LOG_STORAGE_IN_CONSOLE &&
        (from.toLowerCase().includes('storage') || func.toLowerCase().includes('storage'))) ||
      (this.LOG_NETWORK_IN_CONSOLE &&
        (from.toLowerCase().includes('network') || func.toLowerCase().includes('network'))) ||
      (this.LOG_PWA_IN_CONSOLE && (from.toLowerCase().includes('pwa') || func.toLowerCase().includes('pwa')))
    ) {
      if (message !== undefined && message !== '') {
        this.logConsole(`[${from}]`, `(${func})`, message);
      } else {
        this.logConsole(`[${from}]`, `(${func})`);
      }
    }
  }

  public consoleError(from: string, func: string, message: unknown = ''): void {
    if (this.LOG_ERROR_IN_CONSOLE) {
      if (message !== undefined && message !== '') {
        this.logError(`[${from}]`, `(${func})`, message);
      } else {
        this.logError(`[${from}]`, `(${func})`);
      }
    }
  }

  private logColor(func: string): string | undefined {
    if (func.toLowerCase().includes('network')) {
      return 'color: #ffa012';
    }

    if (func.toLowerCase().includes('socket')) {
      return 'color: #bada55';
    }

    if (func.toLowerCase().includes('storage')) {
      return 'color: #aed3e3';
    }

    if (func.toLowerCase().includes('pwa')) {
      return 'color: #ff1aff';
    }

    return undefined;
  }

  private logConsole = (...args) => {
    const message = this.buildMessage(args);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const color = this.logColor(args[0]) || this.logColor(args[1]);

    if (color) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      console.log(message, color, '', ...args);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      console.log(...args);
    }
  };

  private logError = (...args) => {
    const message = this.buildMessage(args);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const color = this.logColor(args[0]) || this.logColor(args[1]);

    if (color) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      console.error(message, color, '', ...args);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      console.error(...args);
    }
  };

  private buildMessage(...args): string {
    let messageConfig = '%c%s   ';

    args.forEach((argument) => {
      const type = typeof argument;
      switch (type) {
        case 'bigint':
        case 'number':
          messageConfig += '%d ';
          break;

        case 'string':
          messageConfig += '%s ';
          break;

        case 'object':
        case 'boolean':
        case 'undefined':
        default:
          messageConfig += '%o ';
      }
    });

    return messageConfig;
  }
}
