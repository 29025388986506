import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { AlertController, ViewDidLeave } from '@ionic/angular';
import { QRCode } from 'jsqr';

// Types
import { Key } from '../../types/key';

// Services
import { LoaderService } from '../../../core/services/loader/loader.service';
import { TranslationService } from '../../../core/services/translation/translation.service';
import { QRCodeReaderService } from '../../../core/services/qr-code-reader/qr-code-reader.service';
import { KeymasterCryptlibService } from '../../../core/services/keymaster-cryptlib/keymaster-cryptlib.service';
import { KeysService } from '../../../core/services/keys/keys.service';

@Component({
  selector: 'app-gatekeeper-key',
  templateUrl: './gatekeeper.page.html',
  styleUrls: ['./gatekeeper.page.scss'],
})
export class GatekeeperKeyPage implements OnInit, AfterViewInit, ViewDidLeave {
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  keySelected: Key = null;
  show: string;
  response: string;
  scanActive: boolean = true;
  videoElement: HTMLVideoElement;
  canvasElement: HTMLCanvasElement;
  allowed: boolean = true;

  constructor(
    private keysService: KeysService,
    private presentLoading: LoaderService,
    private alertController: AlertController,
    private translate: TranslationService,
    private qrCodeReader: QRCodeReaderService,
    private myCrypt: KeymasterCryptlibService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.keysService.get();
  }

  ngAfterViewInit(): void {
    this.videoElement = this.video.nativeElement;
    this.canvasElement = this.canvas.nativeElement;

    this.qrCodeReader.scanEnded.subscribe({
      next: (qrcode: QRCode) => {
        this.scanActive = false;
        void this.challenge(qrcode.data, this.keysService.keys[0]);
      },
    });

    this.startScan();
  }

  startScan(): void {
    this.scanActive = true;
    void this.qrCodeReader.startScan(this.videoElement, this.canvasElement);
  }

  async challenge(scanResult: string, key: Key): Promise<void> {
    this.keySelected = key;

    if (scanResult.includes('http://') || scanResult.includes('https://') || scanResult.includes('www.')) {
      this.scanActive = false;
      this.presentLoading.dismiss();

      const alert = await this.alertController.create({
        header: this.translate.instant('COMMON.CAUTION'),
        message: this.translate.instant('FUNCTIONALITIES.GATEKEEPER.IS_LINK'),
        buttons: [
          {
            text: this.translate.instant('COMMON.CANCEL'),
            role: 'cancel',
            handler: () => this.startScan(),
          },
          {
            text: this.translate.instant('FUNCTIONALITIES.GATEKEEPER.OPEN_LINK'),
            role: 'ok',
            handler: () => {
              this.scanActive = false;
              window.open(scanResult, '_blank');
            },
          },
        ],
      });

      await alert.present();
    } else {
      try {
        this.response = this.myCrypt.createResponseMessage(
          scanResult,
          this.keySelected.private,
          this.keySelected.public,
        );

        this.scanActive = false;
        this.show = 'qr';
      } catch (e) {
        this.scanActive = false;
        this.presentLoading.dismiss();

        const alert = await this.alertController.create({
          header: this.translate.instant('COMMON.ERROR'),
          message: this.translate.instant('FUNCTIONALITIES.GATEKEEPER.READ_ERROR'),
          buttons: [
            {
              text: this.translate.instant('COMMON.OK'),
              role: 'ok',
            },
            {
              text: this.translate.instant('COMMON.RETRY'),
              role: 'ok',
              handler: () => this.startScan(),
            },
          ],
        });

        await alert.present();
      }
    }
  }

  ionViewDidLeave(): void {
    this.qrCodeReader.stopScan();
  }
}
