import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// Types
import { ILoggedUser } from '../../../shared/types';
import { StorageKey } from '../../../shared/types/storage';

// Services
import { LoggerService } from '../logger/logger.service';
import { LoaderService } from '../loader/loader.service';
import { TranslationService } from '../translation/translation.service';
import { StorageService } from '../storage/storage.service';
import { ApiService } from '../../http/api.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  public IS_LOGGED: boolean;
  public TOKEN: string = '';

  constructor(
    private presentLoading: LoaderService,
    private storage: StorageService,
    private api: ApiService,
    private logger: LoggerService,
    private translate: TranslationService,
  ) {}

  reLog(): Observable<ILoggedUser | boolean | string | Error> {
    return this.api.web.isAuthenticated().pipe(
      // eslint-disable-next-line @typescript-eslint/require-await
      switchMap((user: ILoggedUser) => {
        this.logger.consoleError('SessionService', 'isAuthenticated', user);

        if (user.token === null || (user.token !== null && user.token.length === 0)) {
          this.storage.removeFromStorage(StorageKey.LoggedUser).subscribe();
          this.storage.setOnStorage(StorageKey.LastCategorySelected, 'BLOG').subscribe();
          this.presentLoading.dismiss();
          const error = new Error(this.translate.instant('NEWS.ERROR_LOG_AGAIN'));
          error.name = 'NEED_REDO_LOGIN';
          return throwError(() => error);
        } else {
          return of(user);
        }
      }),
      catchError((error: Error) => {
        this.IS_LOGGED = false;

        this.logger.consoleError('SessionService', 'reLog', error?.name);
        return throwError(() => error);
      }),
    );
  }
}
