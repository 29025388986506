import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AlertController } from '@ionic/angular';

// Services
import { StorageService } from '../../services/storage/storage.service';
import { SessionService } from '../../services/session/session.service';
import { LoggerService } from '../../services/logger/logger.service';
import { TranslationService } from '../../services/translation/translation.service';
import { ApiService } from '../../http/api.service';
import { KeysService } from '../../services/keys/keys.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';

// Types
import { ITransaction, ITransactionPending } from '../../../shared/types/wallet';
import { ILoggedUser } from '../../../shared/types';
import { StorageKey } from '../../../shared/types/storage';

export const ApplicationGuard: CanActivateFn = (route, state) => {
  let reLogAlreadyFired: boolean = false;
  const PRIVATE_URLS = [
    '/wallet',
    '/wallet/balance-and-transactions',
    '/wallet/confirm-operation',
    '/wallet/token-transfer',
    '/torgram',
    '/torgram/channel',
    '/torgram/chat',
    '/torgram/invite-link',
  ];

  const api = inject(ApiService);
  const storage = inject(StorageService);
  const alertController = inject(AlertController);
  const router = inject(Router);
  const logger = inject(LoggerService);
  const translate = inject(TranslationService);
  const sessionService = inject(SessionService);
  const keysService = inject(KeysService);
  const authentication = inject(AuthenticationService);

  const getFRITokenAvailability = (): void => {
    storage.getFromStorage(StorageKey.FRITokenAvailability).subscribe({
      error: (error) => {
        if (error === null && sessionService.IS_LOGGED) {
          api.wallet.getAvailability().subscribe({
            next: ({ FRI_TOKEN }: { FRI_TOKEN: number }) =>
              storage.setOnStorage(StorageKey.FRITokenAvailability, FRI_TOKEN === null ? 0 : FRI_TOKEN).subscribe(),
            error: (_error) => logger.consoleError('ApplicationGuard', 'FRITokenAvailability', _error),
          });
        }
      },
    });
  };

  const getFRITransactionsHistory = (): void => {
    storage.getFromStorage(StorageKey.FRITransactionsHistory).subscribe({
      error: (error) => {
        if (error === null && sessionService.IS_LOGGED) {
          api.wallet.getTransactionsHistory().subscribe({
            next: (data: ITransaction[]) => storage.setOnStorage(StorageKey.FRITransactionsHistory, data).subscribe(),
            error: (_error) => logger.consoleError('ApplicationGuard', 'FRITransactionsHistory', _error),
          });
        }
      },
    });
  };

  const saveFRITransactionsPending = (): void => {
    storage.getFromStorage(StorageKey.FRITransactionsPending).subscribe({
      next: (transactions: ITransactionPending[]) => {
        if (transactions) {
          const transactionSaveRequests = transactions.map((t) =>
            api.wallet.saveTransaction(t.myTransaction, t.senderData),
          );

          transactionSaveRequests?.map((t) =>
            t.subscribe({
              next: ({ transactionSign }: { transactionSign: string }) => {
                const index = transactions?.findIndex(
                  ({ myTransaction }: ITransactionPending) => myTransaction.SIGN === transactionSign,
                );
                transactions.splice(index, 1);
                storage.setOnStorage(StorageKey.FRITransactionsPending, transactions).subscribe();
              },
              error: (error: ProgressEvent | { transactionSign: string }) => {
                if (error instanceof ProgressEvent) {
                  logger.consoleError('ApplicationGuard', 'FRITransactionsPending', { error });
                } else {
                  const { transactionSign } = error;

                  const index = transactions?.findIndex(
                    ({ myTransaction }: ITransactionPending) => myTransaction.SIGN === transactionSign,
                  );
                  transactions.splice(index, 1);
                  storage.setOnStorage(StorageKey.FRITransactionsPending, transactions).subscribe();
                }
              },
            }),
          );
        }
      },
      error: (_error) => {
        if (_error === null && sessionService.IS_LOGGED) {
          storage.setOnStorage(StorageKey.FRITransactionsPending, []).subscribe();
        }
      },
    });
  };

  const getWalletData = (): void => {
    if (sessionService.TOKEN !== undefined) {
      getFRITokenAvailability();
    }

    getFRITransactionsHistory();
    saveFRITransactionsPending();
  };

  return new Promise((resolve) => {
    const { url } = state;
    logger.consoleLog('ApplicationGuard', 'url', url);

    keysService
      .get()
      .then(() => {
        logger.consoleLog('ApplicationGuard', 'keysService get', keysService.publicKey);

        sessionService.reLog().subscribe({
          next: (isLogged: boolean) => {
            logger.consoleLog('ApplicationGuard', 'reLog', isLogged);
            sessionService.IS_LOGGED = isLogged;

            if (sessionService.IS_LOGGED && authentication.loggedUser) {
              sessionService.TOKEN = authentication.loggedUser.token;
            }

            getWalletData();

            resolve(true);
          },
          error: async (error: Error) => {
            logger.consoleError('ApplicationGuard', 'reLog', error);

            if (error !== null && error?.name === 'NEED_REDO_LOGIN') {
              sessionService.IS_LOGGED = false;
              sessionService.TOKEN = null;

              if (!reLogAlreadyFired) {
                reLogAlreadyFired = true;

                const alert = await alertController.create({
                  header: translate.instant('COMMON.CAUTION'),
                  message: translate.instant('NEWS.ERROR_LOG_AGAIN'),
                  buttons: [
                    {
                      text: translate.instant('COMMON.CANCEL'),
                      role: 'cancel',
                    },
                    {
                      text: translate.instant('COMMON.CONTINUE_WITH_LOGIN'),
                      role: 'ok',
                    },
                  ],
                });
                await alert.present();

                const { role } = await alert.onDidDismiss();
                reLogAlreadyFired = false;

                if (role === 'ok') {
                  void router.navigateByUrl('/login-rinascimento');
                }

                resolve(true);
              }
            } else {
              storage.getFromStorage(StorageKey.LoggedUser).subscribe({
                next: (loggedUser: ILoggedUser) => {
                  sessionService.IS_LOGGED = true;
                  sessionService.TOKEN = loggedUser.token;

                  getWalletData();

                  resolve(true);
                },
                error: (getFromStorageError) => {
                  logger.consoleError('ApplicationGuard', 'loggedUser', getFromStorageError);

                  sessionService.IS_LOGGED = false;
                  sessionService.TOKEN = null;

                  logger.consoleLog('ApplicationGuard', 'loggedUser', PRIVATE_URLS.includes(url));
                  if (PRIVATE_URLS.includes(url)) {
                    void router.navigateByUrl('/home');
                  }

                  resolve(true);
                },
              });
            }
          },
        });
      })
      .catch((error) => {
        logger.consoleError('ApplicationGuard', 'keysService get', error);

        sessionService.IS_LOGGED = false;
        sessionService.TOKEN = null;

        if (PRIVATE_URLS.includes(url)) {
          void router.navigateByUrl('/home');
        }

        resolve(true);
      });
  });
};
