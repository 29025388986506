<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="1" class="ion-justify-content-center ion-align-items-center ion-no-padding">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()" [disabled]="isLoadingUsers"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="9" class="ion-justify-content-center ion-align-items-center ion-no-padding"> </ion-col>

      <ion-col size="2" class="ion-justify-content-end ion-align-items-center ion-no-padding">
        @if (internetAvailable) {
          @if (isLoadingUsers) {
            <ion-spinner name="lines-small"></ion-spinner>
          } @else if (availableUsers?.length > 0) {
            <ion-button
              size="small"
              fill="clear"
              color="light"
              (click)="saveChanges()"
              [disabled]="!internetAvailable || newUsers?.length === 0"
            >
              {{ 'TORGRAM.DONE' | translate }}
            </ion-button>
          }
        }
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <hr />

  @if (availableUsers?.length === 0) {
    <div class="ion-text-center noUser">
      <ion-label> {{ 'TORGRAM.NOUSERLIST' | translate }} </ion-label>
    </div>
  } @else {
    <ion-row>
      <ion-col class="ion-padding">
        <ion-list class="createRoomItems">
          <ion-item lines="none">
            <ion-select
              [label]="'TORGRAM.USERS' | translate"
              [multiple]="true"
              [(ngModel)]="newUsers"
              [disabled]="isLoadingUsers"
            >
              @for (user of availableUsers; track $index) {
                <ion-select-option [value]="user">
                  {{ user.pk.substring(0, 4) }} ... {{ user.pk.substring(user.pk.length - 4) }}
                </ion-select-option>
              }
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  }
</ion-content>
