import { Directive, ElementRef, Input, AfterViewInit, NgZone, EventEmitter, Output } from '@angular/core';
import { GestureController } from '@ionic/angular';

@Directive({
  selector: '[long-press]',
})
export class LongPressDirective implements AfterViewInit {
  @Output() tap = new EventEmitter();
  @Output() press = new EventEmitter();
  @Input() delay = 300;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any; // not stacking actions

  private positions = {
    start: {
      x: undefined as number,
      y: undefined as number,
    },
    current: {
      x: undefined as number,
      y: undefined as number,
    },
  };

  private longPressActive = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private gestureCtrl: GestureController,
    private zone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    this.loadLongPressOnElement();
  }

  loadLongPressOnElement(): void {
    const gesture = this.gestureCtrl.create({
      el: this.el.nativeElement,
      threshold: 0,
      gestureName: 'long-press',
      onStart: (ev) => {
        this.longPressActive = true;
        this.longPressAction();

        this.positions = {
          start: { x: ev.startX, y: ev.startY },
          current: { x: ev.currentX, y: ev.currentY },
        };
      },
      onMove: (ev) => {
        this.positions.current = { x: ev.currentX, y: ev.currentY };
      },
      onEnd: () => {
        this.longPressActive = false;
      },
    });
    gesture.enable(true);
  }

  private longPressAction() {
    if (this.action) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearInterval(this.action);
    }
    this.action = setTimeout(() => {
      this.zone.run(() => {
        // Check distance
        const xDistance = Math.abs(this.positions.start.x - this.positions.current.x);
        const yDistance = Math.abs(this.positions.start.y - this.positions.current.y);
        if (xDistance > 15 || yDistance > 15) {
          // User dragged finger
          return;
        }

        if (this.longPressActive === true) {
          this.longPressActive = false;
          this.press.emit({ target: this.el.nativeElement });
        } else {
          this.tap.emit();
        }
      });
    }, this.delay);
  }
}
