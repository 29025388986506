import { Component, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

// Services
import { StorageService } from './core/services/storage/storage.service';
import { CommonService } from './core/services/common/common.service';
import { OrientationService } from './core/services/orientation/orientation.service';
import { LoggerService } from './core/services/logger/logger.service';
import { PwaService } from './core/services/pwa/pwa.service';
import { VersionService } from './core/services/version/version.service';
import { ApiService } from './core/http/api.service';

// Components
import { PopoverMenuComponent } from './modules/login/components/popover-menu/popover-menu.component';

// Types
import { StorageKey } from './shared/types/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public storage: StorageService,
    public commonService: CommonService,
    private translate: TranslateService,
    private orientation: OrientationService,
    private router: Router,
    private zone: NgZone,
    private logger: LoggerService,
    public pwa: PwaService,
    private popoverController: PopoverController,
    private versionService: VersionService,
    private api: ApiService,
  ) {
    this.translate.setDefaultLang('en');
    this.translate.langs = ['it', 'en'];
    this.translate.use('en');

    this.storage.getFromStorage(StorageKey.ZoomMode).subscribe({
      next: (zoomMode: 'a' | 'A') => (this.commonService.zoomMode = zoomMode),
      error: (error) => {
        if (error === null) {
          void this.storage.setOnStorage(StorageKey.ZoomMode, 'A').subscribe();
          this.commonService.zoomMode = 'A';
        }
      },
    });

    this.initializeApp();
  }

  get isPwaAvailable(): boolean {
    return !this.pwa.isIncognito;
  }

  ngOnInit(): void {
    this.setupSafeAreas();
    this.lock('portrait-primary');

    window.matchMedia('(display-mode: standalone)').addListener((evt) => {
      if (evt.matches) {
        this.api.web.addAppTrackingRecord('appInstalled').subscribe();
      }
    });
  }

  async openSupportMenu(event: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: PopoverMenuComponent,
      event,
      componentProps: {
        VERSION: this.versionService.VERSION,
        VERSION_CODE: this.versionService.VERSION_CODE,
      },
    });
    return await popover.present();
  }

  private lock(orientation: OrientationType): void {
    // We can call setOrientation directly since pressing a button is a user interaction
    void this.orientation.setOrientation(orientation);
    this.orientation.lock(orientation);
  }

  private initializeApp(): void {
    this.logger.consoleLog('AppComponent', 'initializeApp');

    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const URL = event.url.replace(new RegExp('%3D', 'g'), '=').replace(new RegExp('%26', 'g'), '&');

        const urlForSplit = URL.split('&apn=')[0];
        const slug = urlForSplit.split('/#/')[1];

        this.logger.consoleLog('AppComponent', 'appUrlOpen', JSON.stringify({ urlForSplit, URL, slug }));

        if (slug) {
          void this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private setupSafeAreas(): void {
    const docStyle = document.documentElement.style;
    const ionSafeAreaBottom = docStyle.getPropertyValue('--ion-safe-area-bottom');
    if (ionSafeAreaBottom === '') {
      document.documentElement.style.setProperty('--safe-area-top', '16px');
      document.documentElement.style.setProperty('--safe-area-bottom', '16px');
    }
  }
}
