<ion-list style="margin: 1px">
  <ion-item (click)="openSupport()" lines="full">
    <ion-icon ios="help-outline" md="help-sharp" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap ion-text-bold"> {{ 'SETTINGS.HELP' | translate }} </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-label color="fri-dark" class="ion-text-wrap">
      {{ 'PWA.VERSION' | translate }} {{ componentsProps.VERSION }} ({{ componentsProps.VERSION_CODE }})
    </ion-label>
  </ion-item>
</ion-list>
