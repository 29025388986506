import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

// Services
import { CommonService } from '../../../core/services/common/common.service';
import { StorageService } from '../../../core/services/storage/storage.service';
import { VersionService } from '../../../core/services/version/version.service';
import { SessionService } from '../../../core/services/session/session.service';
import { ToastService } from '../../../core/services/toast/toast.service';
import { LoaderService } from '../../../core/services/loader/loader.service';
import { TranslationService } from '../../../core/services/translation/translation.service';
import { ApiService } from '../../../core/http/api.service';
import { NetworkService } from '../../../core/services/network/network.service';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';

// Types
import { IMenuVoice, IUtilities } from '../../types/menu';
import { StorageKey } from '../../types/storage';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  URLS_OBJ = environment.URLS;

  public functionalities: IMenuVoice[] = [
    {
      title: 'FRI',
      url: 'home',
      icon: 'newspaper',
      show: 'always',
      click: (): void => {},
      availableOffline: true,
    },
  ];

  public utilities: IUtilities = {
    collapsed: true,
    collapseIcon(): string {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return this.collapsed ? 'chevron-up-outline' : 'chevron-down-outline';
    },
    items: [
      {
        title: 'SETTINGS.MAIN',
        url: 'settings',
        icon: 'cog',
        show: 'always',
        availableOffline: true,
      },
      {
        title: 'KEYS.MAIN',
        url: 'keys',
        icon: 'key',
        show: 'always',
        availableOffline: true,
      },
      {
        title: 'FUNCTIONALITIES.MANUAL',
        icon: 'book',
        detail: false,
        availableOffline: false,
        click: (): Window => window.open(this.URLS_OBJ.MANUAL_URL, '_blank'),
        show: 'always',
      },
    ],
  };

  public footer: IMenuVoice[] = [
    {
      title: 'SETTINGS.HELP',
      icon: 'help',
      detail: false,
      click: (): Window => window.open('https://rinascimentoitalia.it/aaa/fri-faq/', '_blank'),
      show: 'always',
      needToShowVersion: true,
      availableOffline: true,
    },
    {
      title: 'FUNCTIONALITIES.LOGIN.MAIN',
      url: 'login-rinascimento',
      icon: 'log-in',
      show: 'not-logged',
      availableOffline: false,
      click: (): void => {},
    },
    {
      title: 'FUNCTIONALITIES.LOGOUT.MAIN',
      detail: false,
      click: async (): Promise<void> => await this.logout(),
      icon: 'log-out',
      show: 'logged',
      availableOffline: false,
    },
  ];

  constructor(
    private alertController: AlertController,
    private presentLoading: LoaderService,
    private presentToast: ToastService,
    private router: Router,
    private api: ApiService,
    private storage: StorageService,
    public commonService: CommonService,
    private translate: TranslationService,
    public versionService: VersionService,
    public sessionService: SessionService,
    private networkService: NetworkService,
    private auth: AuthenticationService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  async logout(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant('COMMON.CAUTION'),
      message: this.translate.instant('FUNCTIONALITIES.LOGOUT.MESSAGE'),
      buttons: [
        {
          text: this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
        },
        {
          text: this.translate.instant('COMMON.CONFIRM'),
          role: 'ok',
        },
      ],
      cssClass: 'proceed-cancel-alert',
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      void this.presentLoading.present(this.translate.instant('FUNCTIONALITIES.LOGOUT.LOGGING_OUT'));
      if (!this.auth.loggedUser || !this.auth.loggedUser.ID) {
        setTimeout(() => {
          this.storage.removeFromStorage(StorageKey.LoggedUser).subscribe();
          this.storage.removeFromStorage(StorageKey.FRITokenAvailability).subscribe();

          this.sessionService.IS_LOGGED = false;

          this.presentLoading.dismiss();
        }, 1250);
      } else {
        this.api.web.doLogout().subscribe({
          next: () => {
            this.storage.removeFromStorage(StorageKey.LoggedUser).subscribe();
            this.storage.removeFromStorage(StorageKey.FRITokenAvailability).subscribe();
            this.sessionService.IS_LOGGED = false;

            this.presentLoading.dismiss();

            void this.router.navigateByUrl('/home');
          },
          error: ({ error }: { error: string }) => {
            this.presentLoading.dismiss();

            if (error === null) {
              this.storage.removeFromStorage(StorageKey.LoggedUser).subscribe();
              this.storage.removeFromStorage(StorageKey.FRITokenAvailability).subscribe();

              this.sessionService.IS_LOGGED = false;
            } else {
              void this.presentToast.showErrorToast(this.translate.instant('COMMON.ERROR'), error);
            }
          },
        });
      }
    }
  }

  openTelegram(): void {
    window.open('https://t.me/rinascimentoitalia', '_blank');
  }
}
