import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Types
import { Room, RoomUsers } from '../../../../../../shared/types/tables';
import { PageNgOnInitCallBack } from '../../../../../../shared/types/common';

// Services
import { TranslationService } from '../../../../../../core/services/translation/translation.service';
import { SocketService } from '../../../../../../core/services/socket/socket.service';
import { ToastService } from '../../../../../../core/services/toast/toast.service';
import { ModalService } from '../../../../../../core/services/modal/modal.service';
import { NetworkService } from '../../../../../../core/services/network/network.service';
import { SocketEvents } from '../../../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../../../core/services/logger/logger.service';

@Component({
  selector: 'app-torgram-room-add-users',
  templateUrl: './torgram-room-add-users.page.html',
  styleUrls: ['./torgram-room-add-users.page.scss'],
})
export class TorgramRoomAddUsersPage implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() room: Room;
  @Input() availableUsers: RoomUsers[];

  public isLoadingUsers: boolean = false;
  public newUsers: RoomUsers[];

  private roomAddUsersSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslationService,
    private socketService: SocketService,
    private presentToast: ToastService,
    private modalService: ModalService,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnInit(): void {
    this.networkService.pageNgOnInit(
      'TorgramRoomAddUsersPage',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramRoomAddUsersComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.roomAddUsersSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  async saveChanges(): Promise<void> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('TORGRAM.CONFIRMADDUSERS'),
      buttons: [
        {
          text: this.translate.instant('TORGRAM.YES'),
          role: 'confirm',
          handler: () => {
            this.addUsers(this.newUsers.map((user) => user.pk));
          },
        },
        {
          text: this.translate.instant('TORGRAM.NO'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
    await actionSheet.onWillDismiss();
  }

  async dismissModal(role?: string): Promise<void> {
    await this.modal.dismiss(role);
    await this.modalService.dismissLast();
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramRoomAddUsersComponent');
    }

    this.roomAddUsersSubscription = this.socket.fromEvent(SocketEvents.ROOM_ADD_USERS).subscribe({
      next: async () => {
        this.socket.emit(SocketEvents.ROOM_AVAILABLE_USERS, { roomId: this.room?.id ?? undefined });
        await this.dismissModal('success');
      },
    });
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramRoomAddUsersComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }

  private addUsers(newUsers: RoomUsers['pk'][]): void {
    this.isLoadingUsers = true;

    if (this.socketService.env === 'tor') {
      void this.presentToast.showOperationToast('', this.translate.instant('TORGRAM.ADDING_USERS_TOR_ROOM'));
    }

    this.socket.emit(SocketEvents.ROOM_ADD_USERS, { newUsers, roomId: this.room.id });
  }
}
