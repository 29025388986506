<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="3" class="ion-justify-content-flex-start ion-align-items-center">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="6" class="ion-justify-content-center ion-align-items-center"> </ion-col>

      <ion-col size="3" class="ion-justify-content-flex-start"> </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  @if (isLoadingUsers || memberList === undefined) {
    <div class="ion-full-height ion-align-items-center ion-justify-content-center">
      <ion-spinner class="loadingSpinner" color="fri-dark" name="dots"></ion-spinner>
    </div>
  } @else {
    @if (memberList?.length === 0) {
      <div class="ion-text-center noUser">
        <ion-label> {{ 'TORGRAM.NOUSERLIST' | translate }} </ion-label>
      </div>
    } @else {
      <ion-list lines="full">
        @if (personLabel !== '' && role !== 'owner' && canEnterVisible) {
          <ion-item class="border-radius personAdd" (click)="openPromotionModal()" [disabled]="!internetAvailable">
            <ion-icon name="person-add-outline" size="small" class="ion-margin-end"></ion-icon>

            <ion-label>{{ personLabel }}</ion-label>
            <not-available-offline></not-available-offline>
          </ion-item>
        }
        @if (role === 'reader' && canEnterVisible) {
          <ion-item class="border-radius personAdd" (click)="shareLink()" [disabled]="!internetAvailable">
            <ion-icon name="link-outline" size="small" class="ion-margin-end"></ion-icon>

            <ion-label>{{ 'TORGRAM.INVITEBYLINK' | translate }}</ion-label>
            <not-available-offline></not-available-offline>
          </ion-item>
        }
      </ion-list>
      <br />

      <ion-label class="ion-text-uppercase ion-text-bold" translate> {{ label }} </ion-label>

      <div class="memberDiv ion-margin-top">
        <ion-list class="border-radius" lines="full">
          @for (member of memberList; track $index) {
            @if (role === 'pending') {
              @if (room?.members[0].owner || room?.members[0].moderator) {
                <ion-item [disabled]="!internetAvailable">
                  <ion-label>
                    {{ member.user.pk.substring(0, 4) }} ...
                    {{ member.user.pk.substring(member.user.pk.length - 4) }}
                  </ion-label>

                  @if (internetAvailable) {
                    @if (isLoadingRequest) {
                      <ion-spinner slot="end" color="fri-dark" name="dots"></ion-spinner>
                    } @else {
                      <ion-button
                        size="small"
                        color="success"
                        slot="end"
                        (click)="canEnter('approved', member.user.id)"
                      >
                        <ion-icon name="checkmark-sharp" size="small"></ion-icon>
                      </ion-button>

                      <ion-button size="small" color="danger" slot="end" (click)="canEnter('rejected', member.user.id)">
                        <ion-icon name="close-sharp" size="small"></ion-icon>
                      </ion-button>
                    }
                  }
                  <not-available-offline></not-available-offline>
                </ion-item>
              }
            } @else if (role === 'unsubscribed') {
              <ion-item [disabled]="!internetAvailable">
                <ion-label>
                  {{ member.user.pk.substring(0, 4) }} ...
                  {{ member.user.pk.substring(member.user.pk.length - 4) }}
                </ion-label>

                @if (internetAvailable) {
                  @if (isLoadingUnban) {
                    <ion-spinner slot="end" color="fri-dark" name="dots"></ion-spinner>
                  } @else {
                    <ion-button size="small" color="danger" slot="end" (click)="unbanUser(member.user.id)">
                      <ion-icon name="trash-outline" size="small"></ion-icon>
                    </ion-button>
                  }
                }
              </ion-item>
            } @else {
              @if (member.status !== 'pending') {
                <ion-item [disabled]="!internetAvailable">
                  <ion-label>
                    {{ member.user.pk.substring(0, 4) }} ...
                    {{ member.user.pk.substring(member.user.pk.length - 4) }}
                  </ion-label>

                  @if (member.user.pk === room?.members[0].user.pk) {
                    <ion-label class="you ion-text-right" slot="end">
                      {{ 'TORGRAM.YOU' | translate }}
                    </ion-label>
                  }
                  @if (internetAvailable) {
                    @if (canEnterVisible) {
                      @if (member.user.pk !== room?.members[0].user.pk) {
                        @if (isLoadingBanned) {
                          <ion-spinner slot="end" color="fri-dark" name="dots"></ion-spinner>
                        } @else {
                          <ion-button size="small" color="danger" slot="end" (click)="demoteUser(member)">
                            <ion-icon name="trash-outline" size="small"></ion-icon>
                          </ion-button>
                        }
                      }
                    }
                  }
                </ion-item>
              }
            }
          }
        </ion-list>
      </div>
    }
  }
</ion-content>
