import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit {
  componentsProps: { [key: string]: unknown };

  constructor(private navParams: NavParams) {}

  ngOnInit(): void {
    this.componentsProps = this.navParams.data;
  }

  openSupport(): Window {
    return window.open('https://rinascimentoitalia.it/aaa/fri-faq/', '_blank');
  }
}
