/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import detectIncognito from 'detectincognitojs';
import Bowser from 'bowser';

// Environment
import { environment } from '../../../../environments/environment';

// Services
import { LoggerService } from '../logger/logger.service';
import { ToastService } from '../toast/toast.service';
import { TranslationService } from '../translation/translation.service';

@Injectable({ providedIn: 'root' })
export class PwaService {
  public isIncognito = true;
  public isInstalled: boolean;
  public checkIfBrowserCompatible: boolean;
  public browserToUse: string;

  private isIpad: boolean;
  private browserName: string;

  constructor(
    private logger: LoggerService,
    private platform: Platform,
    private toast: ToastService,
    private translate: TranslationService,
  ) {}

  // Detects if device is on iOS
  get isIos(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isIpad = /ipad/.test(userAgent);

    return /iphone|ipad|ipod/.test(userAgent);
  }

  get modeName(): 'PWA.PRIVATE_WINDOW' | 'PWA.INCOGNITO_WINDOW' | 'PWA.INPRIVATE_WINDOW' {
    if (this.browserName !== undefined) {
      switch (this.browserName) {
        case 'Safari':
        case 'Firefox':
        case 'Brave':
        case 'Opera':
          return 'PWA.PRIVATE_WINDOW';
        case 'Chrome':
        case 'Chromium':
          return 'PWA.INCOGNITO_WINDOW';
        case 'Internet Explorer':
        case 'Edge':
          return 'PWA.INPRIVATE_WINDOW';
        default:
          this.logger.consoleError('PwaService', 'modeName', {
            error: 'Could not get mode name',
            browserName: this.browserName,
          });
          break;
      }
    }

    return;
  }

  checkPwa(): void {
    if (environment.production) {
      this.getCurrentBrowser();
      this.browserToUse = this.getBrowserToUse();
      this.checkIfBrowserCompatible = this.getCheckIfBrowserCompatible();
      this.isInstalled = this.getIsInstalled();

      this.logger.consoleLog('PwaService', 'checkPwa', {
        checkIfBrowserCompatible: this.checkIfBrowserCompatible,
        browserName: this.browserName,
        browserToUse: this.browserToUse,
        isInstalled: this.isInstalled,
      });

      if (!this.checkIfBrowserCompatible || !this.isInstalled) {
        // TODO: redirect to Login

        if (this.checkIfBrowserCompatible && !this.isInstalled) {
          this.showInstallMessage();
        }
      }
    } else {
      this.checkIfBrowserCompatible = true;
      this.isInstalled = true;
      this.isIncognito = false;
    }
  }

  async checkIfRunningInIncognito(): Promise<void> {
    this.logger.consoleLog('PwaService', 'checkIfRunningInIncognito');
    return detectIncognito()
      .then((result) => {
        const { isPrivate } = result;
        this.logger.consoleLog('PwaService', 'checkIfRunningInIncognito', { isPrivate });
        this.isIncognito = result.isPrivate;
      })
      .catch((error) => {
        if (error.error === 'Could not get mode name') {
          this.isIncognito = false;
        }

        this.logger.consoleError('PwaService', 'checkIfRunningInIncognito', { error });
      });
  }

  private showInstallMessage(): void {
    let message = '';

    if (this.isIos) {
      message = `${this.translate.instant('PWA.IOS_PART_1')} <ion-icon name="share-outline" mode="ios" class="iconForToast" color="primary"></ion-icon> ${this.translate.instant('PWA.IOS_PART_2')}`;
    } else {
      message = `${this.translate.instant('PWA.MD_PART_1')} <ion-icon name="ellipsis-vertical-sharp" mode="md" class="iconForToast" color="primary"></ion-icon> ${this.translate.instant('PWA.MD_PART_2')}`;
    }

    void this.toast.showDismissableToast(message, this.isIpad ? 'top' : 'bottom');
  }

  private getCheckIfBrowserCompatible(): boolean {
    return this.browserName.toLowerCase() === this.browserToUse.toLowerCase();
  }

  private getBrowserToUse(): string {
    let browser = '';
    if (this.platform.is('ios')) {
      browser = 'Safari';
    } else if (this.platform.is('android')) {
      browser = 'Chrome';
    }
    return browser;
  }

  private getIsInstalled(): boolean {
    return this.platform.is('pwa');
  }

  private getCurrentBrowser(): void {
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.browserName = browser.getBrowserName();
    if (this.browserName === 'Chrome') {
      const ua = navigator.userAgent;
      if (/Chrome/.exec(ua)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((navigator as any).brave !== undefined) {
          this.browserName = 'Brave';
        } else if (/Edg/.exec(ua)) {
          this.browserName = 'Edge';
        } else if (/OPR/.exec(ua)) {
          this.browserName = 'Opera';
        } else {
          this.browserName = 'Chrome';
        }
      }
    }
  }
}
