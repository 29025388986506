import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';

// Types
import { Attachment, UploadParams, UploadParamsDefinition } from '../../../shared/types/socket';

// Services
import { ToastService } from '../../services/toast/toast.service';
import { TranslationService } from '../../services/translation/translation.service';
import { LoggerService } from '../../services/logger/logger.service';

@Injectable({ providedIn: 'root' })
export class AttachmentUtils {
  public attachments: Attachment[] = [];

  private readonly VIDEO_DEFAULT_EXTENSION: string = 'mp4';
  private readonly IMAGE_DEFAULT_EXTENSION: string = 'png';
  private readonly UNMANAGED_VIDEO_EXTENSIONS: string[] = ['mov', 'quicktime'];
  private readonly UNMANAGED_IMAGE_EXTENSIONS: string[] = ['jpeg', 'jpg'];

  constructor(
    private toast: ToastService,
    private translate: TranslationService,
    private logger: LoggerService,
  ) {}

  add(
    paramType: string,
    uploadParams: UploadParams,
    attachmentName: string = 'attachment',
  ): Promise<Record<string, unknown>> {
    this.attachments = [];

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let uploadParam: UploadParamsDefinition;
      if (uploadParams !== undefined && uploadParams[paramType] !== undefined) {
        uploadParam = uploadParams[paramType];
      }

      if (uploadParam) {
        if (paramType === 'camera' || paramType === 'gallery') {
          const cameraOptions: ImageOptions = {
            allowEditing: false,
            height: 1024,
            width: 1024,
            quality: 100,
            resultType: CameraResultType.Base64,
            source: paramType === 'camera' ? CameraSource.Camera : CameraSource.Photos,
            webUseInput: false,
            correctOrientation: true,
          };

          Camera.getPhoto(cameraOptions)
            .then(async (image) => {
              image.format = image.format.toLowerCase();

              const dataURLtoFile = (dataurl: string, filename: string) => {
                const arr = dataurl.split(',');
                const bstr = atob(arr[arr.length - 1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);

                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {
                  type: (image.format.includes(this.VIDEO_DEFAULT_EXTENSION) ? 'video/' : 'image/') + image.format,
                });
              };

              const newMimeType = this.getUpdatedExtension(image.format);
              if (newMimeType) {
                image.format = newMimeType;
              }

              const file = dataURLtoFile(image.base64String, attachmentName + '-' + Date.now() + '.' + image.format);

              if (uploadParam.dimension >= file.size / 1024 / 1024) {
                const blob = await this.getImageOrVideoBlob(file);

                const _image = new Image();
                _image.onload = () => {
                  const fileListCamera = {
                    name: attachmentName + '-' + Date.now() + '.' + image.format,
                    size: file.size,
                    type: file.type,
                    file,
                    width: _image.width,
                    height: _image.height,
                  } as Partial<FileList>;

                  this.attachments.push(fileListCamera as unknown as Attachment);

                  this.logger.consoleLog('AttachmentUtils', 'Camera.getPhoto', image);

                  resolve({
                    imagePreview: blob,
                    type: file.type,
                    path: 'data:' + file.type + ';base64,' + image.base64String,
                  });
                };
                _image.src = blob;
              } else {
                void this.toast.showWarningToast(
                  this.translate.instant('COMMON.CAUTION'),
                  this.translate.instant('TORGRAM.PARAM_ERROR', { size: uploadParam.dimension, uom: uploadParam.uom }),
                  'bottom',
                  3000,
                );

                reject({ error: 'paramError' });
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else if (paramType === 'video') {
          await FilePicker.pickVideos({
            limit: 1,
            readData: true,
          })
            .then((result) => {
              const file = result.files[0];

              file.name = file.name.toLowerCase();
              file.mimeType = file.mimeType.toLowerCase();

              this.logger.consoleLog('AttachmentUtils', 'FilePicker.pickVideos', file);

              const newMimeType = this.getUpdatedExtension(file.mimeType);
              if (newMimeType) {
                file.mimeType = newMimeType;
              }

              const rawFile = new File([file.blob], file.name, {
                type: file.mimeType,
              });
              this.logger.consoleLog('AttachmentUtils', 'FilePicker.pickVideos', rawFile);

              if (uploadParam.dimension >= file.size / 1024 / 1024) {
                this.attachments.push({
                  name: rawFile.name,
                  size: rawFile.size,
                  type: rawFile.type,
                  file: rawFile,
                  width: file.width,
                  height: file.height,
                });

                if (rawFile.type.includes('video')) {
                  resolve({
                    videoPreview: file.blob,
                    type: file.mimeType,
                    path: 'data:' + file.mimeType + ';base64,' + file.data,
                  });
                } else {
                  resolve({ canSendAttachment: true });
                }
              } else {
                void this.toast.showWarningToast(
                  this.translate.instant('COMMON.CAUTION'),
                  this.translate.instant('TORGRAM.PARAM_ERROR', { size: uploadParam.dimension, uom: uploadParam.uom }),
                  'bottom',
                  3000,
                );

                reject({ error: 'paramError' });
              }
            })
            .catch((error) => {
              this.logger.consoleError('AttachmentUtils', 'FilePicker.pickVideos', error);

              reject(error);
            });
        } else {
          // FILE
          this.logger.consoleLog('AttachmentUtils', 'FilePicker.pickFiles', uploadParam);

          await FilePicker.pickFiles({
            types: uploadParam.accept.split(', '),
            readData: true,
          })
            .then((result) => {
              const file = result.files[0];

              file.mimeType = file.mimeType.toLowerCase();
              file.name = file.name.toLowerCase();

              const newMimeType = this.getUpdatedExtension(file.mimeType);
              if (newMimeType) {
                file.mimeType = newMimeType;
              }

              if (uploadParam.accept.includes(file.mimeType)) {
                const rawFile = new File([file.blob], file.name, {
                  type: file.mimeType,
                });
                this.logger.consoleLog('AttachmentUtils', 'FilePicker.pickFiles', rawFile);

                if (uploadParam.dimension >= file.size / 1024 / 1024) {
                  this.attachments.push({
                    name: rawFile.name,
                    size: rawFile.size,
                    type: rawFile.type,
                    file: rawFile,
                    width: file.width,
                    height: file.height,
                  });

                  resolve({
                    canSendAttachment: true,
                    type: file.mimeType,
                    path: 'data:' + file.mimeType + ';base64,' + file.data,
                  });
                } else {
                  void this.toast.showWarningToast(
                    this.translate.instant('COMMON.CAUTION'),
                    this.translate.instant('TORGRAM.PARAM_ERROR', {
                      size: uploadParam.dimension,
                      uom: uploadParam.uom,
                    }),
                    'bottom',
                    3000,
                  );

                  reject({ error: 'paramError' });
                }
              } else {
                void this.toast.showWarningToast(
                  this.translate.instant('COMMON.CAUTION'),
                  this.translate.instant('TORGRAM.PARAM_ERROR_2', { types: uploadParam.accept }),
                  'bottom',
                  3000,
                );

                reject({ error: 'paramError' });
              }
            })
            .catch((error) => {
              this.logger.consoleError('AttachmentUtils', 'FilePicker.pickFiles', error);

              reject(error);
            });
        }
      } else {
        reject({ error: 'missingUploadParam' });
      }
    });
  }

  private async getImageOrVideoBlob(file: File): Promise<string> {
    const blob = new Blob([await file.arrayBuffer()], { type: file.type });
    return URL.createObjectURL(blob);
  }

  private getUpdatedExtension(mimeType: string): string | undefined {
    const videoExtensionToReplace = this.UNMANAGED_VIDEO_EXTENSIONS.find((unusedformat) =>
      mimeType.includes(unusedformat),
    );

    if (videoExtensionToReplace) {
      return mimeType.replace(videoExtensionToReplace, this.VIDEO_DEFAULT_EXTENSION);
    }

    const imageExtensionToReplace = this.UNMANAGED_IMAGE_EXTENSIONS.find((unusedformat) =>
      mimeType.includes(unusedformat),
    );

    if (imageExtensionToReplace) {
      return mimeType.replace(imageExtensionToReplace, this.IMAGE_DEFAULT_EXTENSION);
    }

    return undefined;
  }
}
