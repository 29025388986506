import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

// Custom Modules
import { CoreModule } from '../../core/core.module';

// Modals
import { CustomToastPageModule } from './custom-toast/custom-toast.module';
import { GatekeeperKeyPageModule } from './gatekeeper/gatekeeper.module';
import { ModalAttachmentsPage } from './modal-attachments/modal-attachments.page';
import { OrientationPageModule } from './orientation/orientation.module';

const MODALS = [CustomToastPageModule, GatekeeperKeyPageModule, OrientationPageModule];

@NgModule({
  declarations: [ModalAttachmentsPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    SwiperModule,

    // Custom Modules
    CoreModule,
    ...MODALS,
  ],
  exports: [],
})
export class ModalsModule {}
