import { Component, Input, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';

// Types
import { RoomCreateInviteLinkParams } from '../../../../shared/types/socket';
import { Room } from '../../../../shared/types/tables';

// Services
import { ModalService } from '../../../../core/services/modal/modal.service';
import { NetworkService } from '../../../../core/services/network/network.service';

@Component({
  selector: 'app-torgram-custom-link',
  templateUrl: './torgram-custom-link.component.html',
  styleUrls: ['./torgram-custom-link.component.scss'],
})
export class TorgramCustomLinkComponent implements OnInit {
  @Input() modal!: IonModal;
  @Input() room: Room;

  public linkName: string;
  public expirationDate: Date;
  public today: string;
  public userLimit: number;
  public requireApproval: boolean;

  private newLinkRequest: RoomCreateInviteLinkParams;

  constructor(
    private modalService: ModalService,
    private networkService: NetworkService,
  ) {}

  get createNotDisabled(): boolean {
    return this.linkName === undefined || this.linkName.trim().length === 0;
  }

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  ngOnInit(): void {
    this.today = new Date().toISOString();
  }

  async createNewLink(): Promise<void> {
    this.newLinkRequest = {
      name: this.linkName,
      roomId: this.room.id,
      requireApproval: this.requireApproval,
      expirationDate: this.expirationDate,
      userLimit: this.userLimit,
    };

    await this.dismissModal(this.newLinkRequest);
  }

  async dismissModal(request?: RoomCreateInviteLinkParams): Promise<void> {
    await this.modal.dismiss(request);
    await this.modalService.dismissLast();
  }
}
