<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="3" class="ion-justify-content-flex-start ion-align-items-center">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="6" class="ion-justify-content-center ion-align-items-center"> </ion-col>

      <ion-col size="3" class="ion-justify-content-flex-start"> </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="wrapper">
    <ion-label class="ion-text-uppercase ion-padding-horizontal ion-margin-top">
      {{ 'TORGRAM.INVITATIONLINK' | translate }}
    </ion-label>

    <div class="ion-align-items-center ion-padding joinLink ion-margin-top">
      @if (isLoadingLink) {
        <ion-row class="ion-full-width">
          <ion-col class="ion-align-items-center">
            <ion-item class="link" lines="none">
              <ion-row class="ion-full-width">
                <ion-col size="12" class="ion-align-items-center ion-justify-content-center">
                  <ion-spinner color="fri-dark" name="dots"></ion-spinner>
                </ion-col>
              </ion-row>
            </ion-item>
          </ion-col>
        </ion-row>
      } @else {
        @for (link of inviteLinks; track $index) {
          <ion-row class="ion-full-width">
            <ion-col class="ion-align-items-center">
              <ion-item class="link" lines="none">
                <ion-row class="ion-full-width">
                  <ion-col size="10" class="ion-align-items-center ion-justify-content-center">
                    <ion-label (click)="copyCustomLink(link)" class="ion-text-bold">{{ link?.linkId }}</ion-label>
                  </ion-col>

                  <ion-col size="2" class="ion-align-items-center ion-justify-content-center">
                    <ion-button
                      fill="clear"
                      (click)="presentLinkOptions($event, $index)"
                      [disabled]="!internetAvailable"
                    >
                      <ion-icon size="large" name="ellipsis-horizontal-circle-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-text-center">
              <ion-button class="shareLink" (click)="shareLink(link)">
                <ion-label class="ion-text-bold"> {{ 'TORGRAM.SHARELINK' | translate }} </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        }
      }
    </div>
  </div>

  <br />
  <br />

  <ion-label class="ion-text-uppercase ion-margin-top">{{ 'TORGRAM.CUSTOMISABLELINKS' | translate }}</ion-label>

  <ion-button
    class="ion-margin-top ion-align-items-center createNewLink"
    (click)="openCustomLinkModal()"
    [disabled]="!internetAvailable || isLoadingCustomLinks"
  >
    @if (isLoadingCustomLinks) {
      <ion-label>{{ 'TORGRAM.CREATINGNEWLINK' | translate }}</ion-label>
      <ion-spinner name="lines-small"></ion-spinner>
    } @else {
      <ion-icon name="add-outline" class="ion-margin-end"></ion-icon>
      <ion-label>{{ 'TORGRAM.CREATENEWLINK' | translate }}</ion-label>
    }
  </ion-button>

  <hr />

  <ion-list class="ion-margin-top border-radius" lines="full">
    @for (link of inviteCustomLinks; track $index) {
      <ion-item detail="false" class="itemList" [disabled]="!internetAvailable || isLoadingCustomLinks">
        <ion-label (click)="copyCustomLink(link)" slot="start" class="ion-text-wrap linkName">
          {{ link?.name }}
        </ion-label>

        @if (isLoadingRevokeCustomLink) {
          <ion-spinner slot="end" name="lines-small"></ion-spinner>
        } @else {
          <ion-button fill="outline" size="small" color="fri-dark" slot="end" (click)="copyCustomLink(link)">
            <ion-icon name="copy" size="small"></ion-icon>
          </ion-button>

          <ion-button
            size="small"
            color="danger"
            slot="end"
            (click)="revokeCustomLink(link)"
            [disabled]="!internetAvailable"
          >
            <ion-icon name="trash-outline" size="small"></ion-icon>
          </ion-button>
        }

        <not-available-offline> </not-available-offline>
      </ion-item>
    }
  </ion-list>
</ion-content>
