/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

// Environment
import { environment } from '../../../../environments/environment';

// Services
import { LoggerService } from '../logger/logger.service';

// Types
import { IValidateToken } from '../../../shared/types/webservices';

@Injectable({ providedIn: 'root' })
export class WebServiceCall {
  private WEBSERVICES_MODE = environment.WEBSERVICES_MODE as 'ONLINE' | 'LOCAL';

  private WEB_SERVICE_URL = environment.WEBSERVICES[this.WEBSERVICES_MODE].WEB_SERVICE_URL;
  private VALIDATE_TOKEN = environment.WEBSERVICES[this.WEBSERVICES_MODE].VALIDATE_TOKEN;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
  ) {}

  getCall(url: string, options: Record<string, unknown> = {}, token: string = null): Observable<any> {
    if (!token || token?.length > 0 || token === '') {
      return this.http.get(url, options).pipe(catchError(this.errorHandle.bind(this)));
    } else {
      return this.validateToken(token);
    }
  }

  getCallBuffer(url: string, token: string = null): Observable<any> {
    if (!token || token?.length > 0 || token === '') {
      return this.http.get(url, { responseType: 'arraybuffer' }).pipe(catchError(this.errorHandle.bind(this)));
    } else {
      return this.validateToken(token);
    }
  }

  postCallBuffer(url: string, body: Record<string, unknown>, token: string = null): Observable<any> {
    if (!token || token?.length > 0 || token === '') {
      return this.http.post(url, body, { responseType: 'arraybuffer' }).pipe(catchError(this.errorHandle.bind(this)));
    } else {
      return this.validateToken(token);
    }
  }

  putCall(resource: string, body: Record<string, unknown>): Observable<any> {
    const url = this.WEB_SERVICE_URL + resource;

    return this.http.put(url, body).pipe(catchError(this.errorHandle.bind(this)));
  }

  postCall(resource: string, body: Record<string, unknown>): Observable<any> {
    const url = this.WEB_SERVICE_URL + resource;

    return this.http.post(url, body).pipe(catchError(this.errorHandle.bind(this)));
  }

  postCallExternal(url: string, body: Record<string, unknown>): Observable<any> {
    return this.http.post(url, body).pipe(catchError(this.errorHandle.bind(this)));
  }

  putCallExternal(url: string, body: Record<string, unknown>): Observable<any> {
    return this.http.put(url, body).pipe(catchError(this.errorHandle.bind(this)));
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  validateToken(token: string): Observable<boolean | IValidateToken | any> {
    return new Observable((observer) => {
      this.http
        .post(this.VALIDATE_TOKEN, {}, { headers: new HttpHeaders({ Authorization: 'Bearer ' + token }) })
        .subscribe({
          next: (res: IValidateToken) => {
            if (res.statusCode === 200) {
              observer.next(true);
            } else {
              observer.error(res);
            }
          },
          error: (error) => {
            this.logger.consoleError('validateToken', error);
            observer.error(error);
          },
        });
    });
  }

  // Error handling
  errorHandle({ error }: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.logger.consoleError('WebServiceCall', 'errorHandle', error);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return throwError(() => error);
  }
}
