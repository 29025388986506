import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Services
import { ModalService } from '../../../../core/services/modal/modal.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { CommonService } from '../../../../core/services/common/common.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { LoggerService } from '../../../../core/services/logger/logger.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';

// Types
import { Room } from '../../../../shared/types/tables';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

@Component({
  selector: 'app-torgram-join-room',
  templateUrl: './torgram-join-room.component.html',
  styleUrls: ['./torgram-join-room.component.scss'],
})
export class TorgramJoinRoomComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;

  public linkId: string = '';
  public isLoadingJoining: boolean = false;

  private joinLinkSubscription: Subscription;
  private errorSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private socketService: SocketService,
    private modalService: ModalService,
    private common: CommonService,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  get socketErrorMessage(): string {
    return this.socketService.socketErrorMessage;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.joinLinkSubscription?.unsubscribe();
    this.errorSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.networkService.pageNgOnInit(
      'TorgramJoinRoomComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramJoinRoomComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  async dismissModal(role?: string): Promise<void> {
    await this.modal.dismiss(role);
    await this.modalService.dismissLast();
  }

  joinRoom(): Promise<void> {
    this.isLoadingJoining = true;
    if (!this.linkId) {
      this.isLoadingJoining = false;
      return;
    }

    this.linkId = this.linkId.trim();
    this.socket.emit(SocketEvents.ROOM_JOIN_FROM_INVITE_LINK, { linkId: this.linkId });
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramJoinRoomComponent');
    }

    this.joinLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_JOIN_FROM_INVITE_LINK).subscribe({
      next: async ({ room, alreadyJoined }: { room: Room; alreadyJoined: boolean }) => {
        this.logger.consoleLog('TorgramJoinRoomComponent', 'ROOM_JOIN_FROM_INVITE_LINK', {
          room,
          alreadyJoined,
          path: alreadyJoined ? room.roomType.description + '/' + room.id : '',
        });

        if (alreadyJoined) {
          await this.common.torgramNavigationWithBaseHref(room.roomType.description + '/' + room.id);
        } else {
          await this.common.torgramNavigationWithBaseHref();
        }
        await this.dismissModal();

        this.isLoadingJoining = false;
      },
    });

    this.errorSubscription = this.socket.fromEvent(SocketEvents.ERROR).subscribe({
      next: () => {
        this.isLoadingJoining = false;
        this.linkId = undefined;
      },
    });
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramJoinRoomComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }
}
