import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Room, RoomType } from '../../types/tables';

@Pipe({ name: 'equal' })
export class EqualPipe implements PipeTransform {
  transform(items: Room[], { id }: RoomType): any {
    if (id && Array.isArray(items)) {
      return items.filter((item) => {
        return item.type === id;
      });
    } else {
      return items;
    }
  }
}
