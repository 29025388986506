import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal, PopoverController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Components
import { TorgramLinkSettingsComponent } from '../torgram-link-settings/torgram-link-settings.component';

// Types
import { Room, RoomInviteLink } from '../../../../shared/types/tables';
import { RoomCreateInviteLinkParams } from '../../../../shared/types/socket';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

// Services
import { ClipboardService } from '../../../../core/services/clipboard/clipboard.service';
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { ShareService } from '../../../../core/services/share/share.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../core/services/logger/logger.service';

@Component({
  selector: 'app-torgram-link',
  templateUrl: './torgram-link.component.html',
  styleUrls: ['./torgram-link.component.scss'],
})
export class TorgramLinkComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() room?: Room;

  public inviteLinks: RoomInviteLink[];
  public inviteCustomLinks: RoomInviteLink[];
  public isLoadingLink: boolean = false;
  public isLoadingCustomLinks: boolean = false;
  public isLoadingRevokeCustomLink: boolean = false;

  private getInviteLinkSubscription: Subscription;
  private getCustomLinkSubscription: Subscription;
  private generateLinkSubscription: Subscription;
  private revokeLinkSubscription: Subscription;
  private revokeDefaultLinkSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private popoverController: PopoverController,
    private clipboardUtils: ClipboardService,
    private translate: TranslationService,
    private shareService: ShareService,
    private socketService: SocketService,
    private modalService: ModalService,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnInit(): void {
    this.networkService.pageNgOnInit(
      'TorgramLinkComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramLinkComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.getInviteLinkSubscription?.unsubscribe();
    this.getCustomLinkSubscription?.unsubscribe();
    this.generateLinkSubscription?.unsubscribe();
    this.revokeLinkSubscription?.unsubscribe();
    this.revokeDefaultLinkSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  async presentLinkOptions(event: Event, index: number): Promise<void> {
    const popover = await this.popoverController.create({
      component: TorgramLinkSettingsComponent,
      event,
      componentProps: {
        baseInviteLink: this.socketService.inviteLinkBaseUrl,
        link: this.inviteLinks[index].linkId,
      },
    });
    await popover.present();

    const { data } = await popover.onDidDismiss<string>();

    if (data === 'revoke') {
      this.revokeLink();
    }
  }

  async openCustomLinkModal(): Promise<void> {
    const modal = await this.modalService.openTorgramCustomLinkComponent(this.room);

    void modal.present();

    const results = await modal.onDidDismiss();
    await this.modalService.dismissLast();

    if (results.data !== undefined) {
      this.createCustomLink(results.data as RoomCreateInviteLinkParams);
    }
  }

  shareLink(link: RoomInviteLink): void {
    void this.shareService.shareInvitationLink(this.socketService.inviteLinkBaseUrl + link?.linkId);
  }

  revokeCustomLink(link: RoomInviteLink): void {
    this.isLoadingRevokeCustomLink = true;
    this.socket.emit(SocketEvents.ROOM_REVOKE_INVITE_LINK, { roomId: this.room.id, linkId: link.linkId });
  }

  copyCustomLink(link: RoomInviteLink): void {
    this.clipboardUtils.write(
      link.linkId,
      this.translate.instant('TORGRAM.COPY_LINK_SUCCESS'),
      this.translate.instant('TORGRAM.COPY_LINK_FAIL'),
    );
  }

  async dismissModal(): Promise<void> {
    await this.modal.dismiss();
    await this.modalService.dismissLast();
  }

  private getRoomLink(defaultLink: boolean) {
    this.isLoadingLink = true;
    this.socket.emit(SocketEvents.ROOM_GET_INVITE_LINKS, { roomId: this.room.id, defaultLink });
  }

  private revokeLink() {
    this.isLoadingLink = true;
    this.socket.emit(SocketEvents.ROOM_REVOKE_DEFAULT_INVITE_LINK, { roomId: this.room.id });
  }

  private createCustomLink(param: RoomCreateInviteLinkParams) {
    this.isLoadingCustomLinks = true;
    this.socket.emit(SocketEvents.ROOM_GENERATE_INVITE_LINK, param);
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramLinkComponent');
    }

    this.getInviteLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET_INVITE_LINKS).subscribe({
      next: (newInviteLink: RoomInviteLink[]) => {
        this.inviteLinks = newInviteLink;
        this.isLoadingLink = false;
      },
    });

    this.getCustomLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET_CUSTOM_LINK).subscribe({
      next: (newInviteLink: RoomInviteLink[]) => {
        this.inviteCustomLinks = newInviteLink;
      },
    });

    this.generateLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_GENERATE_INVITE_LINK).subscribe({
      next: (newInviteLink: RoomInviteLink) => {
        this.inviteCustomLinks.push(newInviteLink);
        this.isLoadingCustomLinks = false;
      },
    });

    this.revokeLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_REVOKE_INVITE_LINK).subscribe({
      next: (linkId: RoomInviteLink) => {
        const index = this.inviteCustomLinks?.findIndex(
          (inviteCustomLinks) => linkId.linkId === inviteCustomLinks.linkId,
        );

        if (index !== -1) {
          this.inviteCustomLinks.splice(index, 1);
        }
        this.isLoadingRevokeCustomLink = false;
      },
    });

    this.revokeDefaultLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_REVOKE_DEFAULT_INVITE_LINK).subscribe({
      next: (newInviteLink: RoomInviteLink) => {
        const index = this.inviteLinks?.findIndex((inviteLink) => inviteLink.id === newInviteLink.id);

        if (index !== -1) {
          this.inviteLinks[index] = newInviteLink;
        } else {
          this.inviteLinks.push(newInviteLink);
        }

        this.isLoadingLink = false;
      },
    });

    // Default Links
    this.getRoomLink(true);

    // Custom Links
    this.getRoomLink(false);
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramLinkComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }
}
