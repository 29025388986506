import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';

// Services
import { TranslationService } from '../../services/translation/translation.service';
import { LoaderService } from '../../services/loader/loader.service';
import { PwaService } from '../../services/pwa/pwa.service';
import { LoggerService } from '../../services/logger/logger.service';

import { environment } from '../../../../environments/environment';

export const PwaGuard: CanActivateFn = () => {
  let alreadyFired: boolean = false;

  const swUpdate = inject(SwUpdate);
  const translate = inject(TranslationService);
  const loader = inject(LoaderService);
  const pwa = inject(PwaService);
  const logger = inject(LoggerService);

  return new Observable((observer) => {
    logger.consoleLog('PwaGuard', 'canActivate', { isEnabled: swUpdate.isEnabled });

    void pwa.checkPwa();
    if (environment.production) {
      if (swUpdate.isEnabled) {
        void swUpdate.versionUpdates.subscribe({
          next: (newUpdate) => {
            logger.consoleLog('PwaGuard', 'activateUpdate: ' + new Date().toISOString(), newUpdate);

            if (newUpdate.type === 'VERSION_READY') {
              if (!alreadyFired) {
                alreadyFired = true;
                void loader.present(translate.instant('PWA.NEW_VERSION'), false).then(() => {
                  setTimeout(() => {
                    loader.dismiss();

                    alreadyFired = false;
                    window.location.reload();
                  }, 1250);
                });
              }
            }
          },
        });

        void swUpdate.checkForUpdate();
      }
    } else {
      logger.consoleLog('PwaGuard', 'canActivate', 'NOT PRODUCTION');
    }

    pwa
      .checkIfRunningInIncognito()
      .then(() => observer.next(true))
      .catch(() => logger.consoleLog('PwaGuard', 'checkIfRunningInIncognito'));
  });
};
