import { Injectable } from '@angular/core';
import { HttpBackend } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

// Services
import { StorageService } from '../storage/storage.service';
import { CommonService } from '../common/common.service';

// Types
import { StorageKey } from '../../../shared/types/storage';

const BASE_PATH = './assets/i18n';
const SUFFIX: string = '.json';

const buildPath = (PATH_FOLDER: string): string => [BASE_PATH, PATH_FOLDER].join('/') + '/';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: buildPath('ARTICLES'), suffix: SUFFIX },
    { prefix: buildPath('COMMON'), suffix: SUFFIX },
    { prefix: buildPath('FIND'), suffix: SUFFIX },
    { prefix: buildPath('FUNCTIONALITIES'), suffix: SUFFIX },
    { prefix: buildPath('KEYS'), suffix: SUFFIX },
    { prefix: buildPath('LOGIN'), suffix: SUFFIX },
    { prefix: buildPath('NEWS'), suffix: SUFFIX },
    { prefix: buildPath('PWA'), suffix: SUFFIX },
    { prefix: buildPath('SHARE'), suffix: SUFFIX },
    { prefix: buildPath('SETTINGS'), suffix: SUFFIX },
    { prefix: buildPath('TORGRAM'), suffix: SUFFIX },
    { prefix: buildPath('WALLET'), suffix: SUFFIX },
  ]);
}

@Injectable({ providedIn: 'root' })
export class TranslationService {
  langs = ['it', 'en'];

  constructor(
    public translate: TranslateService,
    private storageService: StorageService,
    private commonService: CommonService,
  ) {
    this.translate.addLangs(this.langs);
  }

  setCurrentLanguage(lang: string): void {
    this.storageService.setOnStorage(StorageKey.Lang, lang).subscribe();
    this.translate.use(lang);
    this.commonService.defaultLanguage = lang;
  }

  instant(path: string, params?: Record<string, string | number>): string {
    return this.translate.instant(path, params) as string;
  }
}
