import { Component } from '@angular/core';

// Services
import { PwaService } from '../../../core/services/pwa/pwa.service';
import { TranslationService } from '../../../core/services/translation/translation.service';

@Component({
  selector: 'app-pwa-not-available',
  templateUrl: './pwa-not-available.component.html',
  styleUrls: ['./pwa-not-available.component.scss'],
})
export class PwaNotAvailableComponent {
  constructor(
    private pwa: PwaService,
    private translate: TranslationService,
  ) {}

  get isIncognito(): boolean {
    return this.pwa.isIncognito;
  }

  get incognitoModeLabel(): string {
    const modeName = this.pwa.modeName ?? 'PWA.PRIVATE_WINDOW';
    return this.translate.instant('PWA.NOT_ALLOW') + ' ' + this.translate.instant(modeName);
  }
}
