<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="2" class="ion-justify-content-flex-start ion-align-items-center">
        @if (stepper !== numberOfStep) {
          <ion-buttons>
            <ion-button defaultHref="wallet" color="light" (click)="cancelOperation()">
              <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        }
      </ion-col>

      <ion-col size="8" class="ion-justify-content-center ion-align-items-center">
        <ion-label class="ion-text-wrap ion-text-center ion-text-bold ion-text-uppercase">
          {{ 'TORGRAM.INVITATIONQRCODE' | translate }}
        </ion-label>
      </ion-col>

      <ion-col size="2" class="ion-justify-content-flex-start ion-align-items-center"></ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  @if (qrData) {
    @if (stepper !== numberOfStep) {
      <ion-toolbar class="ion-no-border">
        <ion-row>
          <ion-col size="1" class="ion-justify-content-start ion-align-items-center">
            <ion-icon ios="information-circle-outline" md="information-circle-sharp"></ion-icon>
          </ion-col>

          <ion-col size="11">
            <ion-label class="ion-no-padding ion-text-bold">
              {{ 'TORGRAM.INVITATIONQRCODE_STEPS.STEP_' + stepper | translate }}
            </ion-label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-label
              innerHTML="{{ 'TORGRAM.INVITATIONQRCODE_STEPS.INSTRUCTIONS_' + stepper | translate }}"
              style="font-size: 0.9em"
            ></ion-label>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    }

    <ion-row class="ion-full-height">
      <ion-col size="12">
        <!-- stepper === 0 => GENERAZIONE PRIMO CODICE QR -->
        @if (stepper === 0) {
          <div class="qrCodeFrame noQrCodeFrame">
            <qrcode
              [qrdata]="qrData"
              [width]="250"
              style="text-align: center"
              [errorCorrectionLevel]="'M'"
              [allowEmptyString]="true"
              (click)="showQrCode()"
            >
            </qrcode>
          </div>
        }

        <!-- stepper === 1 => LETTURA CODICE QR CONTROPARTE -->
        @if (stepper === 1) {
          <div class="qrCodeFrame" [class.noQrCodeFrame]="cameraError">
            @if (!cameraError) {
              <app-qr-code-scanner
                #qrCodeScanner
                (camerasFound)="selectCamera($event)"
                (cameraError)="showCameraError(true, 'TORGRAM.NEED_CAMERA_ACCESS')"
                (scanSuccess)="scanCompleted($event)"
                (notHTTPSEnvironment)="showCameraError(true, 'TORGRAM.NEED_HTTPS_ENVIRONMENT')"
              >
              </app-qr-code-scanner>
            }
            @if (cameraError) {
              <ion-label
                class="ion-text-center"
                color="danger"
                (click)="reOpenCamera()"
                [innerHTML]="cameraErrorText | translate"
              ></ion-label>
            }
          </div>
        }

        <!-- stepper === numberOfStep => FINITO JOIN -->
        @if (stepper === 2) {
          <div class="ion-full-height ion-align-items-center ion-justify-content-center">
            @if (errorMessage) {
              @if (errorMessage === 'CANNOT_JOIN_ROOM') {
                <h3 class="ion-text-center" [innerHTML]="'TORGRAM.ERRORS.' + errorMessage + '_OWNER' | translate"></h3>
              } @else {
                <h3 class="ion-text-center" [innerHTML]="'TORGRAM.ERRORS.' + errorMessage | translate"></h3>
              }
            } @else if (waitingJoinOnRoom) {
              <ion-spinner name="dots"></ion-spinner>
            } @else {
              <h3 class="ion-text-center" [innerHTML]="'TORGRAM.QRCODE_COMPLETE_OWNER' | translate"></h3>
            }
          </div>
        }
      </ion-col>
    </ion-row>
  } @else {
    <div class="ion-full-height ion-align-items-center ion-justify-content-center">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  }
</ion-content>

<ion-footer class="ion-padding-horizontal ion-padding-bottom">
  <ion-row class="ion-justify-content-end">
    <ion-col size="6">
      @if (stepper === numberOfStep) {
        @if (!waitingJoinOnRoom || errorMessage) {
          <ion-button color="fri-dark" expand="block" size="small" (click)="closeDialog()">
            <ion-label class="ion-text-wrap ion-text-bold ion-text-uppercase">
              {{ 'COMMON.CLOSE' | translate }}
            </ion-label>
          </ion-button>
        }
      } @else {
        @if (canContinue) {
          <ion-button color="fri-dark" expand="block" size="small" (click)="increaseStep()">
            <ion-label class="ion-text-wrap ion-text-bold ion-text-uppercase">
              {{ 'TORGRAM.CONTINUE' | translate }}
            </ion-label>
          </ion-button>
        }
      }
    </ion-col>
  </ion-row>
</ion-footer>
