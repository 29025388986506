import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Types
import { ICategory } from '../../../shared/types';

@Injectable({ providedIn: 'root' })
export class CommonService {
  public zoomMode: string = 'a';
  public defaultLanguage: string = 'en';
  public FRI_TOKEN: number = null;
  public CATEGORIES: ICategory = {
    BLOG: { data: null, page: 1, toDisplay: '', needPrivate: false, icon: 'assets/custom-icons/news/blog.svg' },
    AAA: { data: null, page: 1, toDisplay: '', needPrivate: true, icon: 'assets/custom-icons/news/aaa.svg' },
    COMUNICATIONS: {
      data: null,
      page: 1,
      toDisplay: '',
      needPrivate: true,
      icon: 'assets/custom-icons/news/comunications.svg',
    },
    RADIO: { data: null, page: 1, toDisplay: '', needPrivate: false, icon: 'assets/custom-icons/news/radio.svg' },
    IRONY: { data: null, page: 1, toDisplay: '', needPrivate: false, icon: 'assets/custom-icons/news/irony.svg' },
    REAL_JOURNAL: { data: null, page: 1, toDisplay: '', needPrivate: false, icon: 'assets/custom-icons/news/vg.svg' },
    NEWSLETTER: {
      data: null,
      page: 1,
      toDisplay: '',
      needPrivate: true,
      icon: 'assets/custom-icons/news/newsletter.svg',
    },
  };

  constructor(private router: Router) {}

  async torgramNavigationWithBaseHref(path: string = '', replaceUrl: boolean = false): Promise<void> {
    const url = this.getTorgramNavigationWithBaseHref(path);

    if (replaceUrl) {
      await this.router.navigate([url], { replaceUrl: true });
    } else {
      await this.router.navigateByUrl(url);
    }
  }

  getTorgramNavigationWithBaseHref(path: string = '', ignoreTorgram: boolean = false): string {
    if (!path) {
      return '/torgram';
    } else {
      const url = ignoreTorgram ? path : ['/torgram', path].join('/');
      return url;
    }
  }
}
