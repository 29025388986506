import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {
  constructor(private menuCtrl: MenuController) {}

  async openMenu(): Promise<void> {
    const isOpen = await this.menuCtrl.isOpen();

    if (isOpen) {
      void this.menuCtrl.close();
    } else {
      void this.menuCtrl.open();
    }
  }
}
