import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Animations
import { RightToLeftAnimationDirective } from './animations/right-to-left-animation.directive';
import { LongPressDirective } from './long-press/long-press.directive';

const ANIMATIONS = [RightToLeftAnimationDirective, LongPressDirective];

@NgModule({
  declarations: [...ANIMATIONS],
  imports: [CommonModule],
  exports: [...ANIMATIONS],
})
export class DirectivesModule {}
