@if (isIncognito) {
  <ion-grid class="ion-full-height ion-justify-content-center ion-align-items-center">
    <ion-row class="ion-text-center">
      <ion-col size="12" class="ion-text-center ion-padding-horizontal ion-no-padding-bottom">
        <ion-label class="ion-text-wrap ion-text-uppercase ion-text-bold" translate>COMMON.CAUTION</ion-label>
      </ion-col>

      <ion-col size="12" class="ion-padding-horizontal">
        <ion-label class="ion-text-wrap" [innerHTML]="incognitoModeLabel"></ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
}
