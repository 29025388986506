import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { NetworkService } from '../../services/network/network.service';

export const ConnectionGuard: CanActivateFn = () => {
  const networkService = inject(NetworkService);

  return new Observable((observer) => {
    networkService.appStarted();

    observer.next(true);
  });
};
