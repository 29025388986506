import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpBackend, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import localeIt from '@angular/common/locales/it';
import { Drivers, StorageConfig } from '@ionic/storage';

// Environment
import { environment } from '../environments/environment';

// i18n
import { TranslationService, createTranslateLoader } from './core/services/translation/translation.service';

// Interceptors
import intercept from './core/interceptor/http-config.interceptor';
import errorIntercept from './core/interceptor/error-handler.interceptor';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeIt, 'it-IT');

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StorageService } from './core/services/storage/storage.service';
import { PwaService } from './core/services/pwa/pwa.service';

import { initializeApp } from './app.initializer';

const storageConfig = {
  name: environment.STORAGE_NAME,
  driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
} as StorageConfig;

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: false,
      scrollPadding: false,
      scrollAssist: true,
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(storageConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),

    // Custom Modules
    CoreModule,
    SharedModule,
  ],
  providers: [
    provideHttpClient(withInterceptors([intercept, errorIntercept])),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [StorageService, TranslationService, PwaService],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
