/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// Environment
import { environment } from '../../../../environments/environment';

// Types
import { IPost, IPostAttachment } from '../../../shared/types';

// Services
import { WebServiceCall } from '../../services/webservice-caller/webservice-caller.service';
import { LoggerService } from '../../services/logger/logger.service';
import { TranslationService } from '../../services/translation/translation.service';

@Injectable({ providedIn: 'root' })
export class WordpressService {
  private URLS = environment.URLS;
  private FORCE_ERROR: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private webServiceCall: WebServiceCall,
    private logger: LoggerService,
    private translate: TranslationService,
  ) {}

  getSectionData(
    url: string,
    token: string,
    page = 1,
    isPrivate: boolean,
    isRealJournal: boolean = false,
  ): Observable<IPost[] | any> {
    return new Observable<IPost[] | any>((observer) => {
      const options = this.buildGetPostOptions(url, page, 20, token, isPrivate);
      this.logger.consoleLog('WordpressService', 'getSectionData', { url, page, token, isPrivate, isRealJournal });

      this.webServiceCall.getCall(url, options, token).subscribe({
        next: (resp) => {
          this.logger.consoleLog('WordpressService', 'getSectionData', resp);

          let results = resp['body'];

          if ('success' in results && results.success === false) {
            this.logger.consoleError('WordpressService', 'getSectionData', results);
            observer.error(results);
          } else {
            if (isRealJournal) {
              results = (results as IPost[]).filter((post: IPost) => post.link.includes('vero-giornale'));
            } else {
              results = (results as IPost[]).filter((post: IPost) => !post.link.includes('vero-giornale'));
            }

            (results as IPost[]).map((post: IPost) => this.formatGotPost(url, post));

            if (isPrivate && this.FORCE_ERROR) {
              observer.error({ message: 'Expired token' });
            } else {
              observer.next(results);
            }
          }
        },
        error: (error) => {
          this.logger.consoleError('WordpressService', 'getSectionData', { url, error });
          observer.error(error);
        },
      });
    });
  }

  getSimplePostTicker(): Observable<string | any> {
    return new Observable<string>((observer) => {
      const url: string = this.URLS.POST_TICKER_URL;
      const options = this.buildGetPostOptions(url, null, null, null, false);

      this.webServiceCall.getCall(url, options).subscribe({
        next: (resp) => {
          const results = resp['body'];

          if ('success' in results && results.success === false) {
            this.logger.consoleError('WordpressService', 'getSimplePostTicker', { url, results });
            observer.error(results);
          } else {
            const _results = results;
            let scrollingNews: string = '';
            _results.map((post: IPost) => this.formatGotPost(url, post));

            _results.map(
              (post: IPost, index: number) =>
                (scrollingNews +=
                  post.title.rendered + (index !== results.length - 1 ? '&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;' : '')),
            );

            observer.next(scrollingNews);
          }
        },
        error: (error) => {
          this.logger.consoleError('WordpressService', 'getSimplePostTicker', { url, error });
          observer.error(error);
        },
      });
    });
  }

  searchNews(
    url: string,
    isRealJournal: boolean,
    wordToSearch: string,
    token: string,
    isPrivate: boolean,
  ): Observable<IPost[] | any> {
    return new Observable<IPost[] | any>((observer) => {
      const options = this.buildSearchPostOptions(url, wordToSearch, token, isPrivate);
      this.logger.consoleLog('WordpressService', 'searchNews', { url, wordToSearch, token, isPrivate });

      this.webServiceCall.getCall(url, options, token).subscribe({
        next: (resp) => {
          const results = resp['body'];

          if ('success' in results && results.success === false) {
            this.logger.consoleError('WordpressService', 'searchNews', { url, results });
            observer.error(results);
          } else {
            const _results = results as IPost[];
            if (isRealJournal) {
              _results.filter((post: IPost) => post.link.includes('vero-giornale'));
            }

            _results.map((post: IPost) => this.formatGotPost(url, post));

            if (isPrivate && this.FORCE_ERROR) {
              observer.error({ message: 'Expired token' });
            } else {
              observer.next(_results);
            }
          }
        },
        error: (error) => {
          this.logger.consoleError('WordpressService', 'searchNews', { url, error });
          observer.error(error);
        },
      });
    });
  }

  private formatGotPost(url: string, post: IPost): IPost {
    if (
      post &&
      post['_embedded'] &&
      post['_embedded']['wp:featuredmedia'] &&
      post['_embedded']['wp:featuredmedia'][0]['media_details'] &&
      post['_embedded']['wp:featuredmedia'][0]['media_details'].sizes['full']
    ) {
      post.media_url = post['_embedded']['wp:featuredmedia'][0]['media_details'].sizes['full'].source_url;
    }

    const contentContent: string = post['content'].rendered.length > 0 ? post['content'].rendered.trim() : null;
    post.contentContent = this.sanitizer.bypassSecurityTrustHtml(contentContent);

    if (post.title.rendered.includes('Private: ')) {
      post.title.rendered = post.title.rendered.split('Private: ')[1];
    }

    post.attachments = [];
    if (post.excerpt && post.excerpt.rendered.length > 0) {
      post.attachments = this.getAttachments(post);
    }

    if (contentContent !== null) {
      if (url.includes('aaa')) {
        const today = new Date().toLocaleDateString();
        const postDate = new Date(post.date).toLocaleDateString();

        post.isAAAImportant = contentContent.includes('aaa_important') && today === postDate;
        post.isAAAImportantPost = contentContent.includes('aaa_important');
      }

      post.isForm = contentContent.includes('isForm');
      post.isOnlyImage = contentContent.includes('isOnlyImage');

      const div: HTMLElement = document.createElement('div');
      div.innerHTML = contentContent;

      const cVidd: Element = div.getElementsByClassName('cVidd')[0];
      if (cVidd !== null && cVidd !== undefined) {
        const video: Element = cVidd.getElementsByTagName('video')[0];

        if (video !== undefined) {
          post.video_url = video['src'] as string;
        } else {
          const iframe: Element = cVidd.getElementsByTagName('iframe')[0];
          post.video_url = iframe !== undefined ? (iframe['src'] as string) : null;
        }
      } else {
        post.video_url = null;

        const p: Element = div.getElementsByTagName('p')[0];
        if (p !== undefined) {
          const ionLabel: Element = document.createElement('ion-label');
          ionLabel.className = 'previewArticle';
          ionLabel.innerHTML = p.textContent + '<i>...' + this.translate.instant('ARTICLES.CONTINUE') + '</i>';

          if (p.textContent.trim().length > 0) {
            post.preview = this.sanitizer.bypassSecurityTrustHtml(ionLabel.innerHTML);
          }
        }
      }
    } else {
      post.video_url = null;
    }

    if (!('preview' in post)) {
      post.preview = null;
    }

    return post;
  }

  private getAttachments(post: IPost): IPostAttachment[] {
    const attachments: IPostAttachment[] = [];

    const div = document.createElement('div');
    div.innerHTML = post.excerpt.rendered;

    const tagsP = div.getElementsByTagName('p');

    const array = Array.prototype.slice.call(tagsP) as Element[];
    array.map((p: Element) => {
      const splitted = p.innerHTML.split('/');

      if (p.innerHTML.includes('.pdf')) {
        attachments.push({
          type: 'document',
          src: p.innerHTML,
          toDisplay: splitted[splitted.length - 1],
        } as IPostAttachment);
      } else if (p.innerHTML.includes('.mp3')) {
        attachments.push({
          type: 'musical-note',
          src: p.innerHTML,
          toDisplay: splitted[splitted.length - 1],
        } as IPostAttachment);
      }
    });

    return attachments;
  }

  private buildGetPostOptions(
    url: string,
    page: number | null,
    per_page: number | null,
    token: string,
    isPrivate: boolean,
  ): Record<string, unknown> {
    const options = {
      observe: 'response' as 'body',
      params: {
        orderby: url?.includes('aaa') ? 'menu_order' : 'date',
        order: url?.includes('aaa') ? 'asc' : 'desc',
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (per_page !== null) {
      options.params['per_page'] = per_page;
    }

    if (page !== null) {
      options.params['page'] = page;
    }

    if (token !== null && token.trim().length > 0 && isPrivate) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    return options;
  }

  private buildSearchPostOptions(
    url: string,
    wordToSearch: string,
    token: string,
    isPrivate: boolean,
  ): Record<string, unknown> {
    const options = {
      observe: 'response' as 'body',
      params: {
        orderby: url?.includes('aaa') ? 'menu_order' : 'date',
        order: url?.includes('aaa') ? 'asc' : 'desc',
        search: wordToSearch,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (token !== null && token.trim()?.length > 0 && isPrivate) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    return options;
  }
}
