<ion-header>
  <ion-toolbar color="fri-dark" mode="ios" class="ion-no-padding">
    <ion-back-button defaultHref="home"></ion-back-button>

    <ion-title class="ion-no-padding">{{ 'FUNCTIONALITIES.GATEKEEPER.MAIN' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-menu-button autoHide="false"> </ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-label> {{ 'FUNCTIONALITIES.GATEKEEPER.SUGGESTION' | translate }} </ion-label>

  <div class="flexCenterCenter full-width">
    <ion-card mode="ios" class="full-width">
      <ion-card-content class="full-height">
        @if (allowed) {
          <video #video [hidden]="!scanActive" width="100%;" height="100%"></video>

          <canvas #canvas hidden></canvas>

          @if (!scanActive) {
            <qrcode
              [qrdata]="response"
              style="text-align: center"
              [errorCorrectionLevel]="'M'"
              [allowEmptyString]="true"
            >
            </qrcode>
          }
        } @else {
          <div class="div flexCenterCenter full-height">
            <ion-label
              style="align-self: center; font-weight: bold"
              color="danger"
              class="ion-no-padding ion-text-center"
            >
              {{ 'FUNCTIONALITIES.GATEKEEPER.NO_ACCESS_CAMERA' | translate }}
            </ion-label>
          </div>
        }
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
