import { Injectable, NgZone } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';

// Services
import { LoggerService } from '../logger/logger.service';
import { TranslationService } from '../translation/translation.service';

// Types
import { PageNgOnInitCallBack } from '../../../shared/types/common';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  public connected: boolean;
  public connectedSubject: Subject<void> = new Subject<void>();

  private isPresent: boolean = false;

  constructor(
    private logger: LoggerService,
    private zone: NgZone,
    private toastController: ToastController,
    private translate: TranslationService,
  ) {
    // Workaround per le problematiche dovute al lazy loading del toastController
    void this.toastController
      .create({
        animated: false,
        icon: 'wifi',
        position: 'bottom',
        color: 'primary',
        buttons: [{ icon: 'close', role: 'cancel' }],
      })
      .then((t) => {
        void t.present();
        void t.dismiss();
      });
  }

  public appStarted(): void {
    void Network.getStatus().then(({ connected }: ConnectionStatus) => {
      this.zone.run(() => {
        this.logger.consoleLog('NetworkService', 'getStatus', connected);
        this.connected = connected;

        if (this.connected) {
          if (this.isPresent) {
            void this.dismissToast();
          }
        } else if (!this.isPresent) {
          void this.presentToast();
        }
      });
    });

    void Network.addListener('networkStatusChange', ({ connected }: ConnectionStatus) => {
      this.zone.run(() => {
        this.logger.consoleLog('NetworkService', 'networkStatusChange', { connected });
        this.connected = connected;

        if (this.connected) {
          if (this.isPresent) {
            void this.dismissToast();
          }

          this.connectedSubject.next();
        } else {
          if (!this.isPresent) {
            void this.presentToast();
          }
        }
      });
    });
  }

  public pageNgOnInit(
    from: string,
    callBack?: PageNgOnInitCallBack,
    notConnectedCallback?: PageNgOnInitCallBack,
  ): void {
    setTimeout(() => {
      this.logger.consoleLog('NetworkService', 'pageNgOnInit', from);

      void Network.addListener('networkStatusChange', ({ connected }) => {
        this.zone.run(() => {
          this.connected = connected;
          this.logger.consoleLog(from, 'networkStatusChange', connected);

          if (this.connected) {
            if (this.isPresent) {
              void this.dismissToast();
            }

            callBack?.();
          } else {
            if (!this.isPresent) {
              void this.presentToast(from);
            }

            notConnectedCallback?.();
          }
        });
      });

      callBack();
    }, 500);
  }

  public removeListeners(from: string): void {
    this.logger.consoleLog(from, 'removeListeners');
    void Network.removeAllListeners();
  }

  private async presentToast(from: string = 'NetworkService'): Promise<void> {
    this.logger.consoleLog(from, 'presentToast', { connected: this.connected });
    this.isPresent = true;

    try {
      await this.toastController.dismiss();
    } catch (error: unknown) {}

    const toast = await this.toastController.create({
      message: this.translate.instant('COMMON.NO_INTERNET_CONNECTION'),
      icon: 'wifi',
      position: 'bottom',
      color: 'primary',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {
            this.isPresent = false;
          },
        },
      ],
    });

    void toast.present();
  }

  private async dismissToast(from: string = 'NetworkService'): Promise<void> {
    this.logger.consoleLog(from, 'dismissToast', { connected: this.connected });

    this.isPresent = false;
    await this.toastController.dismiss();
  }
}
