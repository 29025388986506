import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-qrcode-modal',
  templateUrl: './qrcode-modal.component.html',
  styleUrls: ['./qrcode-modal.component.scss'],
})
export class QRCodeModalComponent {
  @Input() qrData?: string;

  get width(): number {
    return document.querySelector('body').clientWidth;
  }
}
