/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { EventEmitter, Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeWhile } from 'rxjs/operators';
import { ScreenOrientation } from '@capacitor/screen-orientation';

// Modals
import { OrientationPage } from '../../../shared/modals/orientation/pages/orientation.page';

@Injectable({
  providedIn: 'root',
})
export class OrientationService {
  public currentOrientation$: Observable<OrientationType>;
  public lockedOrientation$ = new BehaviorSubject<OrientationType | null>(null);
  public lockIsReady$ = new BehaviorSubject<boolean>(false);

  private modalActive = false;
  private updateEvent = new EventEmitter();

  constructor(
    private modalController: ModalController,
    private platform: Platform,
  ) {
    const events = [
      this.updateEvent,
      fromEvent(document, 'visibilitychange'),
      fromEvent(document, 'fullscreenchange'),
      fromEvent(window, 'resize'),
    ];

    if (screen.orientation) {
      events.push(fromEvent(screen.orientation, 'change'));
    }

    this.currentOrientation$ = merge(...events).pipe(
      debounceTime(200),
      map(() => this.checkOrientation()),
    );
  }

  /**
   * Sets the orientation or triggers Fullscreen depending on support
   * @param orientation - The desired orientation
   */
  public async setOrientation(orientation: OrientationType): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      void this.toggleScreenLock(orientation).then((locked) => resolve(locked));
    });
  }

  /**
   * Forces the user to rotate the screen to match the desired orientation
   * by showing a modal whenever the orientation doesn't match
   * and making use of Screen Orientation and Fullscreen API
   *
   * @param orientation - The orientation to lock to
   */
  public lock(orientation: OrientationType): void {
    this.lockedOrientation$.next(orientation);

    this.currentOrientation$
      .pipe(
        takeWhile(() => !!this.lockedOrientation$.value),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (value) => {
          // On a desktop there is no way to change the orientation
          // therefore the lock will always be ready

          if (this.platform.is('desktop')) {
            this.lockIsReady$.next(true);
          } else if (!this.modalActive) {
            if (value === this.lockedOrientation$.value) {
              this.lockIsReady$.next(true);
            } else {
              this.lockIsReady$.next(false);
              this.modalActive = true;
              void this.showOrientationModal().then(() => {
                this.modalActive = false;
              });
            }
          }
        },
        complete: async () => {
          this.lockIsReady$.next(false);

          // To unlock orientation which will default back to the global setting:
          await ScreenOrientation.unlock();

          if (this.isFullscreenActive()) {
            // eslint-disable-next-line no-console
            document.exitFullscreen().catch((err) => console.warn(err));
          }
        },
      });
    this.updateEvent.emit();
  }

  /**
   * Unlocks the screen if it has been locked
   */
  public unlock(): void {
    this.lockedOrientation$.next(null);
    this.updateEvent.emit();
  }

  /**
   * Calculates aspect ratio and returns OrientationType
   */
  private checkOrientation(): OrientationType {
    const aspectRatio = window.outerWidth / window.outerHeight;
    return aspectRatio >= 1 ? 'landscape-primary' : 'portrait-primary';
  }

  /**
   * Checks if fullscreen mode is active
   */
  private isFullscreenActive(): boolean {
    const doc = document as any;
    return (doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement) as boolean;
  }

  /**
   * Sets the document view to fullscreen if possible
   */
  // private async toggleFullScreen(): Promise<boolean> {
  //   const doc = document.documentElement as any;
  //   const rfs =
  //     doc.requestFullscreen || doc.webkitRequestFullScreen || doc.mozRequestFullScreen || doc.msRequestFullscreen;

  //   return new Promise<boolean>((resolve) => {
  //     if (rfs) {
  //       rfs.call(doc);
  //       resolve(true);
  //     } else {
  //       resolve(false);
  //     }
  //   });
  // }

  /**
   * Sets a Screen Orientation lock if possible
   *
   * @param orientation - the desired orientation
   */
  private async toggleScreenLock(orientation: OrientationType): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      ScreenOrientation.lock({ orientation })
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  }

  /**
   * Shows a modal that requests the user to rotate the device
   */
  private async showOrientationModal(): Promise<boolean> {
    const modal = await this.modalController.create({
      component: OrientationPage,
      backdropDismiss: false,
      animated: false,
      cssClass: 'orientationModal',
    });

    await modal.present();

    return new Promise<boolean>((resolve) => {
      modal
        .onDidDismiss()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  }
}
