import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

// Services
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { ClipboardService } from '../../../../core/services/clipboard/clipboard.service';
import { NetworkService } from '../../../../core/services/network/network.service';

@Component({
  selector: 'app-torgram-link-settings',
  templateUrl: './torgram-link-settings.component.html',
})
export class TorgramLinkSettingsComponent {
  @Input() link: string;
  @Input() baseInviteLink: string;

  constructor(
    private popoverController: PopoverController,
    private translate: TranslationService,
    private clipboardUtils: ClipboardService,
    private networkService: NetworkService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  copyLink(): void {
    this.clipboardUtils.write(
      this.baseInviteLink + this.link,
      this.translate.instant('SHARE.COPY_LINK_SUCCESS'),
      this.translate.instant('SHARE.COPY_LINK_FAIL'),
    );

    this.dismiss();
  }

  revokeLink(): void {
    void this.popoverController.dismiss('revoke');
  }

  private dismiss(): void {
    void this.popoverController.dismiss();
  }
}
