<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border ion-no-padding">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="3" class="ion-justify-content-flex-start ion-align-items-center">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="6" class="ion-justify-content-center ion-align-items-center"> </ion-col>

      <ion-col size="3" class="ion-justify-content-flex-start"> </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-header class="ion-no-border ion-padding searchBar">
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ion-searchbar
        [(ngModel)]="searchBoxTxt"
        [placeholder]="'TORGRAM.SEARCH_PLACEHOLDER' | translate"
        [disabled]="!internetAvailable"
      ></ion-searchbar>
    </ion-col>
  </ion-row>
</ion-header>

<ion-content class="ion-padding">
  <ion-label class="ion-text-uppercase" translate> {{ 'TORGRAM.USERS' | translate }} </ion-label>

  @if (isLoadingMemebers) {
    <div class="ion-full-height ion-align-items-center ion-justify-content-center">
      <ion-spinner class="loadingSpinner" color="fri-dark" name="dots"></ion-spinner>
    </div>
  }

  <div class="memberDiv ion-margin-top">
    <ion-list class="border-radius" lines="full">
      @for (member of roomMembers; track $index) {
        <ion-item [disabled]="disabled(member) || !internetAvailable || isLoadingDemote" (click)="promoteUser(member)">
          <ion-label>
            {{ member.user.pk.substring(0, 4) }} ...
            {{ member.user.pk.substring(member.user.pk.length - 4) }}
          </ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>
      }
    </ion-list>
  </div>
</ion-content>
