import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Pipes
import { FilterPipe } from './filter/filter.pipe';
import { EqualPipe } from './equal/equal.pipe';
import { ForwardPipe } from './forward/forward.pipe';
import { SafePipe } from './safe/safe.pipe';
import { BreakSpacesPipe } from './break-spaces/break-spaces.pipe';

const PIPES = [FilterPipe, EqualPipe, ForwardPipe, SafePipe, BreakSpacesPipe];

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, FormsModule],
  declarations: PIPES,
  exports: PIPES,
})
export class PipesModule {}
