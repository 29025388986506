import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionSheetController, IonModal } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Services
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { ShareService } from '../../../../core/services/share/share.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { CommonService } from '../../../../core/services/common/common.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../core/services/logger/logger.service';

// Types
import { BanMemberRoomParams, ChangeClaimRoomParams, RoomSubBody } from '../../../../shared/types/socket';
import { Room, RoomInviteLink, RoomMembers, RoomUsers } from '../../../../shared/types/tables';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

@Component({
  selector: 'app-torgram-user-list',
  templateUrl: './torgram-user-list.component.html',
  styleUrls: ['./torgram-user-list.component.scss'],
})
export class TorgramUserListComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() role?: string;
  @Input() room?: Room;

  public memberList: RoomMembers[];
  public isLoadingUsers: boolean = false;
  public isLoadingBanned: boolean = false;
  public isLoadingRequest: boolean = false;
  public isLoadingUnban: boolean = false;

  private inviteLinks: RoomInviteLink[];
  private visible: boolean;

  private getLinkSubscription: Subscription;
  private roomMemberClaimsSubscription: Subscription;
  private roomMembersSubscription: Subscription;
  private roomBannedMemberSubscription: Subscription;
  private roomJoinedSubscription: Subscription;
  private newJoinRequestSubscription: Subscription;
  private roomJoinedRequestSubscription: Subscription;
  private roomBannedMembersSubscription: Subscription;
  private roomUnbanMemberSubscription: Subscription;
  private errorSubscription: Subscription;
  private roomAddUsersSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private translate: TranslationService,
    private socketService: SocketService,
    private actionSheetCtrl: ActionSheetController,
    private shareService: ShareService,
    private modalService: ModalService,
    private common: CommonService,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get canEnterVisible(): boolean {
    if (this.role !== 'owner') {
      if (this.room.members[0].owner || (this.room.members[0].moderator && this.role === 'reader')) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    } else {
      this.visible = false;
    }
    return this.visible;
  }

  get personLabel(): string {
    return this.role === 'owner'
      ? this.translate.instant('TORGRAM.ADDOWNER')
      : this.role === 'moderator'
        ? this.translate.instant('TORGRAM.ADDMODERATOR')
        : this.role === 'contributor'
          ? this.translate.instant('TORGRAM.ADDWRITER')
          : '';
  }

  get label(): string {
    return this.role === 'owner'
      ? this.translate.instant('TORGRAM.OWNERS')
      : this.role === 'moderator'
        ? this.translate.instant('TORGRAM.MODERATORS')
        : this.role === 'contributor'
          ? this.translate.instant('TORGRAM.CONTRIBUTORS')
          : this.role === 'reader'
            ? this.translate.instant('TORGRAM.READERS')
            : this.role === 'unsubscribed'
              ? this.translate.instant('TORGRAM.BANNED_USERS')
              : this.translate.instant('TORGRAM.WAITINGFORAPPROVAL');
  }

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnInit(): void {
    this.networkService.pageNgOnInit(
      'TorgramUserListComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramUserListComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.getLinkSubscription?.unsubscribe();
    this.roomMemberClaimsSubscription?.unsubscribe();
    this.roomMembersSubscription?.unsubscribe();
    this.roomBannedMemberSubscription?.unsubscribe();
    this.roomJoinedSubscription?.unsubscribe();
    this.newJoinRequestSubscription?.unsubscribe();
    this.roomJoinedRequestSubscription?.unsubscribe();
    this.roomBannedMembersSubscription?.unsubscribe();
    this.roomUnbanMemberSubscription?.unsubscribe();
    this.roomAddUsersSubscription?.unsubscribe();
    this.errorSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  canEnter(status: 'approved' | 'rejected', userId: number): void {
    const subscriptionBody = {
      roomId: this.room?.id,
      newUserId: userId,
      status,
    } as RoomSubBody;

    this.approvedToRoom(subscriptionBody);
  }

  async demoteUser(member: RoomMembers): Promise<void> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('TORGRAM.DEMOTE'),
      buttons: [
        {
          text: this.translate.instant('TORGRAM.YES'),
          role: 'confirm',
          handler: () => {
            if (this.role === 'reader') {
              const banMember = {
                roomId: this.room.id,
                memberId: member.userId,
                publicKey: this.room.members[0].user.pk,
              } as BanMemberRoomParams;

              this.banMember(banMember);
            } else {
              const updateMember = {
                roomId: this.room.id,
                memberId: member.userId,
                claims: {
                  [this.role]: false,
                },
              } as ChangeClaimRoomParams;

              this.promoteMember(updateMember);
            }
          },
        },
        {
          text: this.translate.instant('TORGRAM.NO'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  async openPromotionModal(): Promise<void> {
    await this.modalService.openTorgramPromotionComponent(this.role, this.room);
  }

  shareLink(): void {
    void this.shareService.shareInvitationLink(this.socketService.inviteLinkBaseUrl + this.inviteLinks[0].linkId);
  }

  async dismissModal(): Promise<void> {
    this.getMembers(this.room.id);

    await this.modal.dismiss();
    await this.modalService.dismissLast();
  }

  unbanUser(userId: RoomUsers['id']): void {
    this.isLoadingUnban = true;
    this.socket.emit(SocketEvents.ROOM_UNBAN_USER, {
      roomId: this.room?.id,
      userId,
    });
  }

  private getMembers(roomId: Room['id'], param?: string): void {
    this.isLoadingUsers = true;
    switch (param) {
      case 'unsubscribed':
        this.socket.emit(SocketEvents.ROOM_BANNED_USERS, {
          roomId,
        });
        break;
      case 'owner':
      case 'moderator':
      case 'contributor':
      case 'pending':
        this.socket.emit(SocketEvents.ROOM_GET_MEMBERS, {
          roomId,
          [param === 'pending' ? 'status' : 'role']: param,
        });
        break;
      default:
        this.socket.emit(SocketEvents.ROOM_GET_MEMBERS, {
          roomId,
        });
        break;
    }
  }

  private promoteMember(updateMember: ChangeClaimRoomParams): void {
    this.socket.emit(SocketEvents.ROOM_UPDATE_MEMBER_CLAIMS, { ...updateMember });
  }

  private banMember(banMember: BanMemberRoomParams): void {
    this.isLoadingBanned = true;
    this.socket.emit(SocketEvents.ROOM_BAN_MEMBER, { ...banMember });
  }

  private getRoomLink(defaultLink: boolean) {
    this.socket.emit(SocketEvents.ROOM_GET_INVITE_LINKS, { roomId: this.room.id, defaultLink });
  }

  private approvedToRoom(subscriptionBody: RoomSubBody): void {
    this.isLoadingRequest = true;
    this.socket.emit(SocketEvents.ROOM_JOIN_REQUEST, { ...subscriptionBody });
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramUserListComponent');
    }

    this.roomMemberClaimsSubscription = this.socket.fromEvent(SocketEvents.ROOM_MEMBER_CLAIMS).subscribe({
      next: (results: RoomMembers[]) => {
        this.memberList = results;
      },
    });

    this.roomMembersSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET_MEMBERS).subscribe({
      next: (results: RoomMembers[]) => {
        if (!this.memberList) {
          this.memberList = results;
        } else {
          this.memberList = this.memberList.filter((member) => results.some((res) => res.id === member.id));
          results.map((res) => {
            const index = this.memberList.findIndex((member) => member.id === res.id);

            if (index === -1) {
              this.memberList.push(res);
            } else {
              this.memberList[index] = res;
            }
          });
        }
        this.isLoadingUsers = false;
      },
    });

    this.getLinkSubscription = this.socket.fromEvent(SocketEvents.ROOM_GET_INVITE_LINKS).subscribe({
      next: (newInviteLink: RoomInviteLink[]) => {
        this.inviteLinks = newInviteLink;
      },
    });

    this.roomJoinedSubscription = this.socket.fromEvent(SocketEvents.ROOM_JOINED).subscribe({
      next: (members: RoomMembers[]) => {
        this.memberList = members;
      },
    });

    this.roomJoinedRequestSubscription = this.socket.fromEvent(SocketEvents.ROOM_JOIN_REQUEST).subscribe({
      next: () => {
        this.isLoadingRequest = false;
      },
    });

    this.roomBannedMemberSubscription = this.socket
      .fromEvent<{ members: RoomMembers[]; room: Room }>(SocketEvents.ROOM_BAN_MEMBER)
      .subscribe({
        next: async ({ members }) => {
          const index = members?.findIndex(({ userId }) => userId === this.room.members[0].userId);

          if (index === -1) {
            await this.modalService.dismissAll();
            await this.common.torgramNavigationWithBaseHref();
          } else {
            this.memberList = members;
          }

          this.isLoadingBanned = false;
        },
      });

    this.newJoinRequestSubscription = this.socket.fromEvent(SocketEvents.ROOM_NEW_JOIN_REQUEST).subscribe({
      next: () => {
        this.getMembers(this.room?.id, this.role);
      },
    });

    this.errorSubscription = this.socket.fromEvent(SocketEvents.ERROR).subscribe({
      next: () => {
        this.isLoadingBanned = false;
        this.isLoadingRequest = false;
        this.isLoadingUsers = false;
        this.isLoadingUnban = false;
      },
    });

    this.roomBannedMembersSubscription = this.socket.fromEvent(SocketEvents.ROOM_BANNED_USERS).subscribe({
      next: (bannedMembers: RoomMembers[]) => {
        this.memberList = bannedMembers;

        this.isLoadingUsers = false;
      },
    });

    this.roomUnbanMemberSubscription = this.socket.fromEvent(SocketEvents.ROOM_UNBAN_USER).subscribe({
      next: (unbannedUserId: RoomUsers['id']) => {
        this.memberList = this.memberList.filter((x) => x.user.id !== unbannedUserId);
        this.isLoadingUnban = false;
      },
    });

    this.roomAddUsersSubscription = this.socket.fromEvent(SocketEvents.ROOM_ADD_USERS).subscribe({
      next: () => {
        this.getMembers(this.room?.id, this.role);
      },
    });

    this.getMembers(this.room?.id, this.role);
    this.getRoomLink(true);
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramUserListComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }
}
