import { NgModule } from '@angular/core';

import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { ModalsModule } from './modals/modals.module';
import { PipesModule } from './pipes/pipes.module';

const SHARED = [ComponentsModule, DirectivesModule, ModalsModule, PipesModule];

@NgModule({
  exports: SHARED,
})
export class SharedModule {}
