// Services
import { PwaService } from './core/services/pwa/pwa.service';
import { StorageService } from './core/services/storage/storage.service';
import { TranslationService } from './core/services/translation/translation.service';

// Types
import { StorageKey } from './shared/types/storage';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const initializeApp = (
  storage: StorageService,
  translate: TranslationService,
  pwa: PwaService,
): (() => void) => {
  // eslint-disable-next-line no-console
  console.log('[AppInizialier]', '(initializeApp)');

  return async () => {
    await pwa.checkIfRunningInIncognito();

    storage.getFromStorage<string>(StorageKey.Lang).subscribe({
      next: (lang: string | null | undefined) => {
        if (lang === null) {
          lang = 'en';
        }

        translate.setCurrentLanguage(lang ?? 'en');
      },
      error: () => {
        translate.setCurrentLanguage('en');
      },
    });

    storage.getFromStorage(StorageKey.CurrentEnvironment).subscribe({
      error: () => {
        storage.setOnStorage(StorageKey.CurrentEnvironment, 'web').subscribe();
      },
    });

    storage.getFromStorage(StorageKey.ZoomMode).subscribe({
      error: () => {
        storage.setOnStorage(StorageKey.ZoomMode, 'A').subscribe();
      },
    });
  };
};
