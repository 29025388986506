import { Injectable } from '@angular/core';

// Services
import { WordpressService } from './wordpress/wordpress.service';
import { WalletService } from './wallet/wallet.service';
import { WebService } from './web/web.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public wallet: WalletService,
    public web: WebService,
    public wordpress: WordpressService,
  ) {}
}
