/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { inject } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandlerFn, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Services
import { LoggerService } from '../services/logger/logger.service';

function catchErrorHandler(logger: LoggerService, error: HttpErrorResponse): Observable<never> {
  logger.consoleError('HttpConfigInterceptor', 'catchError', error);
  return throwError(() => (error.status === 0 ? ({ error: { code: 'CORS_ERROR' } } as HttpErrorResponse) : error));
}

export default function errorIntercept(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const logger = inject(LoggerService);
  return next(request).pipe(catchError((error: HttpErrorResponse) => catchErrorHandler(logger, error)));
}
