import { Component } from '@angular/core';

// Services
import { PwaService } from '../../../core/services/pwa/pwa.service';
import { ClipboardService } from '../../../core/services/clipboard/clipboard.service';

// Environment
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-pwa-not-installed',
  templateUrl: './pwa-not-installed.component.html',
  styleUrls: ['./pwa-not-installed.component.scss'],
})
export class PwaNotInstalledComponent {
  public readonly pwaLink: string = environment.PWA_LINK;

  constructor(
    public pwa: PwaService,
    private clipboardUtils: ClipboardService,
  ) {}

  get isPwaAvailable(): boolean {
    return !this.pwa.isIncognito;
  }

  copyToClipBoard(): void {
    this.clipboardUtils.write(this.pwaLink);
  }
}
