<ion-modal class="modal-attachment" #modal [isOpen]="isOpen" (didDismiss)="didDismiss.emit()">
  <ng-template>
    <ion-content>
      <ion-button fill="clear" color="white" class="ion-no-margin ion-no-padding close" (click)="dismissModal(modal)">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>

      <swiper #swiper [pagination]="images.length > 1" [initialSlide]="initialSlide" [zoom]="zoomOptions">
        @for (image of images; track $index) {
          <ng-template swiperSlide>
            <div class="swiper-zoom-container">
              <img [src]="image" />
            </div>
          </ng-template>
        }
      </swiper>
    </ion-content>
    <ion-footer>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="6">
            <ion-button expand="block" fill="clear" color="white" (click)="zoom('out')">
              <ion-icon name="remove-outline"></ion-icon>
              Zoom
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button expand="block" fill="clear" color="white" (click)="zoom('in')">
              <ion-icon slot="start" name="add-outline"></ion-icon>
              Zoom
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ng-template>
</ion-modal>
