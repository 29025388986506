@if (storage._storage !== null) {
  <ion-app [ngClass]="{ normalText: commonService.zoomMode === 'a', bigText: commonService.zoomMode === 'A' }">
    @if (isPwaAvailable) {
      @if (pwa.checkIfBrowserCompatible && pwa.isInstalled) {
        <ion-split-pane contentId="menu-principale" when="(min-width: 3000px)">
          <ion-menu contentId="menu-principale" type="overlay" swipe-gesture="false" side="end">
            <app-menu></app-menu>
          </ion-menu>
          <ion-router-outlet id="menu-principale"></ion-router-outlet>
        </ion-split-pane>
      } @else {
        <ion-header>
          <ion-toolbar class="ion-no-border" color="fri-dark">
            <ion-img src="./assets/logo/fri-horizontal-blue.svg"></ion-img>

            <ion-buttons slot="end">
              <ion-button fill="clear" color="light" (click)="openSupportMenu($event)">
                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <app-pwa-not-installed></app-pwa-not-installed>
        </ion-content>
      }
    } @else {
      <ion-header>
        <ion-toolbar class="ion-no-border" color="fri-dark">
          <ion-img src="./assets/logo/fri-horizontal-blue.svg"></ion-img>

          <ion-buttons slot="end">
            <ion-button fill="clear" color="light" (click)="openSupportMenu($event)">
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <app-pwa-not-available></app-pwa-not-available>
      </ion-content>
    }
  </ion-app>
}
