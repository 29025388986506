<ion-item [formGroup]="formGroup" (click)="modal.present()">
  <ion-label [position]="position">
    {{ labelText }}
  </ion-label>
  <ion-input class="ion-text-end" [placeholder]="placeholder" readonly [value]="showDate"></ion-input>
  <ion-icon slot="end" name="calendar" size="small" color="fri-dark"></ion-icon>
</ion-item>
<ion-modal #modal trigger="open-date-input">
  <ng-template>
    <ion-content>
      <div class="full-height ion-align-items-center ion-justify-content-center">
        <ion-card>
          <ion-card-content class="ion-no-padding">
            <ion-datetime
              #datetime
              force-overscroll="false"
              presentation="date"
              showDefaultButtons
              doneText="OK"
              [cancelText]="'COMMON.CANCEL' | translate"
              [min]="min"
              [max]="max"
              (ionChange)="changeDate($event)"
            >
            </ion-datetime>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
