<ion-row class="ion-justify-content-center ion-align-items-center ion-full-height">
  <ion-col size="12" class="ion-text-center">
    <ion-label [innerHTML]="'PWA.DESCRIPTION' | translate"> </ion-label>
    <br /><br />
    @if (pwa.checkIfBrowserCompatible) {
      <ion-label class="ion-text-bold"> {{ 'PWA.FOLLOW' | translate }} </ion-label>
    } @else {
      <ion-col size="12" class="ion-text-center">
        <ion-label class="ion-text-bold ion-text-wrap">
          {{ 'PWA.NOT_SUPPORTED.NOT_COMPATIBLE' | translate }}
        </ion-label>
        <br />
        @if (pwa.browserToUse) {
          <br />
          <ion-label class="ion-text-wrap" color="danger">
            {{ 'PWA.NOT_SUPPORTED.CLICK_URL' | translate }}
            <a (click)="copyToClipBoard()"> {{ pwaLink }} </a>
            {{ 'PWA.NOT_SUPPORTED.PASTE_URL' | translate }}
            <ion-label class="ion-text-bold"> {{ pwa.browserToUse }}</ion-label>
          </ion-label>
        }
      </ion-col>
    }
  </ion-col>
</ion-row>
