<ion-item lines="none" [class.pending]="transaction?.isPending">
  <ion-grid>
    <ion-row class="ion-align-items-center ion-padding ion-justify-content-between">
      <ion-col size="2.5">
        <ion-badge [color]="transaction.AMOUNT > 0 ? 'success' : 'danger'">
          {{ transaction.AMOUNT > 0 ? '+' : '-' }}
          {{ transaction.AMOUNT > 0 ? transaction.AMOUNT : -transaction.AMOUNT }}
        </ion-badge>
      </ion-col>

      <ion-col offset="1.5" size="7" class="ion-text-right">
        {{ transaction.TIMESTAMP | date: 'dd/MM/yyyy HH:mm:ss' }}
      </ion-col>

      @if (!transaction?.isPending) {
        <ion-col size="1">
          <ion-icon
            [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
            [name]="transaction.collapsed ? 'chevron-down-outline' : 'chevron-up-outline'"
            (click)="transaction.collapsed = !transaction.collapsed"
          ></ion-icon>
        </ion-col>
      }

      @if (transaction?.isPending) {
        <ion-col size="1">
          <ion-icon [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'" name="hourglass-outline"></ion-icon>
        </ion-col>
      }
    </ion-row>

    @if (!transaction.collapsed) {
      <ion-row>
        <ion-col size="12">
          @if (transaction.NOTE) {
            <ion-item (click)="clipboardUtils.write(transaction.NOTE)">
              <ion-icon
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
                slot="start"
                name="document-outline"
              >
              </ion-icon>
              <ion-label class="ion-text-nowrap">{{ transaction.NOTE }}</ion-label>
            </ion-item>
          }

          @if (transaction.EMAIL) {
            <ion-item (click)="clipboardUtils.write(transaction.EMAIL)">
              <ion-icon
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
                slot="start"
                name="mail-outline"
              ></ion-icon>
              <ion-label class="ion-text-nowrap">{{ transaction.EMAIL }}</ion-label>
            </ion-item>
          }

          @if (transaction.SIGN) {
            <ion-item (click)="clipboardUtils.write(transaction.SIGN)">
              <ion-icon
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
                slot="start"
                name="finger-print-outline"
              >
              </ion-icon>
              <ion-label>
                <pre>{{ transaction.SIGN }}</pre>
              </ion-label>
            </ion-item>
          }
        </ion-col>
      </ion-row>
    }
  </ion-grid>
</ion-item>
