import { Component, Input } from '@angular/core';

// Types
import { ITransaction } from '../../../shared/types/wallet';

// Services
import { CommonService } from '../../../core/services/common/common.service';
import { ClipboardService } from '../../../core/services/clipboard/clipboard.service';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
})
export class TransactionDetailComponent {
  @Input() transaction: ITransaction;

  constructor(
    public clipboardUtils: ClipboardService,
    public commonService: CommonService,
  ) {}
}
