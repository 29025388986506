import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Types
import { RoomCreate, RoomUpdate, UploadParams } from '../../../../shared/types/socket';
import { Room, RoomUsers } from '../../../../shared/types/tables';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

// Services
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { ToastService } from '../../../../core/services/toast/toast.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { CommonService } from '../../../../core/services/common/common.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../core/services/logger/logger.service';

// Utils
import { AttachmentUtils } from '../../../../core/utils/attachment/attachment.service';

@Component({
  selector: 'app-torgram-room-edit',
  templateUrl: './torgram-room-edit.component.html',
  styleUrls: ['./torgram-room-edit.component.scss'],
})
export class TorgramRoomEditComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() room: Room;

  public imagePreview: string;
  public uploadParams: UploadParams;
  public isLoadingRoom: boolean = false;

  public roomToEdit: Room;
  public availableUsers: RoomUsers[];
  public isNew: boolean;

  private roomType: string;
  private role: string;

  private roomCreatedSubscription: Subscription;
  private roomEditedSubscription: Subscription;
  private gotUploadParamsSubscription: Subscription;
  private roomAvailableUsersSubscription: Subscription;
  private errorSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslationService,
    private socketService: SocketService,
    private presentToast: ToastService,
    private modalService: ModalService,
    private common: CommonService,
    private attachment: AttachmentUtils,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  private get cannotSaveChanges(): boolean {
    return this.roomToEdit.name === undefined || this.roomToEdit.name.trim().length === 0;
  }

  ngOnInit(): void {
    this.isNew = this.room === undefined;

    if (this.room) {
      this.roomToEdit = {
        ...this.room,
        newUsers: [],
      };
    } else {
      this.roomToEdit = {
        name: undefined,
        description: undefined,
        reserved: false,
        byInvitation: false,
        historyAvailable: false,
        newUsers: [],
      };
    }

    this.imagePreview = null;

    this.networkService.pageNgOnInit(
      'TorgramRoomEditComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramRoomEditComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.roomCreatedSubscription?.unsubscribe();
    this.roomEditedSubscription?.unsubscribe();
    this.gotUploadParamsSubscription?.unsubscribe();
    this.roomAvailableUsersSubscription?.unsubscribe();
    this.errorSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  async saveChanges(save: boolean): Promise<void> {
    if (!this.cannotSaveChanges) {
      if (this.isNew) {
        const actionSheet = await this.actionSheetCtrl.create({
          header: this.translate.instant('TORGRAM.' + (save ? 'CONFIRMCREATION' : 'CANCELCREATION')),
          buttons: [
            {
              text: this.translate.instant('TORGRAM.YES'),
              role: 'confirm',
              handler: async () => {
                if (save) {
                  const createRoomParam = {
                    status: 'active',
                    name: this.roomToEdit.name,
                    description: this.roomToEdit.description,
                    reserved: this.roomToEdit.reserved,
                    historyAvailable: this.roomToEdit.historyAvailable,
                    attachments: this.attachment.attachments,
                    type: this.roomToEdit.byInvitation ? 'messaging' : this.roomType,
                    newUsers: this.roomToEdit.newUsers.map((user) => user.pk),
                  } as RoomCreate;

                  this.createRoom(createRoomParam);
                } else {
                  await this.dismissModal();
                }
              },
            },
            {
              text: this.translate.instant('TORGRAM.NO'),
              role: 'cancel',
            },
          ],
        });

        await actionSheet.present();

        const { role } = await actionSheet.onWillDismiss();

        this.role = role;
      } else {
        const actionSheet = await this.actionSheetCtrl.create({
          header: this.translate.instant('TORGRAM.' + (save ? 'CONFIRMUPDATE' : 'CANCELUPDATE')),
          buttons: [
            {
              text: this.translate.instant('TORGRAM.YES'),
              role: 'confirmSave',
              handler: async () => {
                if (save) {
                  const updateRoom = {
                    roomId: this.roomToEdit.id,
                  } as RoomUpdate;

                  ['name', 'description', 'reserved', 'historyAvailable'].map((key) => {
                    if (this.room[key] !== this.roomToEdit[key]) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      updateRoom[key] = this.roomToEdit[key];
                    }
                  });

                  if (this.attachment.attachments.length > 0) {
                    updateRoom['attachments'] = this.attachment.attachments;
                  }

                  this.updateRoom(updateRoom);
                } else {
                  await this.dismissModal();
                }
              },
            },
            {
              text: this.translate.instant('TORGRAM.NO'),
              role: 'cancel',
            },
          ],
        });

        await actionSheet.present();

        const { role } = await actionSheet.onWillDismiss();

        if (role === 'confirmSave' && !this.isLoadingRoom) {
          await this.dismissModal(role);
        }
      }
    }
  }

  async addAttachment(): Promise<void> {
    if (!this.isLoadingRoom) {
      this.imagePreview = null;

      await this.attachment.add('gallery', this.uploadParams, 'room-image').then((result) => {
        if ('imagePreview' in result) {
          const blob = result['imagePreview'] as string;

          this.imagePreview = blob;
        }
      });
    }
  }

  async dismissModal(role?: string): Promise<void> {
    await this.modal.dismiss(role);
    await this.modalService.dismissLast();
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramRoomEditComponent');
    }

    this.roomCreatedSubscription = this.socket.fromEvent(SocketEvents.ROOM_CREATE).subscribe({
      next: async (newRoom: Room) => {
        this.attachment.attachments = [];

        if (newRoom && this.role === 'confirm') {
          await this.common.torgramNavigationWithBaseHref(newRoom.roomType.description + '/' + newRoom.id);
          await this.dismissModal(this.role);
          this.isLoadingRoom = false;
        }
      },
    });

    this.roomEditedSubscription = this.socket.fromEvent(SocketEvents.ROOM_EDIT).subscribe({
      next: async (roomEdited: Room) => {
        this.attachment.attachments = [];

        if (roomEdited) {
          if (roomEdited.status === 'disabled') {
            await this.modalService.dismissAll();

            await this.common.torgramNavigationWithBaseHref();

            await this.presentToast.showWarningToast(
              this.translate.instant('COMMON.CAUTION'),
              this.translate.instant('TORGRAM.ROOM_DELETED'),
              'bottom',
              5000,
            );
          } else {
            await this.dismissModal('confirmSave');
          }
          this.isLoadingRoom = false;
        }
      },
    });

    this.gotUploadParamsSubscription = this.socket.fromEvent(SocketEvents.GET_UPLOAD_PARAMS).subscribe({
      next: (param: UploadParams) => {
        this.uploadParams = param;
      },
    });

    this.roomAvailableUsersSubscription = this.socket.fromEvent(SocketEvents.ROOM_AVAILABLE_USERS).subscribe({
      next: ({ availableUsers }: { availableUsers: RoomUsers[] }) => {
        this.availableUsers = availableUsers;
      },
    });

    this.errorSubscription = this.socket.fromEvent(SocketEvents.ERROR).subscribe({
      next: () => {
        this.isLoadingRoom = false;
      },
    });

    this.socket.emit(SocketEvents.GET_UPLOAD_PARAMS, {});

    if (this.isNew) {
      this.socket.emit(SocketEvents.ROOM_AVAILABLE_USERS, { roomId: this.room?.id ?? undefined });
    } else {
      this.availableUsers = undefined;
    }
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramRoomEditComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }

  private createRoom(createRoom: RoomCreate): void {
    this.isLoadingRoom = true;

    if (this.socketService.env === 'tor') {
      void this.presentToast.showOperationToast('', this.translate.instant('TORGRAM.CREATING_TOR_ROOM'));
    }

    this.socket.emit(SocketEvents.ROOM_CREATE, { ...createRoom });
  }

  private updateRoom(updateRoom: RoomUpdate): void {
    this.isLoadingRoom = true;

    if (this.socketService.env === 'tor') {
      void this.presentToast.showOperationToast('', this.translate.instant('TORGRAM.EDITING_TOR_ROOM'));
    }

    this.socket.emit(SocketEvents.ROOM_EDIT, { ...updateRoom });
  }
}
