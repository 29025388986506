import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Types
import { Key } from '../../../shared/types/key';
import { StorageKey } from '../../../shared/types/storage';

// Services
import { StorageService } from '../storage/storage.service';

@Injectable({ providedIn: 'root' })
export class KeysService {
  public fromLogin: boolean;
  public keys: Key[] = [];

  constructor(private storage: StorageService) {}

  get publicKey(): string | undefined {
    return this.keys?.find(({ deletable }) => !deletable)?.public ?? '';
  }

  get(): Promise<string | boolean> {
    return new Promise<string | boolean>((resolve, reject) => {
      this.storage.getFromStorage(StorageKey.Keys).subscribe({
        next: (keys: Key[]) => {
          if (keys === null) {
            this.keys = [];
            this.storage.setOnStorage(StorageKey.Keys, this.keys).subscribe({ next: () => resolve(true) });
          } else {
            this.keys = [...keys] as Key[];
            this.updateKeysInStorage().subscribe({ next: () => resolve('') });
          }
        },
        error: (error) => {
          if (error === null) {
            this.keys = [];
            this.storage.setOnStorage(StorageKey.Keys, this.keys).subscribe({ next: () => resolve(true) });
          } else {
            reject(error);
          }
        },
      });
    });
  }

  post(key: Key): Observable<Key[]> {
    this.keys.push(key);
    return this.storage.setOnStorage(StorageKey.Keys, this.keys);
  }

  patch(key: Key): void {
    const i = this.keys?.findIndex((k) => k.private === key.private);
    this.keys[i] = key;

    this.storage.setOnStorage(StorageKey.Keys, this.keys).subscribe();
  }

  deleteKey(publicKey: Key['public']): Observable<string> {
    return new Observable<string>((observer) => {
      const position: number = this.keys?.findIndex((key: Key) => key.public === publicKey);

      if (position === -1) {
        observer.error("La chiave non esiste nell'array delle chiavi esistenti");
      } else {
        this.keys.splice(position, 1);

        this.storage.setOnStorage(StorageKey.Keys, this.keys).subscribe({
          next: () => observer.next('Chiave eliminata con successo'),
        });
      }
    });
  }

  updateKeysInStorage(): Observable<Key[]> {
    return this.storage.setOnStorage(StorageKey.Keys, this.keys);
  }
}
