import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';

import { GatekeeperPageRoutingModule } from './gatekeeper-routing.module';
import { GatekeeperKeyPage } from './gatekeeper.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GatekeeperPageRoutingModule,
    TranslateModule.forChild(),
    QRCodeModule,
  ],
  declarations: [GatekeeperKeyPage],
})
export class GatekeeperKeyPageModule {}
