import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController, AlertInput, ModalController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Modals
import { QRCodeModalComponent } from '../../../../../../shared/components/qrcode-modal/qrcode-modal.component';

// Components
import { QRCodeScannerComponent } from '../../../../../../shared/components/qr-code-scanner/qr-code-scanner.component';

// Services
import { TranslationService } from '../../../../../../core/services/translation/translation.service';
import { LoggerService } from '../../../../../../core/services/logger/logger.service';
import { ModalService } from '../../../../../../core/services/modal/modal.service';
import { SocketService } from '../../../../../../core/services/socket/socket.service';
import { NetworkService } from '../../../../../../core/services/network/network.service';
import { KeysService } from '../../../../../../core/services/keys/keys.service';
import { SocketEvents } from '../../../../../../core/services/socket/socket-events';

// Types
import { Room } from '../../../../../../shared/types/tables';
import { PageNgOnInitCallBack } from '../../../../../../shared/types/common';
import { QrCodeData } from '../../../../../../shared/types/tor';

@Component({
  selector: 'app-torgram-room-qr-code-invitation',
  templateUrl: './torgram-room-qr-code-invitation.page.html',
  styleUrls: ['./torgram-room-qr-code-invitation.page.scss'],
})
export class TorgramRoomQrCodeInvitationPage implements OnInit, OnDestroy {
  @ViewChild('qrCodeScanner') qrCodeScanner: QRCodeScannerComponent;

  @Input() room!: Room;

  public stepper: number = 0;
  public readonly numberOfStep: number = 2;

  public qrData: string = '';
  public canContinue: boolean = true;
  public cameraError: boolean = false;
  public cameraErrorText: string;
  public waitingJoinOnRoom: boolean = true;
  public errorMessage: string | undefined = undefined;

  private roomNewJoinRequestQrCodeSubscription: Subscription;
  private errorSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private translate: TranslationService,
    private logger: LoggerService,
    private modalService: ModalService,
    private socketService: SocketService,
    private networkService: NetworkService,
    private keysService: KeysService,
  ) {}

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnInit(): void {
    this.stepper = 0;
    this.canContinue = true;

    const data: QrCodeData = {
      roomId: this.room.id,
      ownerPk: this.keysService.publicKey,
    };
    this.qrData = JSON.stringify(data);

    this.networkService.pageNgOnInit(
      'TorgramRoomQrCodeInvitationPage',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramRoomQrCodeInvitationPage', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.roomNewJoinRequestQrCodeSubscription?.unsubscribe();
    this.errorSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  async showQrCode(): Promise<void> {
    const modal = await this.modalController.create({
      component: QRCodeModalComponent,
      breakpoints: [0, 0.75],
      initialBreakpoint: 0.75,
      componentProps: {
        qrData: this.qrData,
      },
      canDismiss: true,
    });
    await modal.present();
  }

  async selectCamera(devices: MediaDeviceInfo[]): Promise<void> {
    const device =
      devices.length === 1 ? devices[0] : (JSON.parse(await this.chooseDevice(devices)) as MediaDeviceInfo);
    this.qrCodeScanner.startScan(device);
  }

  showCameraError(event: boolean, text: string): void {
    this.cameraError = event;
    this.cameraErrorText = text;
  }

  async chooseDevice(devices: MediaDeviceInfo[]): Promise<string> {
    const inputs: AlertInput[] = [];
    for (const device of devices) {
      inputs.push({
        name: 'camera',
        type: 'radio',
        label: device.label,
        value: JSON.stringify(device),
      });
    }

    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: this.translate.instant('COMMON.CAMERA'),
      cssClass: 'proceed-cancel-alert',
      backdropDismiss: false,
      inputs,
      buttons: [
        {
          text: this.translate.instant('COMMON.CONFIRM'),
          role: 'confirm',
          handler: (value) => value !== undefined,
        },
      ],
    });
    await alert.present();

    const { data }: { data?: { values: string } } = await alert.onDidDismiss();
    return data.values;
  }

  reOpenCamera(): void {
    if (this.cameraErrorText === 'TORGRAM.BAD_QRCODE') {
      this.cameraErrorText = '';
      this.cameraError = false;
    }
  }

  scanCompleted(qrData: string): void {
    try {
      const senderData = JSON.parse(qrData) as QrCodeData;
      const mineData = JSON.parse(this.qrData) as QrCodeData;

      if (senderData.roomId === mineData.roomId && senderData.ownerPk === mineData.ownerPk) {
        this.socket.emit(SocketEvents.ROOM_JOIN_FROM_QR_CODE, senderData);
        this.stepper += 1;
        this.canContinue = true;
      }
    } catch (e) {
      this.logger.consoleError('TorgramRoomQrCodeInvitationPage', 'scanCompleted', e);
      this.showCameraError(true, 'TORGRAM.BAD_QRCODE');
    }
  }

  async cancelOperation(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('COMMON.CAUTION'),
      message: this.translate.instant('TORGRAM.CANCEL_OPERATION'),
      cssClass: 'confirm-cancel-alert',
      buttons: [
        { text: this.translate.instant('COMMON.NO'), role: 'cancel' },
        {
          text: this.translate.instant('COMMON.YES'),
          role: 'confirm',
          handler: async () => {
            await this.closeDialog();
          },
        },
      ],
    });

    await alert.present();
  }

  increaseStep(): void {
    this.stepper = this.stepper + 1;

    if (this.stepper === 1) {
      this.canContinue = false;
    }
  }

  async closeDialog(): Promise<void> {
    this.socket.emit(SocketEvents.JOIN_ROOMS, {});

    await this.modalService.dismissLast();
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramRoomQrCodeInvitationPage');
    }

    this.roomNewJoinRequestQrCodeSubscription = this.socket.fromEvent(SocketEvents.ROOM_JOIN_FROM_QR_CODE).subscribe({
      next: () => {
        this.waitingJoinOnRoom = false;
      },
    });

    this.errorSubscription = this.socket.fromEvent(SocketEvents.ERROR).subscribe({
      next: (params: { message: string }) => {
        this.errorMessage = params.message;
      },
    });
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramRoomQrCodeInvitationPage', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }
}
