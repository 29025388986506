import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Guard
import { ApplicationGuard } from './core/guards/application/application.guard';
import { PwaGuard } from './core/guards/pwa/pwa.guard';
import { ConnectionGuard } from './core/guards/connection/connection.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'keys',
    loadChildren: () => import('./modules/keys/keys.module').then((m) => m.KeysModule),
  },
  {
    path: 'wallet',
    loadChildren: () => import('./modules/wallet/wallet.module').then((m) => m.WalletModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'login-rinascimento',
    loadChildren: () =>
      import('./modules/features/log-in-rinascimento/log-in-rinascimento.module').then(
        (m) => m.LogInRinascimentoPageModule,
      ),
  },
  {
    path: 'torgram',
    loadChildren: () => import('./modules/torgram/torgram.module').then((m) => m.TorgramModule),
  },
  {
    path: 'category-page',
    loadChildren: () => import('./modules/category-page/category-page.module').then((m) => m.CategoryPageModule),
  },
  {
    path: 'full-news-page/:CATEGORY/:ID',
    loadChildren: () =>
      import('./modules/home/pages/full-news-page/full-news-page.module').then((m) => m.FullNewsPagePageModule),
  },
  {
    path: 'associated-portal',
    loadChildren: () =>
      import('./modules/home/pages/associated-portal/associated-portal.module').then(
        (m) => m.AssociatedPortalPageModule,
      ),
  },
  {
    path: 'aaa',
    loadChildren: () => import('./modules/home/pages/aaa/aaa.module').then((m) => m.AaaPageModule),
  },
];

const MODULES_PAGE = ['torgram', 'wallet', 'keys', 'home', 'category-page'];

routes.map((route) => {
  if (!route.canActivate) {
    route.canActivate = [];
  }

  if (!MODULES_PAGE.includes(route.path)) {
    route.canActivate = [ConnectionGuard, PwaGuard, ApplicationGuard];
  }
});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
