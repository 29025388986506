import { Injectable } from '@angular/core';

// Types
import { ILoggedUser } from '../../../shared/types';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public loggedUser: ILoggedUser;
  public userId: number;

  constructor() {}
}
