<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border ion-no-padding">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="1" class="ion-justify-content-center ion-align-items-center ion-no-padding">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="7" class="ion-justify-content-center ion-align-items-center ion-no-padding"> </ion-col>

      <ion-col size="4" class="ion-justify-content-end ion-align-items-center ion-no-padding">
        @if (room?.members && room?.members[0]?.owner) {
          <ion-button size="small" fill="clear" color="light" (click)="openInfoModal()" [disabled]="!internetAvailable">
            {{ 'TORGRAM.EDIT' | translate }}
          </ion-button>
        }
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  @if (room) {
    <ion-avatar class="roomImage ion-justify-content-center ion-align-items-center ion-padding">
      @if (roomImage !== null) {
        <img [src]="roomImage" />
      } @else {
        <ion-spinner name="dots"></ion-spinner>
      }
    </ion-avatar>

    <ion-row class="ion-no-padding">
      <ion-col class="ion-text-center ion-no-padding">
        <h1>{{ room?.name }}</h1>
        <ion-label>{{ room?.description }}</ion-label>
      </ion-col>
    </ion-row>

    <br />
    <ion-list class="ion-margin-top border-radius" lines="full">
      <ion-item [detail]="internetAvailable" (click)="openModal('owner')" [disabled]="!internetAvailable">
        <div class="boxShapeOwner ion-justify-content-center ion-align-items-center ion-margin-end">
          <ion-icon name="medal" size="small"></ion-icon>
        </div>

        <ion-label> {{ 'TORGRAM.OWNERS' | translate }} </ion-label>
        <not-available-offline> </not-available-offline>
      </ion-item>

      <ion-item [detail]="internetAvailable" (click)="openModal('moderator')" [disabled]="!internetAvailable">
        <div class="boxShapeAdmin ion-justify-content-center ion-align-items-center ion-margin-end">
          <ion-icon name="ribbon" size="small"></ion-icon>
        </div>

        <ion-label> {{ 'TORGRAM.MODERATORS' | translate }} </ion-label>
        <not-available-offline> </not-available-offline>
      </ion-item>

      @if (room.roomType.id === 1) {
        <ion-item [detail]="internetAvailable" (click)="openModal('contributor')" [disabled]="!internetAvailable">
          <div class="boxShapeUsers ion-justify-content-center ion-align-items-center ion-margin-end">
            <ion-icon name="book" size="small"></ion-icon>
          </div>

          <ion-label> {{ 'TORGRAM.CONTRIBUTORS' | translate }} </ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>
      }

      <ion-item [detail]="internetAvailable" (click)="openModal('reader')" [disabled]="!internetAvailable">
        <div class="boxShapeReader ion-justify-content-center ion-align-items-center ion-margin-end">
          <ion-icon name="people" size="small"></ion-icon>
        </div>

        <ion-label> {{ 'TORGRAM.USERS' | translate }}</ion-label>
        <not-available-offline> </not-available-offline>
      </ion-item>

      @if (room?.members && (room?.members[0].moderator || room?.members[0].owner)) {
        @if (room.reserved) {
          <ion-item [detail]="internetAvailable" (click)="openModal('pending')" [disabled]="!internetAvailable">
            <div class="boxShapePending ion-justify-content-center ion-align-items-center ion-margin-end">
              <ion-icon name="hourglass-outline" size="small"></ion-icon>
            </div>

            <ion-label> {{ 'TORGRAM.WAITINGFORAPPROVAL' | translate }}</ion-label>
            <not-available-offline> </not-available-offline>
          </ion-item>
        }

        <ion-item [detail]="internetAvailable" (click)="addNewUsers()" [disabled]="!internetAvailable">
          <div class="boxShapePending ion-justify-content-center ion-align-items-center ion-margin-end">
            <ion-icon name="add-outline" size="small"></ion-icon>
          </div>

          <ion-label> {{ 'TORGRAM.ADD_NEW_USERS' | translate }}</ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>
      }
    </ion-list>

    <br />

    <ion-label class="ion-text-bold">{{ 'TORGRAM.UTILITIES' | translate }}</ion-label>
    <ion-list class="ion-margin-top border-radius" lines="full">
      <ion-item [detail]="internetAvailable" (click)="openSearchModal()" [disabled]="!internetAvailable">
        <div class="boxShapeSearch ion-justify-content-center ion-align-items-center ion-margin-end">
          <ion-icon name="search-outline" size="small"></ion-icon>
        </div>

        <ion-label> {{ 'TORGRAM.SEARCH' | translate }}</ion-label>
        <not-available-offline> </not-available-offline>
      </ion-item>

      @if (room?.members[0].owner) {
        <ion-item
          [detail]="internetAvailable"
          (click)="deleteMessages()"
          [disabled]="!internetAvailable || roomMessagesLength == 0"
        >
          <div class="boxShapeDelete ion-justify-content-center ion-align-items-center ion-margin-end">
            <ion-icon name="chatbox-outline" size="small"></ion-icon>
          </div>

          <ion-label translate> TORGRAM.DELETE_MESSAGES </ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>

        <ion-item [detail]="internetAvailable" (click)="openModal('unsubscribed')" [disabled]="!internetAvailable">
          <div class="boxShapeDelete ion-justify-content-center ion-align-items-center ion-margin-end">
            <ion-icon name="hand-left-outline" size="small"></ion-icon>
          </div>

          <ion-label translate> TORGRAM.BANNED_USERS </ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>
      }
    </ion-list>
    <br />

    @if (room?.members && room?.members[0].owner) {
      <ion-label class="ion-text-bold">{{ 'TORGRAM.INVITE' | translate }}</ion-label>

      <ion-list class="ion-margin-top border-radius" lines="full">
        <ion-item [detail]="internetAvailable" (click)="openLinkModal()" [disabled]="!internetAvailable">
          <div class="boxShapeLink ion-justify-content-center ion-align-items-center ion-margin-end">
            <ion-icon name="link-outline" size="small"></ion-icon>
          </div>

          <ion-label> {{ 'TORGRAM.INVITATIONLINK' | translate }}</ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>

        <ion-item [detail]="internetAvailable" (click)="openQrCodeModal()" [disabled]="!internetAvailable">
          <div class="boxShapeLink ion-justify-content-center ion-align-items-center ion-margin-end">
            <ion-icon name="qr-code-outline" size="small"></ion-icon>
          </div>

          <ion-label> {{ 'TORGRAM.INVITATIONQRCODE' | translate }}</ion-label>
          <not-available-offline> </not-available-offline>
        </ion-item>
      </ion-list>

      <br />
    }

    <ion-label class="ion-text-bold">{{ 'TORGRAM.SETTINGS' | translate }}</ion-label>

    <ion-list class="ion-margin-top border-radius" lines="full">
      <ion-item [detail]="internetAvailable" (click)="dismissRoom()" [disabled]="!internetAvailable">
        <div class="boxShapeDelete ion-justify-content-center ion-align-items-center ion-margin-end">
          <ion-icon
            [name]="room?.members && room?.members[0].owner ? 'trash-outline' : 'exit-outline'"
            size="small"
          ></ion-icon>
        </div>

        <ion-label translate>
          {{ room?.members && room?.members[0].owner ? 'TORGRAM.DELETE' : 'TORGRAM.LEAVE' }}
        </ion-label>
        <not-available-offline> </not-available-offline>
      </ion-item>
    </ion-list>

    <hr />
  } @else {
    <div class="ion-full-height ion-align-items-center ion-justify-content-center">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  }
</ion-content>
