import { Injectable } from '@angular/core';
import { IonicSafeString, ToastController } from '@ionic/angular';

type ToastPosition = 'top' | 'bottom' | 'middle';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private isFired: boolean = false;
  private toast: HTMLIonToastElement;

  constructor(private toastController: ToastController) {}

  async showToast(
    header: string,
    message: string,
    position: ToastPosition,
    color: string,
    duration: number = 2000,
    cssClass: string = 'ion-text-center',
  ): Promise<void> {
    if (!this.isFired) {
      this.isFired = true;
      this.toast = await this.toastController.create({
        header,
        message,
        duration,
        position,
        color,
        cssClass,
      });

      await this.toast.present();
      await this.toast.onDidDismiss().then(() => {
        this.isFired = false;
      });
    }
  }

  async showOperationToast(header: string, message: string, position: ToastPosition = 'bottom'): Promise<void> {
    await this.showToast(header, message, position, 'fri-light', 5000);
  }

  async showErrorToast(header: string, message: string, position: ToastPosition = 'top'): Promise<void> {
    await this.showToast(header, message, position, 'danger');
  }

  async showSuccessToast(header: string, message: string, position: ToastPosition = 'top'): Promise<void> {
    await this.showToast(header, message, position, 'success');
  }

  async showWarningToast(
    header: string,
    message: string,
    position: ToastPosition = 'top',
    duration: number,
  ): Promise<void> {
    await this.showToast(header, message, position, 'warning', duration);
  }

  async showDismissableToast(
    message: string,
    position: ToastPosition = 'top',
    color: string = 'fri-light',
  ): Promise<void> {
    if (!this.isFired) {
      this.isFired = true;
      this.toast = await this.toastController.create({
        message: new IonicSafeString(message).value,
        position,
        color,
        animated: true,
        buttons: [{ icon: 'close-outline', role: 'cancel', handler: () => {} }],
      });

      await this.toast.present();
      await this.toast.onDidDismiss().then(() => {
        this.isFired = false;
      });
    }
  }

  async dismissToast(): Promise<void> {
    await this.toast?.dismiss();
    this.isFired = false;
  }
}
