import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CustomToastPage } from './custom-toast.page';
import { CustomToastPageRoutingModule } from './custom-toast-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, CustomToastPageRoutingModule, TranslateModule.forChild()],
  declarations: [CustomToastPage],
})
export class CustomToastPageModule {}
