import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import jsQR, { QRCode } from 'jsqr';

// Services
import { StorageService } from '../storage/storage.service';

// Types
import { StorageKey } from '../../../shared/types/storage';

@Directive()
@Injectable({ providedIn: 'root' })
export class QRCodeReaderService {
  @Output() scanEnded: EventEmitter<QRCode> = new EventEmitter<QRCode>();

  private stream: MediaStream;
  private videoElement: HTMLVideoElement;

  private canvasElement: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;

  private scanActive: boolean;
  private hasEmitted: boolean;

  constructor(private storage: StorageService) {}

  selectCamera(): Promise<{ chosen: boolean; device: string }> {
    return new Promise<{ chosen: boolean; device: string }>(() => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => devices.filter((d) => d.kind === 'videoinput'))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .catch((error) => this.storage.setOnStorage(StorageKey.CameraLog, 'selectCamera: '.concat(error)).subscribe());
    });
  }

  async startScan(videoElement: HTMLVideoElement, canvasElement: HTMLCanvasElement): Promise<void> {
    const camera = await this.selectCamera();

    if (camera.chosen) {
      this.hasEmitted = false;

      this.videoElement = videoElement;
      this.canvasElement = canvasElement;
      this.canvasContext = this.canvasElement.getContext('2d');

      this.scanActive = true;

      const isPortrait = window.innerHeight > window.innerWidth;

      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: camera.device,
            width: isPortrait ? 480 : 640,
            height: isPortrait ? 640 : 480,
          },
        })
        .then((stream) => {
          if (this.scanActive) {
            this.stream = stream;
            this.videoElement.srcObject = stream;
            this.videoElement.setAttribute('playsinline', 'playsinline');
            void this.videoElement.play();

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            requestAnimationFrame(this.scan.bind(this));
          } else {
            this.stopScan();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .catch((error) => this.storage.setOnStorage(StorageKey.CameraLog, 'startScan: '.concat(error)).subscribe());
    }
  }

  stopScan(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }

    this.scanActive = false;
    this.stream = null;
  }

  private scan() {
    if (this.videoElement && this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      this.storage
        .setOnStorage(
          StorageKey.CameraLog,
          'scan: '.concat(this.videoElement.videoHeight.toString(), ' / ', this.videoElement.videoWidth.toString()),
        )
        .subscribe();

      this.canvasElement.height = this.videoElement.videoHeight;
      this.canvasElement.width = this.videoElement.videoWidth;
      this.canvasContext.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
      const imageData = this.canvasContext.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);

      new Promise<QRCode>((resolve) => {
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });
        resolve(code);
      })
        .then((code) => {
          if (code && !this.hasEmitted) {
            this.hasEmitted = true;
            this.stopScan();
            this.scanEnded.emit(code);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .catch((error) => this.storage.setOnStorage(StorageKey.CameraLog, 'scan: '.concat(error)).subscribe());

      if (this.scanActive) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        requestAnimationFrame(this.scan.bind(this));
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      requestAnimationFrame(this.scan.bind(this));
    }
  }
}
