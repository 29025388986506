<ion-header class="ion-no-border ion-padding">
  <ion-toolbar class="ion-no-border ion-no-padding">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="3" class="ion-justify-content-flex-start ion-align-items-center">
        <ion-buttons>
          <ion-back-button color="light" text="" (click)="dismissModal()"></ion-back-button>
        </ion-buttons>
      </ion-col>

      <ion-col size="5" class="ion-justify-content-center ion-align-items-center"> </ion-col>

      <ion-col size="4" class="ion-text-right"></ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="wrapper">
    <div></div>

    <ion-row class="ion-padding wrapper-link">
      <h1>{{ 'TORGRAM.JOIN_ROOM' | translate }}</h1>

      <ion-col size="12">
        <ion-textarea
          rows="1"
          [(ngModel)]="linkId"
          placeholder="{{ 'TORGRAM.LINK_ID' | translate }}"
          [disabled]="isLoadingJoining"
        ></ion-textarea>
      </ion-col>

      <ion-col size="12">
        @if (!isLoadingJoining) {
          <ion-button
            class="ion-full-width"
            size="large"
            shape="round"
            color="primary"
            (click)="joinRoom()"
            [disabled]="!internetAvailable"
          >
            {{ 'TORGRAM.JOIN' | translate }}
          </ion-button>
        } @else {
          <ion-button
            class="ion-full-width"
            size="large"
            shape="round"
            color="primary"
            [disabled]="!internetAvailable || isLoadingJoining"
          >
            {{ 'TORGRAM.JOINING' | translate }}
            <ion-spinner name="lines-small"></ion-spinner>
          </ion-button>
        }
      </ion-col>

      @if (socketErrorMessage) {
        <ion-label [innerHTML]="'TORGRAM.ERRORS.' + socketErrorMessage | translate" color="danger"></ion-label>
      }
    </ion-row>
  </div>
</ion-content>
