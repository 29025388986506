import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Types
import { ILoggedUser } from '../../../shared/types';
import { ISendTransaction, ITransaction } from '../../../shared/types/wallet';
import { IWebServiceError } from '../../../shared/types/webservices';
import { StorageKey } from '../../../shared/types/storage';

// Services
import { WebServiceCall } from '../../services/webservice-caller/webservice-caller.service';
import { StorageService } from '../../services/storage/storage.service';

import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WalletService {
  private WEBSERVICES_MODE = environment.WEBSERVICES_MODE as 'ONLINE' | 'LOCAL';

  private WEB_SERVICE_URL = environment.WEBSERVICES[this.WEBSERVICES_MODE].WEB_SERVICE_URL;

  constructor(
    private webService: WebServiceCall,
    private storage: StorageService,
  ) {}

  getAvailability(): Observable<{ FRI_TOKEN: number } | IWebServiceError> {
    return this.storage.getFromStorage(StorageKey.LoggedUser).pipe(
      switchMap(({ ID, token }: ILoggedUser) =>
        this.webService.postCallExternal(this.WEB_SERVICE_URL + '/availability', {
          USER_ID: ID,
          AUTHORIZATION_TOKEN: 'Bearer ' + token,
        }),
      ),
    ) as Observable<{ FRI_TOKEN: number } | IWebServiceError>;
  }

  getTransactionsHistory(): Observable<ITransaction[] | IWebServiceError> {
    return this.storage
      .getFromStorage(StorageKey.LoggedUser)
      .pipe(
        switchMap(({ ID }: ILoggedUser) =>
          this.webService.getCall(this.WEB_SERVICE_URL.concat('/transactionsHistory?ID_USER=', ID.toString())),
        ),
      ) as Observable<ITransaction[] | IWebServiceError>;
  }

  saveTransaction(
    RECEIVER: ISendTransaction,
    SENDER: ISendTransaction,
  ): Observable<{ transactionSign: string } | IWebServiceError> {
    return this.storage.getFromStorage(StorageKey.LoggedUser).pipe(
      switchMap(({ token }: ILoggedUser) =>
        this.webService.putCallExternal(this.WEB_SERVICE_URL + '/saveTransaction', {
          AUTHORIZATION_TOKEN: 'Bearer ' + token,
          RECEIVER,
          SENDER,
        }),
      ),
    ) as Observable<{ transactionSign: string } | IWebServiceError>;
  }
}
