import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Room, RoomType } from '../../types/tables';

@Pipe({ name: 'forward' })
export class ForwardPipe implements PipeTransform {
  transform(items: Room[], { id }: RoomType): Room[] {
    if (id && Array.isArray(items)) {
      return items.filter((item) => {
        return item.roomType.id === id;
      });
    } else {
      return items;
    }
  }
}
