import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Types
import { Room, RoomUsers } from '../../../shared/types/tables';

// Components
import { TorgramRoomInfoComponent } from '../../../modules/torgram/components/torgram-room-info/torgram-room-info.component';
import { TorgramUserListComponent } from '../../../modules/torgram/components/torgram-user-list/torgram-user-list.component';
import { TorgramRoomEditComponent } from '../../../modules/torgram/components/torgram-room-edit/torgram-room-edit.component';
import { TorgramLinkComponent } from '../../../modules/torgram/components/torgram-link/torgram-link.component';
import { TorgramSearchGroupComponent } from '../../../modules/torgram/components/torgram-search-group/torgram-search-group.component';
import { TorgramCustomLinkComponent } from '../../../modules/torgram/components/torgram-custom-link/torgram-custom-link.component';
import { TorgramPromoteListComponent } from '../../../modules/torgram/components/torgram-promote-list/torgram-promote-list.component';
import { TorgramJoinRoomComponent } from '../../../modules/torgram/components/torgram-join-room/torgram-join-room.component';
import { TorgramRoomQrCodeInvitationPage } from '../../../modules/torgram/modals/torgram-room-qr-code-invitation/pages/torgram-room-qr-code-invitation/torgram-room-qr-code-invitation.page';
import { TorgramJoinRoomQrCodePage } from '../../../modules/torgram/modals/torgram-join-room-qr-code/pages/torgram-join-room-qr-code/torgram-join-room-qr-code.page';
import { TorgramRoomAddUsersPage } from '../../../modules/torgram/modals/torgram-room-add-users/pages/torgram-room-add-users/torgram-room-add-users.page';

@Injectable({ providedIn: 'root' })
export class ModalService {
  modalInstances: HTMLIonModalElement[] = [];

  constructor(public modalCtrl: ModalController) {}

  storeModal(modal: HTMLIonModalElement): void {
    if (!this.modalInstances) {
      this.modalInstances = [];
    }

    this.modalInstances.push(modal);
  }

  async dismissAll(): Promise<void> {
    await Promise.all(
      this.modalInstances.map(async (modal) => {
        await modal.dismiss();
      }),
    );
  }

  async dismissLast(): Promise<void> {
    const lastIndex = this.modalInstances.length - 1;

    if (this.modalInstances[lastIndex]) {
      await this.modalInstances[lastIndex].dismiss();
    }

    this.modalInstances.splice(lastIndex, 1);
  }

  async openTorgramRoomInfoComponent(room: Room, roomMessagesLength: number): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TorgramRoomInfoComponent,
      componentProps: {
        room,
        roomMessagesLength,
      },
    });

    this.storeModal(modal);
    void modal.present();
  }

  async openTorgramUserListComponent(role: string, room: Room): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TorgramUserListComponent,
      componentProps: {
        role,
        room,
      },
    });

    this.storeModal(modal);
    void modal.present();
  }

  async openTorgramRoomEditComponent(
    room?: Room,
    roomType?: string,
    isNew: boolean = false,
  ): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramRoomEditComponent,
      componentProps: {
        room,
        roomType,
        isNew,
      },
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }

  async openTorgramJoinRoom(): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramJoinRoomComponent,
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }

  async openTorgramLinkComponent(room: Room): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TorgramLinkComponent,
      componentProps: {
        room,
      },
    });

    this.storeModal(modal);
    void modal.present();
  }

  async openTorgramCustomLinkComponent(room: Room): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramCustomLinkComponent,
      componentProps: {
        room,
      },
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }

  async openTorgramSearchGroupComponent(
    room: Room = undefined,
    onlyMessages: boolean = false,
  ): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramSearchGroupComponent,
      componentProps: {
        onlyMessages,
        room,
      },
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }

  async openTorgramPromotionComponent(role: string, room: Room): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TorgramPromoteListComponent,
      componentProps: {
        role,
        room,
      },
    });

    this.storeModal(modal);

    void modal.present();
  }

  async openTorgramRoomQrCodeJoin(room?: Room): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramRoomQrCodeInvitationPage,
      componentProps: {
        room,
      },
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }

  async openTorgramJoinRoomQrCode(): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramJoinRoomQrCodePage,
      componentProps: {},
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }

  async openTorgramAddUsersToRoom(room?: Room, availableUsers?: RoomUsers[]): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: TorgramRoomAddUsersPage,
      componentProps: {
        room,
        availableUsers,
      },
    });

    this.storeModal(modal);
    void modal.present();

    return modal;
  }
}
