import { Injectable } from '@angular/core';

// Package.json
import packageJson from '../../../../../package.json';

import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class VersionService {
  public VERSION: string = packageJson.version;
  public VERSION_CODE: string = environment.VERSION_CODE;

  constructor() {}
}
