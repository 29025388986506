<ion-header>
  <ion-toolbar color="fri-dark">
    <ion-img src="./assets/logo/fri-horizontal-blue.svg"></ion-img>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    @for (p of functionalities; track $index) {
      @if (
        p.show === 'always' ||
        (p.show === 'logged' && sessionService.IS_LOGGED) ||
        (p.show === 'not-logged' && !sessionService.IS_LOGGED)
      ) {
        <ion-menu-toggle auto-hide="false">
          @if (p.url) {
            <ion-item
              (click)="utilities.collapsed = true; p.click()"
              routerDirection="root"
              [routerLink]="[p.url]"
              [detail]="p.detail || !(!p.availableOffline && !internetAvailable)"
              routerLinkActive="selected"
              [disabled]="!p.availableOffline && !internetAvailable"
            >
              <ion-icon
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
              ></ion-icon>
              <ion-label color="fri-dark" class="ion-text-uppercase">{{ p.title | translate }}</ion-label>
            </ion-item>
          }

          @if (!p.url) {
            <ion-item
              (click)="utilities.collapsed = true; p.click()"
              [detail]="p.detail || !(!p.availableOffline && !internetAvailable)"
              [disabled]="!p.availableOffline && !internetAvailable"
            >
              <ion-icon
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
              ></ion-icon>
              <ion-label color="fri-dark" class="ion-text-uppercase">{{ p.title | translate }}</ion-label>

              @if (!p.availableOffline && !internetAvailable) {
                <not-available-offline></not-available-offline>
              }
            </ion-item>
          }
        </ion-menu-toggle>
      }
    }
  </ion-list>
</ion-content>

<ion-footer>
  <ion-accordion-group class="ion-border-bottom">
    <ion-accordion>
      <ion-item slot="header">
        <ion-label class="ion-text-uppercase" translate>COMMON.UTILITIES</ion-label>
      </ion-item>
      <ion-list slot="content">
        @for (p of utilities.items; track $index) {
          <ion-menu-toggle>
            @if (p.url) {
              <ion-item
                class="collapsable"
                color="light"
                [routerLink]="[p.url]"
                routerLinkActive="selected"
                [detail]="p.detail || !(!p.availableOffline && !internetAvailable)"
                [lines]="$index + 1 === utilities.items?.length ? 'full' : 'inset'"
                (click)="utilities.collapsed = true"
                [disabled]="!p.availableOffline && !internetAvailable"
              >
                <ion-icon
                  slot="start"
                  [ios]="p.icon + '-outline'"
                  [md]="p.icon + '-sharp'"
                  [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
                ></ion-icon>
                <ion-label color="fri-dark" class="ion-text-uppercase">{{ p.title | translate }}</ion-label>

                @if (!p.availableOffline && !internetAvailable) {
                  <not-available-offline></not-available-offline>
                }
              </ion-item>
            } @else {
              <ion-item
                class="collapsable"
                color="light"
                [detail]="p.detail || !(!p.availableOffline && !internetAvailable)"
                [lines]="$index + 1 === utilities.items?.length ? 'full' : 'inset'"
                (click)="utilities.collapsed = true; p.click()"
                [disabled]="!p.availableOffline && !internetAvailable"
              >
                <ion-icon
                  slot="start"
                  [ios]="p.icon + '-outline'"
                  [md]="p.icon + '-sharp'"
                  [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
                ></ion-icon>
                <ion-label color="fri-dark" class="ion-text-uppercase">{{ p.title | translate }}</ion-label>

                @if (!p.availableOffline && !internetAvailable) {
                  <not-available-offline></not-available-offline>
                }
              </ion-item>
            }
          </ion-menu-toggle>
        }
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>

  <ion-list>
    <ion-menu-toggle>
      @for (p of footer; track $index) {
        @if (p.url) {
          @if (
            p.show === 'always' ||
            (p.show === 'logged' && sessionService.IS_LOGGED) ||
            (p.show === 'not-logged' && !sessionService.IS_LOGGED)
          ) {
            <ion-item
              (click)="utilities.collapsed = true; p.click()"
              [routerLink]="p.url"
              [lines]="$index + 1 === footer?.length ? 'full' : 'inset'"
              [disabled]="!p.availableOffline && !internetAvailable"
            >
              <ion-icon
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
              ></ion-icon>
              <ion-label color="fri-dark" class="ion-text-uppercase">{{ p.title | translate }}</ion-label>

              @if (!p.availableOffline && !internetAvailable) {
                <not-available-offline></not-available-offline>
              }
            </ion-item>
          }
        } @else {
          @if (
            p.show === 'always' ||
            (p.show === 'logged' && sessionService.IS_LOGGED) ||
            (p.show === 'not-logged' && !sessionService.IS_LOGGED)
          ) {
            <ion-item
              (click)="utilities.collapsed = true; p.click()"
              [lines]="$index + 1 === footer?.length ? 'full' : 'inset'"
              [disabled]="!p.availableOffline && !internetAvailable"
            >
              <ion-icon
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
                [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
              ></ion-icon>
              <ion-label color="fri-dark" class="ion-text-uppercase">{{ p.title | translate }}</ion-label>

              @if (!p.availableOffline && !internetAvailable) {
                <not-available-offline></not-available-offline>
              }
            </ion-item>
          }
        }
      }
    </ion-menu-toggle>

    <ion-item>
      <ion-label>FRI APP - V {{ versionService.VERSION }} ({{ versionService.VERSION_CODE }})</ion-label>
      <ion-icon
        slot="end"
        name="wifi-outline"
        [size]="commonService.zoomMode === 'a' ? 'normal' : 'large'"
        [color]="!internetAvailable ? 'danger' : 'fri-bluette'"
      ></ion-icon>
    </ion-item>
  </ion-list>
</ion-footer>
