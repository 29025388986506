import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

// Types
import { Room, RoomType } from '../../../../shared/types/tables';
import { GeneralSearchParams } from '../../../../shared/types/socket';
import { PageNgOnInitCallBack } from '../../../../shared/types/common';

// Services
import { SocketService } from '../../../../core/services/socket/socket.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import { SocketEvents } from '../../../../core/services/socket/socket-events';
import { LoggerService } from '../../../../core/services/logger/logger.service';

@Component({
  selector: 'app-torgram-search-group',
  templateUrl: './torgram-search-group.component.html',
  styleUrls: ['./torgram-search-group.component.scss'],
})
export class TorgramSearchGroupComponent implements OnInit, OnDestroy {
  @Input() modal!: IonModal;
  @Input() onlyMessages?: boolean = false;
  @Input() room?: Room;

  public searchBoxTxt: string = '';
  public descriptionSelected: 'channel' | 'chat' | 'messages' = 'channel';
  public filteredList: Room[];
  public rooms: Room[];
  public isLoadingRooms: boolean;

  private generalSearchedSubscription: Subscription;
  private roomJoinedSubscription: Subscription;
  private searchedMessageSubscription: Subscription;
  private socketChangedSubscription: Subscription;

  constructor(
    private socketService: SocketService,
    private modalService: ModalService,
    private networkService: NetworkService,
    private logger: LoggerService,
  ) {}

  get isLoading(): boolean {
    return !this.onlyMessages ? this.filteredList === undefined : this.rooms === undefined;
  }

  get searchCategories(): RoomType[] {
    return [
      { label: 'TORGRAM.CHANNELS', id: 1, description: 'channel' },
      { label: 'TORGRAM.CHAT', id: 2, description: 'chat' },
      { label: 'TORGRAM.MESSAGES', id: 3, description: 'messages' },
    ] as RoomType[];
  }

  get internetAvailable(): boolean {
    return this.networkService.connected;
  }

  get isJoiningStarted(): boolean {
    return this.socketService.isJoiningStarted;
  }

  private get socket(): Socket {
    return this.socketService.socket;
  }

  ngOnInit(): void {
    if (this.onlyMessages) {
      this.descriptionSelected = 'messages';
    }

    this.networkService.pageNgOnInit(
      'TorgramSearchGroupComponent',
      this.synchronize.bind(this) as PageNgOnInitCallBack,
      this.notConnectedCallback.bind(this) as PageNgOnInitCallBack,
    );

    this.socketChangedSubscription = this.socketService.socketChanged.subscribe({
      next: async () => {
        this.logger.consoleLog('TorgramSearchGroupComponent', 'socketChanged');
        this.ngOnDestroy(false);
        await this.synchronize(false);
      },
    });
  }

  ngOnDestroy(removeListeners: boolean = true): void {
    this.generalSearchedSubscription?.unsubscribe();
    this.roomJoinedSubscription?.unsubscribe();
    this.searchedMessageSubscription?.unsubscribe();

    if (removeListeners) {
      this.socketChangedSubscription?.unsubscribe();
    }
  }

  handleInput(): void {
    this.filteredList = [];
    this.getRooms();
  }

  async dismissModal(data?: string): Promise<void> {
    await this.modal.dismiss(data);
    await this.modalService.dismissLast();
  }

  private getRooms(): void {
    this.filteredList = undefined;
    this.isLoadingRooms = true;

    const params = {
      type: this.descriptionSelected,
      filter: this.searchBoxTxt,
    } as GeneralSearchParams;

    if (this.onlyMessages) {
      params.roomId = this.room.id;
    }

    this.socket.emit(this.onlyMessages ? SocketEvents.SEARCH_MESSAGE : SocketEvents.GENERAL_SEARCH, params);
  }

  private async synchronize(initializeSocket: boolean = true): Promise<void> {
    if (initializeSocket) {
      await this.socketService.initSocket('TorgramSearchGroupComponent');
      this.filteredList = undefined;
    }

    this.roomJoinedSubscription = this.socket.fromEvent(SocketEvents.JOIN_ROOMS).subscribe({
      next: (newRooms: Room[]) => {
        this.rooms = newRooms;
        this.isLoadingRooms = false;
      },
    });

    this.generalSearchedSubscription = this.socket.fromEvent(SocketEvents.GENERAL_SEARCH).subscribe({
      next: (results: Room[]) => {
        this.filteredList = results;
        this.isLoadingRooms = false;
      },
    });

    this.searchedMessageSubscription = this.socket.fromEvent(SocketEvents.SEARCH_MESSAGE).subscribe({
      next: ({ messages }: { count: number; messages?: Room[] }) => {
        messages.map((message) => {
          message.room = this.room;
          message.message = message.message.replace(this.searchBoxTxt, '<mark>' + this.searchBoxTxt + '</mark>');
        });
        this.filteredList = messages;
        this.isLoadingRooms = false;
      },
    });

    if (!this.onlyMessages || this.searchBoxTxt !== '') {
      this.getRooms();
    }
  }

  private notConnectedCallback(): void {
    this.logger.consoleLog('TorgramSearchGroupComponent', 'notConnectedCallback');

    this.ngOnDestroy(false);
  }
}
