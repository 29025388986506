/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Types
import { ILoggedUser } from '../../../shared/types';
import { IAppTracking, IWebServiceError } from '../../../shared/types/webservices';
import { ILogInRinascimento, ITryLogin } from '../../../shared/types/features';
import { IServerCategory } from '../../../shared/types/aaa';
import { StorageKey } from '../../../shared/types/storage';

// Environment
import { environment } from '../../../../environments/environment';

// Services
import { WebServiceCall } from '../../services/webservice-caller/webservice-caller.service';
import { StorageService } from '../../services/storage/storage.service';
import { LoggerService } from '../../services/logger/logger.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class WebService {
  private WEBSERVICES_MODE = environment.WEBSERVICES_MODE as 'ONLINE' | 'LOCAL';

  private WEB_SERVICE_URL = environment.WEBSERVICES[this.WEBSERVICES_MODE].WEB_SERVICE_URL;
  private TOKEN_GENERATOR = environment.WEBSERVICES[this.WEBSERVICES_MODE].TOKEN_GENERATOR;

  constructor(
    private webService: WebServiceCall,
    private storage: StorageService,
    private logger: LoggerService,
    private auth: AuthenticationService,
  ) {}

  doLogin(user: ITryLogin): Observable<ILoggedUser | IWebServiceError> {
    return new Observable((observer) => {
      const { email, password, publicKey } = user;

      this.webService.postCall('/login', { user_email: email, password, publicKey }).subscribe({
        next: (_user: ILoggedUser) => observer.next(_user),
        error: (error: IWebServiceError) => observer.error(error),
      });
    });
  }

  doLogout(ID: number = this.auth.loggedUser.ID): Observable<{ message: 'string' } | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.postCall('/logout', { ID }).subscribe({
        next: (results: { message: 'string' }) => {
          this.storage.removeFromStorage(StorageKey.LoggedUser).subscribe();
          this.storage.removeFromStorage(StorageKey.FRITokenAvailability).subscribe();
          this.storage.setOnStorage(StorageKey.LastCategorySelected, 'BLOG').subscribe();
          observer.next(results);
        },
        error: (error: IWebServiceError) => {
          this.logger.consoleError('WebService', 'doLogout', error);
          observer.error(error);
        },
      });
    });
  }

  pairKey(ID: number): Observable<void | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.postCall('/pair', { ID }).subscribe({
        next: () => observer.next(),
        error: (error: IWebServiceError) => {
          this.logger.consoleError('WebService', 'pairKey', error);
          observer.error(error);
        },
      });
    });
  }

  isAuthenticated(): Observable<ILoggedUser | string> {
    return this.storage.getFromStorage(StorageKey.LoggedUser).pipe(
      switchMap((loggedUser: ILoggedUser) => {
        this.logger.consoleLog('WebService', 'isAuthenticated', loggedUser);

        if (loggedUser) {
          const { ID } = loggedUser;
          this.auth.loggedUser = loggedUser;

          return this.getUser(ID);
        } else {
          this.auth.loggedUser = undefined;

          return throwError(() => new Error(''));
        }
      }),
      switchMap((results: ILoggedUser) => {
        if (results.logged) {
          return of(this.auth.loggedUser);
        } else {
          this.auth.loggedUser = undefined;

          return throwError(() => new Error('NOT LOGGED'));
        }
      }),
    );
  }

  addAppTrackingRecord(type: string): Observable<void | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.postCall('/appTracking', { type }).subscribe({
        next: () => observer.next(),
        error: (error) => observer.error(error),
      });
    });
  }
  updatePersonalToken(ID: number, token: string): Observable<void | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.postCall('/user-token', { ID, token }).subscribe({
        next: () => observer.next(),
        error: (error) => observer.error(error),
      });
    });
  }

  getUser(ID: number): Observable<ILoggedUser | Error> {
    if (ID) {
      return this.webService.postCall('/logged-user', { ID }) as Observable<ILoggedUser | Error>;
    } else {
      return new Observable((observer) => observer.error(new Error('No ID')));
    }
  }

  getDefaultLanguage(): Observable<IAppTracking | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.getCall(this.WEB_SERVICE_URL + '/getDefaultLanguage').subscribe({
        next: (results: IAppTracking) => observer.next(results),
        error: (error: IWebServiceError) => observer.error(error),
      });
    });
  }

  updateResetPINStatus(ID: number): Observable<void | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.putCall('/user-pin', { ID }).subscribe({
        next: () => observer.next(),
        error: (error: IWebServiceError) => observer.error(error),
      });
    });
  }
  getAAACategories(): Observable<IServerCategory[] | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.getCall(this.WEB_SERVICE_URL + '/categoriesList').subscribe({
        next: (results: IServerCategory[]) => observer.next(results),
        error: (error: IWebServiceError) => observer.error(error),
      });
    });
  }

  generateToken(username: string, password: string): Observable<ILogInRinascimento | IWebServiceError> {
    return new Observable((observer) => {
      this.webService.postCallExternal(this.TOKEN_GENERATOR, { username, password }).subscribe({
        next: (result: ILogInRinascimento) => observer.next(result),
        error: (error: IWebServiceError) => observer.error(error),
      });
    });
  }
}
