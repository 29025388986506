/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/prefer-for-of */
import * as JSRSASIGN from 'jsrsasign';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class KeymasterCryptlibService {
  constructor() {}

  generateKeys(): { public: string; private: string } {
    const ec = new JSRSASIGN.KJUR.crypto.ECDSA({ curve: 'secp256k1' });
    const keypair = ec.generateKeyPairHex();
    return {
      public: keypair.ecpubhex,
      private: keypair.ecprvhex,
    };
  }

  createResponseMessage(originalMessage: string, privateKeyHex: string, publicKeyHex: string): string {
    let message = this.SHA256Hex(this.getLastSignature(originalMessage));
    const ec = new JSRSASIGN.KJUR.crypto.ECDSA({ curve: 'secp256k1' });
    let signatureDER = ec.signHex(message, privateKeyHex);

    if (signatureDER.length > (1 + 1 + 1 + 1 + 32 + 1 + 1 + 32) * 2) {
      let tmp = '';
      let toCopy = 0;
      let toSkip = 0;
      let status = 0;
      for (let a = 0; a < signatureDER.length / 2; a++) {
        const b = signatureDER.substr(a * 2, 2);
        if (status === 0) {
          tmp += b;
          status++;
        } else if (status === 1) {
          tmp += '44';
          status++;
        } else if (status === 2 || status === 5) {
          tmp += b;
          status++;
        } else if (status === 3 || status === 6) {
          tmp += '20';
          const len = parseInt(b, 16);
          toSkip = len - 32;
          toCopy = 32;
          status++;
        } else if (toSkip > 0) {
          toSkip--;
        } else if (toCopy > 0) {
          tmp += b;
          toCopy--;
          if (toCopy === 0) {
            status++;
          }
        }
      }
      signatureDER = tmp;
    }

    message = publicKeyHex;
    if (message.length % 2 === 1) {
      message = '0' + message;
    }

    message += signatureDER;
    if (message.length % 2 === 1) {
      message = '0' + message;
    }

    message = (message.length / 2).toString(16) + message;
    if (message.length % 2 === 1) {
      message = '0' + message;
    }

    const uint8 = new Uint8Array(message.length / 2);
    for (let a = 0; a < message.length; a += 2) {
      uint8[a / 2] = parseInt(message.substr(a, 2), 16);
    }

    return this.base64FromArray(uint8);
  }

  verifyMessage(originalMessage: string, responseMessage: string, publicKeyHex: string): boolean {
    const message = this.SHA256Hex(this.getLastSignature(originalMessage));
    const signature = this.getLastSignature(responseMessage);
    const ec = new JSRSASIGN.KJUR.crypto.ECDSA({ curve: 'secp256k1' });
    return ec.verifyHex(message, signature, publicKeyHex);
  }

  getLastSignature(encodedMessage: string): string {
    const buffer = this.base64ToArray(encodedMessage);

    let lastSignature = '';
    for (let totLen = 0; totLen < buffer.length; ) {
      const len = buffer[totLen];
      const keyLen = buffer[totLen + 1] === 2 ? 33 : buffer[totLen + 1] === 3 ? 33 : 65;

      const sigLen = len - keyLen;

      for (let a = 0; a < sigLen; a++) {
        let newByte = buffer[totLen + 1 + keyLen + a].toString(16);
        if (newByte.length < 2) {
          newByte = '0' + newByte;
        }
        lastSignature += newByte;
      }

      totLen += len + 1;
    }

    return lastSignature;
  }

  SHA256Hex(hex: string): string {
    return JSRSASIGN.KJUR.crypto.Util.hashHex(hex, 'sha256');
  }

  base64FromArray(uint8Data: Uint8Array): string {
    const base64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let r = '';
    let p = '';

    const s = [];
    uint8Data.forEach((d) => s.push(d));
    let c = s.length % 3;

    for (; c > 0 && c < 3; c++) {
      p += '=';
      s.push(0);
    }

    for (c = 0; c < s.length; c += 3) {
      let n = (s[c] << 16) + (s[c + 1] << 8) + s[c + 2];
      n = [(n >>> 18) & 63, (n >>> 12) & 63, (n >>> 6) & 63, n & 63];
      r += base64chars[n[0]] + base64chars[n[1]] + base64chars[n[2]] + base64chars[n[3]];
    }
    return r.substring(0, r.length - p.length) + p;
  }

  base64ToArray(s: string): Uint8Array {
    const base64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    const p = s.charAt(s.length - 1) === '=' ? (s.charAt(s.length - 2) === '=' ? 'AA' : 'A') : '';
    const r = [];
    s = s.substr(0, s.length - p.length) + p;

    for (let c = 0; c < s.length; c += 4) {
      const n =
        (base64chars.indexOf(s.charAt(c)) << 18) +
        (base64chars.indexOf(s.charAt(c + 1)) << 12) +
        (base64chars.indexOf(s.charAt(c + 2)) << 6) +
        base64chars.indexOf(s.charAt(c + 3));
      r.push((n & 0xff0000) >> 16, (n & 0xff00) >> 8, n & 0xff);
    }

    for (let c = 0; c < p.length; c++) {
      r.splice(r.length - 1, 1);
    }
    return Uint8Array.from(r);
  }
}
